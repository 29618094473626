import {
  Dispensary,
  GetUserConnectedInfoResponse,
  Invitation,
  InvitationConfirmationType,
  User
} from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { CrbConnectOrgRepo } from './connect-org.repo';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { DispensariesApi } from 'api';
import { UserStore } from 'stores/UserStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { AppViewStateStore } from 'stores/AppViewStateStore';

interface VM {
  isLoading: boolean;
  savingConnection?: boolean;
  companies: GetUserConnectedInfoResponse[];
  invitedDispensary: null | Invitation;
  user: User;
}

@injectable()
export class CrbConnectOrgPresenter {
  @inject(CrbConnectOrgRepo)
  repo: CrbConnectOrgRepo;

  @inject(CrbDispensaryStore)
  dispensaryStore: CrbDispensaryStore;

  @inject(UserStore)
  userStore: UserStore;

  @inject(SnackbarStore)
  snackbarStore: SnackbarStore;

  @inject(DispensariesApi)
  dispensaryApi: DispensariesApi;

  @inject(AppViewStateStore)
  appViewStateStore: AppViewStateStore;

  vm: VM = {
    isLoading: true,
    savingConnection: false,
    companies: [],
    invitedDispensary: null,
    user: {} as User
  };
  constructor() {
    makeAutoObservable(this);
  }

  public load = async () => {
    try {
      this.vm.companies = this.dispensaryStore.limitedDispensaries;
      if (this.dispensaryStore.invitedDispensaries.length === 0) {
        throw new Error('No invitations found');
      }
      this.vm.invitedDispensary = this.dispensaryStore.invitedDispensaries[0];
      this.vm.user = this.userStore.user;
    } catch (e) {
      return e;
    } finally {
      this.vm.isLoading = false;
    }
  };

  public connectOrg = async (selectedRow: string, name: string, useInput: boolean) => {
    this.vm.savingConnection = true;
    try {
      let response = {} as Dispensary;
      if (useInput) {
        //connect with name
        if (this.vm.invitedDispensary) {
          response = await this.dispensaryApi.confirmDispensaryInvitation(
            this.vm.invitedDispensary?.id,
            name,
            InvitationConfirmationType.Invitation,
            ''
          );
        }
      } else {
        // connect with selected row
        const existingCrb = this.vm.companies.find((company) => company.crbId === selectedRow);
        if (existingCrb && this.vm.invitedDispensary) {
          response = await this.dispensaryApi.confirmDispensaryInvitation(
            this.vm.invitedDispensary?.id,
            name,
            InvitationConfirmationType.Crb,
            existingCrb?.crbId
          );
        }
        this.appViewStateStore.setConnectedOrg(true);
      }
      // update invitation list
      await this.dispensaryStore.loadDispensaries([], [], this.vm.user.id);
      // set response to current crb
      this.dispensaryStore.setCurrentDispensary(response.id);
      this.vm.savingConnection = false;
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage(
        'Failed to connect organization. Please contact Please try again. If the problem continues, please contact support@greencheckverified.com'
      );
      console.error(e);
    }
  };
}
