import { Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as PageBackIcon } from 'assets/images/page-back-icon.svg';
import { ReactComponent as DropDownIcon } from 'assets/images/down-arrow.svg';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ContainerItem, Header, Text, Hyperlink } from 'ui';
import { CommentIdComponents, CommentType } from '@gcv/shared';
import palette from 'ui/theme/palette';

export interface MenuOption {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  toolTip?: string;
}

interface Props {
  title: string;
  subTitle?: string;
  additionalTitle?: string;
  additionalTitleIcon?: React.ReactNode;
  titleIcon?: React.ReactNode;
  titleMaxWidth?: string;
  settingsOptions?: MenuOption[];
  onBack?: { path: string };
  onBackCallback?: () => unknown | boolean;
  topActions?: React.ReactNode;
  commentsBadgeProps?: {
    idComponents: CommentIdComponents;
    title: string;
    type: CommentType;
  };
  topActionWidth?: string;
  onAdditionalTitleClick?: () => void;
  enableAdditionalTitleHover?: boolean;
  titleAlign?: 'flex-start' | 'flex-end' | 'center';
  titlePadding?: string;
}

export const TabbedPageHeader: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [createReportMenuEl, setCreateReportMenuEl] = React.useState<Element>();
  const theme = useTheme();
  const [showFullDescription, setShowDescription] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const onBack = () => {
    if (props.onBackCallback) props.onBackCallback();
    if (props.onBack) navigate(props.onBack.path);
  };

  return (
    <>
      <Container
        justify="space-between"
        align={props.titleAlign ? props.titleAlign : 'flex-start'}
        dataCy="tabbed-page-header"
        padding={props.titlePadding ? props.titlePadding : '0 8px 0px 8px'}
        width="100%"
        nowrap={!isMobile}
        column={isMobile}
      >
        {props.title && (
          <Container padding="0" width={props.title.length > 36 ? '100%' : 'auto'} column>
            {props.additionalTitle && (
              <Box
                onClick={() => {
                  if (props.enableAdditionalTitleHover && props.onAdditionalTitleClick) {
                    props.onAdditionalTitleClick();
                  }
                }}
                sx={{
                  margin: '0.5rem 0 0.2rem 2.5rem',
                  padding: 0,
                  display: 'flex',
                  color: '#828599',
                  cursor: props.enableAdditionalTitleHover ? 'pointer' : 'default',
                  '&:hover': {
                    color: props.enableAdditionalTitleHover ? `${palette.primary.main}` : '#828599',
                    '& svg': {
                      '& path': {
                        fill: props.enableAdditionalTitleHover ? `${palette.primary.main}` : '#828599'
                      }
                    }
                  },
                  alignItems: props.additionalTitle.length > 85 ? 'flex-start' : 'center'
                }}
              >
                {props.additionalTitleIcon}
                <Text
                  sx={{
                    display: 'inline',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '470px',
                    color: 'currentcolor'
                  }}
                  content={props.additionalTitle}
                />
              </Box>
            )}
            <ContainerItem
              flex
              grow
              align="flex-start"
              padding={props.additionalTitle ? '0' : '0.5rem 0 0 0 '}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {props.onBack && (
                  <PageBackIcon
                    onClick={onBack}
                    style={{
                      cursor: 'pointer',
                      marginRight: '0.5rem',
                      height: '30px',
                      width: '30px',
                      minWidth: '30px'
                    }}
                  />
                )}

                {props.titleIcon && <Box sx={{ mr: '0.5rem', display: 'flex' }}>{props.titleIcon}</Box>}
              </Box>

              <Box sx={{ mr: '1rem', maxWidth: props.titleMaxWidth ? props.titleMaxWidth : 'none' }}>
                <Header inline content={props.title} dataCy="page-title" lineHeight="29px" />
                {props.settingsOptions && props.settingsOptions.length > 0 && (
                  <Box
                    sx={{
                      m: '0 0.5rem',
                      display: 'inline-block',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      boxShadow: Boolean(createReportMenuEl)
                        ? `0px 0px 0px 1.5px rgba(3, 166, 91, 0.25)`
                        : `0px`,
                      width: '18px',
                      height: '18px',
                      background: Boolean(createReportMenuEl) ? `${palette.background.paper}` : '',
                      '&:hover': {
                        // background: `${palette.background.paper}`
                        background: '#fff',
                        '& svg': {
                          '& path': {
                            fill: `${palette.primary.main}`
                          }
                        }
                      },
                      '& svg': {
                        width: '12px',
                        height: '12px',
                        display: 'inline-block',
                        margin: '2px 2px 2px 3px',
                        textAlign: 'center',
                        '& path': {
                          fill: Boolean(createReportMenuEl) ? `${palette.text.primary}` : `#828599`
                        },
                        '&: hover': {
                          '& path': {
                            fill: `${palette.primary.main}`
                          }
                        },
                        '&: focus': {
                          borderRadius: '50%',
                          border: `1px solid ${palette.border}`
                        }
                      }
                    }}
                    onClick={(event) => setCreateReportMenuEl(event.currentTarget)}
                  >
                    <DropDownIcon />
                  </Box>
                )}
              </Box>
            </ContainerItem>

            {props.subTitle && (
              <ContainerItem margin="0px 0.5rem 0px 70px" padding="0">
                <Text
                  type="body2"
                  content={
                    props.subTitle!.length > 550 && !showFullDescription
                      ? props.subTitle!.substring(0, 549) + '...'
                      : props.subTitle!
                  }
                  dataCy="page-description"
                  sx={{
                    display: 'inline',
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '24px',
                    paddingBottom: '10px',
                    color: (theme) => theme.palette.text.secondary
                  }}
                />

                {props.subTitle!.length > 106 && (
                  <Hyperlink
                    label={showFullDescription ? 'See less' : 'See more'}
                    style={{
                      display: 'inline',
                      marginLeft: '4px',
                      fontSize: '14px',
                      lineHeight: '20px'
                    }}
                    onClick={() => {
                      setShowDescription(!showFullDescription);
                    }}
                  />
                )}
              </ContainerItem>
            )}
          </Container>
        )}

        {props.topActions && (
          <Container
            minWidth={props.topActionWidth ? props.topActionWidth : 'none'}
            justify="flex-end"
            dataCy="page-top-actions"
            padding="0"
            width={isMobile ? '100%' : 'auto'}
          >
            {props.topActions}
          </Container>
        )}
      </Container>

      {props.settingsOptions && (
        <Menu
          id="settings-menu"
          anchorEl={createReportMenuEl}
          open={Boolean(createReportMenuEl)}
          onClose={() => setCreateReportMenuEl(undefined)}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={{ marginTop: '5px' }}
        >
          {props.settingsOptions.map((o) => {
            return (
              <MenuItem
                disabled={o.disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  setCreateReportMenuEl(undefined);
                  o.onClick();
                }}
                sx={{ minWidth: '249px' }}
              >
                {o.label}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};
