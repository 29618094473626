import {
  BankTemplateType,
  DueDiligenceDocumentStatus,
  DueDiligenceRequirementStatus,
  DueDiligenceStatus,
  RequirementCategory,
  RequirementResultType,
  RequirementType,
  TemplateResponse,
  TemplateResultResponse
} from '@gcv/shared';
import { TemplateApi } from 'api';

export class TemplateApiMock extends TemplateApi {
  async getBankTemplate(id: string, templateId: string): Promise<TemplateResponse> {
    return {
      name: 'Default Template',
      description: 'Initial onboarding template',
      draft_exists: false,
      version: '4',
      date_updated: '2023-02-07T23:15:37.200Z',
      created_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
      date_created: '2023-02-07T23:12:41.254Z',
      published_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
      template_id: '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b',
      updated_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
      bank_id: 'ff4501e1-68af-4a73-937f-fc7b161b2322',
      id: '765b8269-e214-4e6c-b7b8-7d090b09c9ae',
      published_date: '2023-02-07T23:15:37.200Z',
      template_type: BankTemplateType.Onboarding,
      plant_touching: true,
      requirements: {
        gcv_business_formation_documents: {
          version: '0',
          date_updated: '2022-05-18T11:41:37.749Z',
          requirement_id: 'gcv_business_formation_documents',
          date_created: '2022-05-18T11:41:37.749Z',
          name: 'Business Formation Document(s)',
          legacy_requirement_id: 'gcv_business_formation_documents',
          required: true,
          bank_id: '1',
          category: RequirementCategory.Legal,
          description: 'This document establishes the legal existence of your business.',
          id: '55eed6ec-a1c6-4449-879d-3148b38e1887',
          type: RequirementType.BusinessLicense,
          modified: false,
          archived: false,
          order: 0
        }
      },
      custom_requirements: {
        id1: {
          bank_id: 'ff4501e1-68af-4a73-937f-fc7b161b2322',
          date_updated: '2023-02-07T23:14:32.638Z',
          date_created: '2023-02-07T23:14:32.638Z',
          id: 'd40530e1-7d08-4751-a531-f993806bb1e5',
          modified: true,
          order: 1,
          requirement_id: '2e922ba2-da3a-4c43-8c83-ff0cf772bb8f',
          type: RequirementType.CustomFieldsSection,
          version: '1',
          custom_section: {
            description: '',
            fields: [],
            id: '1',
            label: 'test label',
            named_section: true,
            order: 1
          }
        }
      }
    };
  }

  async getCrbTemplateResult(
    crbId: string,
    templateId: string,
    templateResultId: string
  ): Promise<TemplateResultResponse> {
    return {
      account_owners_section_bank_review_complete: true,
      active: true,
      bank_id: 'ff4501e1-68af-4a73-937f-fc7b161b2322',
      business_details_section_bank_review_complete: true,
      crb_id: 'd2cee74c-f60e-4804-8f83-9835c5313029',
      custom_requirement_modified_by: '8d8cabfe-402b-4c8b-974b-1cfb17006f40',
      custom_requirement_result: [
        {
          active: true,
          crb_id: 'd2cee74c-f60e-4804-8f83-9835c5313029',
          custom_fields: {
            last_updated_by: '8d8cabfe-402b-4c8b-974b-1cfb17006f40',
            last_updated_date: '2023-02-08T00:18:14.614Z',
            responses: {
              '865761a5-8c00-4cc1-8566-ae6ac3149dd3': 'test hello again again again',
              '17693932-e5e7-4796-8635-37cb0841bb3b': 'no'
            }
          },
          date_created: '2023-02-07T23:59:30.912Z',
          date_updated: '2023-02-08T00:18:14.614Z',
          id: 'ecda5083-23d5-446f-9f85-f5947cce38bc',
          requirement_id: '394191a6-9c23-4183-82e0-9bed0e536095',
          requirement_version: '0',
          status: DueDiligenceRequirementStatus.NEEDS_REVIEW,
          template_id: '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b',
          template_requirement_id:
            '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b.394191a6-9c23-4183-82e0-9bed0e536095',
          type: RequirementResultType.CustomRequirementResult
        }
      ],
      date_created: '2023-02-06T21:00:48.196Z',
      date_updated: '2023-02-08T00:18:15.482Z',
      due_diligence_documentation_section_bank_review_complete: true,
      id: 'd13bf1a9-a3ba-4293-b023-1f8b9b40997b',
      operational_details_section_bank_review_complete: true,
      requirements_results: [
        {
          crb_id: 'd2cee74c-f60e-4804-8f83-9835c5313029',
          date_created: '2022-05-18T11:43:38.723Z',
          date_updated: '2022-05-18T11:43:39.137Z',
          do_not_have: false,
          documents: {
            '13cbfb51-b856-4045-b1d9-03a99db9e215': {
              bank_internal_note: '',
              id: '13cbfb51-b856-4045-b1d9-03a99db9e215',
              review_date: '2021-12-09T00:10:35.127Z',
              reviewed_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
              status: DueDiligenceDocumentStatus.APPROVED
            }
          },
          id: '336b29c9-c4b3-406f-8165-33280c6d5755',
          requirement_id: 'gcv_business_formation_documents',
          requirement_version: '0',
          review_date: '',
          reviewed_by: '',
          status: DueDiligenceRequirementStatus.NEEDS_REVIEW,
          template_id: '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b',
          template_requirement_id: '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b.gcv_business_formation_documents',
          type: RequirementResultType.OnboardingDocumentRequirementResult
        }
      ],
      review_started_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
      review_started_date: '2023-02-06T21:14:49.760Z',
      reviewed_by: '11ef7d80-99cb-46e0-aa49-e3dcea92b840',
      reviewed_date: '2023-02-06T21:18:23.424Z',
      status: DueDiligenceStatus.BANK_APPROVED,
      submitted_by: '8d8cabfe-402b-4c8b-974b-1cfb17006f40',
      submitted_date: '2023-02-06T21:09:21.938Z',
      template_id: '7bb934e2-ede4-41d1-bf35-0ecf4e806e6b',
      template_version: '1',
      version: '1'
    };
  }
}
