/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFiBankStore } from 'stores/FiBankStore';
import { getUserStore } from 'stores/UserStore';
import { api, banksApi } from './api-util/api';

// The aliases match the UI labels for the reports.
import {
  FinancialInstitutionAccountDetailsReportParameters as AccountDetailsParams,
  FinancialInstitutionAccountSummaryReportParameters as AccountSummaryParams,
  FinancialInstitutionCoreTransactionReportParameters as CoreTransactionParams,
  FiDailySummariesReportParameters as DailySummariesParams,
  FinancialInstitutionDepositsReportParameters as DepositsParams,
  FiCtrSarReportsParameters as FinCENSummaryParams,
  FiLicenseReportParameters as LicenseParams,
  FiQuestionnaireFullResponsesReportParameters as QuestionnaireFullParams,
  FiQuestionnaireSummaryReportParameters as QuestionnaireSummaryParams,
  FiPreCompliancedSalesReportParameters as RawSalesDataParams,
  FinancialInstitutionSalesReportParameters as SalesActivityParams,
  FinancialInstitutionUsersReportParameters as UserReportParams
} from '@gcv/shared';

export enum ReportType {
  ACCOUNT = 'account',
  DAILY = 'daily',
  DEPOSITS = 'deposits',
  FINCENCTR = 'finCENCTR',
  FINCENSAR = 'finCENSAR',
  LICENSE = 'license',
  QUESTIONNAIRES = 'questionnaires',
  RAWSALES = 'rawSales',
  SALES = 'sales',
  USER = 'user',
  TRANSACTION = 'transaction',
  COMMENTS = 'comments'
}

export type ReportData =
  | AccountSummaryParams
  | AccountDetailsParams
  | DailySummariesParams
  | DepositsParams
  | FinCENSummaryParams
  | RawSalesDataParams
  | SalesActivityParams
  | UserReportParams
  | QuestionnaireSummaryParams
  | QuestionnaireFullParams
  | LicenseParams
  | CoreTransactionParams;

interface Payload {
  orgId: string;
  userId: string;
  reportData: ReportData;
}

export enum DepositStatus {
  All = 'all',
  Pending = 'pending',
  Accepted = 'accepted',
  UnderReview = 'under_review'
}

export enum FinCENReportStatus {
  All = 'all',
  Complete = 'complete',
  Open = 'open'
}

export enum SalesActivityStatus {
  Unchecked = 'unchecked',
  RedFlag = 'red_flag',
  GreenChecked = 'green_checked',
  Warning = 'warning'
}

export enum CRBLicenseStatus {
  Active = 'Active',
  ApprovedPending = 'Approved/Pending',
  Inactive = 'Inactive',
  PreLicense = 'Pre-License',
  Unknown = 'Unknown',
  Unlicensed = 'Unlicensed',
  /** GCV only status, for when we don't have a matching license in CRBMonitor data */
  Unmatched = 'Unmatched'
}

export const exportAccountsSummaryReport = async (data: any): Promise<boolean> => {
  const dispensaryIds = data.ids as string[];
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: dispensaryIds,
      bankId: bankId
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/account-summary`, body);
};
export const exportAccountDetails = async (data: any): Promise<boolean> => {
  const templateIds = data.ids as string;
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      templateId: templateIds,
      bankId: bankId
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/account-details`, body);
};

export const exportDailySummaries = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end
    }
  };

  return await banksApi().post('/banks/reporting/dailySummaries', body);
};

export const exportDeposits = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end,
      status: data.status
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/dispensaries/deposits`, body);
};

export const exportFinCENCTRSummary = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      userId: userId,
      bankId: bankId,
      dispensaryIds: data.accounts,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end,
      activityType: 'CTR',
      status: data.status
    }
  };

  return await api().post('/fincen-reports/export-summary', body);
};

export const exportFinCENSARSummary = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      userId: userId,
      bankId: bankId,
      dispensaryIds: data.accounts,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end,
      activityType: 'SAR',
      status: data.status
    }
  };

  return await api().post('/fincen-reports/export-summary', body);
};

export const exportCRBLicenseData = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      bankId: bankId,
      statuses: data.licenseStatuses
    },
    reportType: 'fi_license_report'
  };

  return await banksApi().post(`/banks/${bankId}/reporting/dispensaries/licenses`, body);
};

export const exportRawSalesData = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const timezone = getFiBankStore().bank.iana_timezone;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end,
      bankTimezone: timezone
    }
  };

  // Notice that the report is named quite differently, on the back-end.
  return await banksApi().post(`/banks/${bankId}/reporting/dispensaries/precomplianceReview`, body);
};

export const exportSalesActivity = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const formatStatusMap = (statuses: string[]) => {
    const statusMap: Record<string, boolean> = {};
    statuses.forEach((s) => {
      statusMap[s] = true;
    });

    return statusMap;
  };

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      salesStatusMap: formatStatusMap(data.salesStatuses),
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/dispensaries/sales`, body);
};

export const exportUserReport = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      orgIds: data.accounts
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/users`, body);
};

export const exportQuestionnairesReport = async (data: { fullReport: boolean; ids: string[] }) => {
  if (data.fullReport) {
    return await exportQuestionnairesFullReport(data);
  } else {
    return await exportQuestionnairesSummaryReport(data);
  }
};

export const exportAccountSummary = async (data: { fullReport: boolean; ids: string[] }) => {
  if (data.fullReport) {
    return await exportAccountDetails(data);
  } else {
    return await exportAccountsSummaryReport(data);
  }
};

const exportQuestionnairesSummaryReport = async (data: any): Promise<boolean> => {
  const dispensaryIds = data.ids as string[];
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: dispensaryIds,
      bankId: bankId,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/questionnaire-summary`, body);
};

const exportQuestionnairesFullReport = async (data: any): Promise<boolean> => {
  const questionnaireIds = data.ids as string[];
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      questionnaireIds: questionnaireIds,
      bankId: bankId,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/questionnaire-full-responses`, body);
};

export const exportCoreTransactionReport = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      dispensaryIds: data.accounts,
      bankId: bankId,
      startDate: data.timePeriod.dateRange.start,
      endDate: data.timePeriod.dateRange.end
    },
    reportType: 'fi_crb_core_transaction_report'
  };

  return await banksApi().post(`/banks/${bankId}/reporting/dispensaries/core-transactions`, body);
};

export const exportCommentsReport = async (data: any): Promise<boolean> => {
  const bankId = getFiBankStore().bank.id;
  const userId = getUserStore().user.id;

  const { accounts, timePeriod } = data;

  const body = {
    orgId: bankId,
    userId: userId,
    reportData: {
      bankId: bankId,
      dispensaryIds: accounts,
      startDate: timePeriod.dateRange.start,
      endDate: timePeriod.dateRange.end
    }
  };

  return await banksApi().post(`/banks/${bankId}/reporting/comments`, body);
};
