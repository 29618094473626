import {
  CRB,
  CreateDocumentsRequest,
  DocumentUpload,
  DocumentUploadSymlink,
  DueDiligenceDocument,
  LicenseDocumentDataRequest,
  OnboardingDocumentRequirement,
  RequirementCategory,
  RequirementType,
  TemplateResultResponse,
  TribalNationLicenseType,
  tribalNationSelectList,
  User,
  USStates
} from '@gcv/shared';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { CrbmApi, DocumentsApi } from 'api';
import { ReactComponent as OpenExternalIcon } from 'assets/images/ic-external.svg';
import { ReactComponent as FileIcon } from 'assets/images/ic-file.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/ic-modal-alert.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/ic-modal-success.svg';
import { USStateOptions } from 'domain/consts';
import { environment } from 'environments/environment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Rollbar from 'rollbar';
import { getComplianceStore } from 'stores/ComplianceStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { rollBarAccessToken } from 'third-party-integrations/rollbar';
import {
  Button,
  Column,
  Container,
  ContainerItem,
  Dialog,
  InputDate,
  InputFile,
  InputSelect,
  InputText,
  Row,
  SubTitle,
  Table,
  ZeroState
} from 'ui';
import { Header, Spinner, Text, Tooltip } from 'ui/atoms';
import { InputCheckBox, InputSelectSearch, TableContainer } from 'ui/molecules';
import { DateTimeHelpers } from 'util/dateTime.util';
import { encodeFileForUpload } from 'util/files.util';
import { cropInput } from 'util/format.util';
import { capitalizeWords } from 'util/strings.util';
import palette from '../../theme/palette';

interface LicenseTableData {
  id: number;
  businessName: string;
  businessId: string;
  licenseNumber: string;
  licenseId: string;
  state: string;
  expirationDate: string;
  licenseTypeDescription: string;
  locality: string;
}

interface SharedDocumentsTableData {
  file_name: string;
  uploaded_on: string;
  requirement_name: string;
  document_id: string;
  org_id: string;
}

interface BusinessTableData {
  id: number;
  businessName: string;
  match_quality: number;
}

interface BusinessLicensesTableData {
  id: number;
  businessId: string;
  licenseNumber: string;
  licenseId: string;
  licenseAuthority: string;
  licenseType: string;
  med_rec_classification: string;
  status: string;
}

interface UploadDocumentPropsV2 {
  addDocumentsToRequirement: (documents: CreateDocumentsRequest) => Promise<void>;
  openDocumentNewWindow?: (docId: string, file_name: string) => Promise<void>;
  toggleModal: (isOpen: boolean) => void;
  modalOpen: boolean;
  requirement: OnboardingDocumentRequirement;
  dispensary: CRB;
  companyType: 'bank' | 'dispensary';
  userMap: User[];
  existingFiles?: DocumentUpload[];
  documents?: DueDiligenceDocument[];
  sharedRootDocs?: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  templateResults?: TemplateResultResponse;
  allDocumentsMap?: {
    [id: string]: DocumentUpload;
  };
}

export const UploadDocumentModalV2: React.FC<UploadDocumentPropsV2> = (props) => {
  const {
    addDocumentsToRequirement,
    requirement,
    dispensary,
    companyType,
    toggleModal,
    modalOpen,
    documents
  } = props;
  const { documentSharing, tribalLicenses } = useFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const { register, handleSubmit, ...form } = useForm({
    defaultValues: {
      state: dispensary.is_tribal_nation ? '' : dispensary.state,
      license_name: documents && documents.length === 1 ? documents[0].license_name : '',
      license_number: documents && documents.length === 1 ? documents[0].license_number : '',
      expiration_date: documents && documents.length === 1 ? documents[0].expiration_date : '',
      isTribalNation: dispensary.is_tribal_nation,
      tribal_nation: documents && documents.length === 1 ? documents[0].tribal_nation : '',
      tribal_nation_license_type:
        documents && documents.length === 1 ? documents[0].tribal_nation_license_type : ''
    }
  } as FieldValues);

  const crbmApi = new CrbmApi();
  const documentsApi = new DocumentsApi();

  const complianceStore = getComplianceStore();
  const snackbarStore = getSnackbarStore();

  const [rollbar] = useState(
    new Rollbar({
      accessToken: rollBarAccessToken,
      captureUncaught: true,
      captureUnhandledRejections: true
    })
  );

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<{ filename: string; s3_key: string }[]>([]);
  const [licenseData, setLicenseData] = useState<FieldValues>({});
  const [licenseNameOptions, setLicenseNameOptions] = useState<{ label: string; value: string }[]>([]);
  const [openLicensesLoading, setOpenLicensesLoading] = useState(false);
  const [openLicenseValidation, setOpenLicenseValidation] = useState(false);
  const [openBusinessesLoading, setOpenBusinessesLoading] = useState(false);
  const [openBusinessValidation, setOpenBusinessValidation] = useState(false);
  const [openBusinessLicensesLoading, setOpenBusinessLicensesLoading] = useState(false);
  const [openBusinessLicensesValidation, setOpenBusinessLicensesValidation] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<{ licenseId: string; businessId: string }>();
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [selectedBusinessLicense, setSelectedBusinessLicense] =
    useState<{ licenseId: string; businessId: string }>();
  const [availableLicenses, setAvailableLicenses] = useState<LicenseTableData[]>([]);
  const [availableBusinesses, setAvailableBusinesses] = useState<BusinessTableData[]>([]);
  const [availableBusinessLicenses, setAvailableBusinessLicenses] = useState<BusinessLicensesTableData[]>([]);

  const [selectedDocuments, setSelectedDocuments] = React.useState<
    { file_name: string; uploaded_on: string; requirement_name: string; document_id: string }[]
  >([]);
  const [showUploadForm, setShowUploadForm] = useState<boolean>(false);

  React.useEffect(() => {
    if (form.watch('state')) {
      setLicenseNameOptions(
        complianceStore.licenseDataMap[form.watch('state') as USStates].business_license_types.map(
          (business_license_type) => {
            return { label: business_license_type.viewValue, value: business_license_type.value };
          }
        )
      );
    }
    form.setValue('license_name', undefined);
  }, [form.watch('state')]);

  const openFailureSnackbar = () =>
    snackbarStore.showErrorSnackbarMessage('There was an issue uploading one or more of your documents.');

  const loadLicenses = async () => {
    toggleModal(false);
    setOpenLicensesLoading(true);

    const licenseNumber = form.watch('license_number');

    await crbmApi
      .searchLicenses(dispensary.id, licenseNumber)
      .then((res) => {
        if (res.length === 0) {
          form.setValue('licenseNotFound', true);
        } else {
          const licenses: LicenseTableData[] = [];

          res.forEach((l) => {
            l.businesses.forEach((business) => {
              if (licenses.length < 5) {
                licenses.push({
                  id: l.id,
                  businessName: business.name ?? '--',
                  businessId: business.id.toString() ?? '--',
                  licenseNumber: l.license_identifier ?? '--',
                  licenseId: l.id.toString() ?? '--',
                  state: l.licensing_authority_address?.region ?? '--',
                  expirationDate: l.expires_at ?? '--',
                  licenseTypeDescription: l.uniform_license_type_description,
                  locality: l.locality
                });
              }
            });

            l.individuals.forEach((individual) => {
              if (licenses.length < 5) {
                licenses.push({
                  id: l.id,
                  businessName: individual.name ?? '--',
                  businessId: individual.id.toString() ?? '--',
                  licenseNumber: l.license_identifier ?? '--',
                  licenseId: l.id.toString() ?? '--',
                  state: l.licensing_authority_address?.region ?? '--',
                  expirationDate: l.expires_at ?? '--',
                  licenseTypeDescription: l.uniform_license_type_description,
                  locality: l.locality
                });
              }
            });
          });

          setAvailableLicenses(licenses);
        }
      })
      .finally(() => {
        setOpenLicensesLoading(false);
        setOpenLicenseValidation(true);
      });
  };

  const onSubmit = async (data: FieldValues) => {
    if (
      requirement.category === RequirementCategory.License &&
      requirement.type === RequirementType.BusinessLicense
    ) {
      try {
        setLoading(true);
        setLicenseData({ ...data });

        if (files.length) {
          await uploadDocuments(files).then(async (s3Files) => {
            setUploadedFiles(s3Files);

            //check if some files failed to upload, show error if so
            if (s3Files.length < files.length) {
              openFailureSnackbar();
            }

            toggleModal(false);
            setLoading(false);

            if (!form.getValues('isTribalNation')) {
              await loadLicenses();
            } else {
              await updateFileData(s3Files, data);
              dispensary.is_tribal_nation = data.isTribalNation;

              form.reset({
                state: dispensary.state,
                license_name: '',
                license_number: '',
                expiration_date: '',
                isTribalNation: dispensary.is_tribal_nation,
                tribal_nation: '',
                tribal_nation_license_type: ''
              });
            }
          });
        } else {
          await updateFileData([], data);
        }
      } catch (e) {
        toggleModal(false);
        setOpenLicensesLoading(false);
        setOpenLicenseValidation(false);

        snackbarStore.showErrorSnackbarMessage('There was an issue loading license data.');
      }
    } else {
      setLoading(true);

      const uploadedFiles = await uploadDocuments(files);

      //check if some files failed to upload, show error if so
      if (uploadedFiles.length < files.length) {
        toggleModal(false);
        setLoading(false);
        openFailureSnackbar();
        return;
      }

      toggleModal(false);
      setLoading(false);
      await updateFileData(uploadedFiles, data);

      snackbarStore.showSuccessSnackbarMessage('File(s) have been uploaded/added successfully.');
    }
  };

  const uploadDocuments = async (blobs: File[]) => {
    if (!blobs.length) {
      return [];
    }

    return Promise.all(
      blobs.map(async (blob) => await handleAddDocumentToS3({ blob: blob, userType: companyType }))
    )
      .then((files) => files.filter((f) => f) as { filename: string; s3_key: string }[])
      .catch((err) => {
        rollbar.error('0kb file ' + err);
        openFailureSnackbar();
        return [];
      });
  };

  const handleAddDocumentToS3 = async ({ blob, userType }: { blob: File; userType: string }) => {
    try {
      const result_base64 = await encodeFileForUpload(blob);
      if (!result_base64) {
        rollbar.error('0kb file ' + JSON.stringify(blob, null, 2));
        return undefined;
      } else {
        const initial_s3_key = `${dispensary.id}/${blob.name}`;
        const config = {
          document: { file_name: blob.name, data: result_base64 },
          userType: userType,
          orgId: dispensary.id,
          s3_key: initial_s3_key
        };

        const { s3LinkPath, s3_key } = await getPresignedUrl(config);
        await uploadfileToS3(s3LinkPath, config.document.data);

        return {
          filename: blob.name,
          s3_key: s3_key
        };
      }
    } catch (e: any) {
      rollbar.error(e);
      return undefined;
    }
  };

  const getPresignedUrl = async (config: { s3_key: string; userType: string }) => {
    const { s3_key, userType } = config;

    return await documentsApi.getDocumentLink(
      `${environment.env}-org-documents-file-bucket`,
      s3_key,
      'put',
      userType
    );
  };

  const uploadfileToS3 = async (fileuploadurl: string, file: string) => {
    try {
      return fetch(fileuploadurl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: file // body data type must match "Content-Type" header
      });
    } catch (e: any) {
      rollbar.error(e);
      throw e;
    }
  };

  const updateFileData = async (
    files: {
      filename: string;
      s3_key: any;
    }[],
    data?: FieldValues
  ) => {
    const documents: {
      filename: string;
      req_name: string;
      s3_key: string;
      shared?: boolean;
      linked_doc_id?: string;
    }[] =
      files &&
      files.map((file) => {
        return { ...file, req_name: requirement.name, shared: true };
      });

    const license_data: LicenseDocumentDataRequest | undefined =
      data && requirement.category === RequirementCategory.License
        ? requirement.type === RequirementType.BusinessLicense
          ? {
              state: data.state,
              license_number: data.license_number,
              license_name: data.license_name,
              expiration_date: data.expiration_date,
              crbm_license_id: selectedLicense
                ? selectedLicense.licenseId.toString()
                : selectedBusinessLicense
                ? selectedBusinessLicense.licenseId.toString()
                : '',
              crbm_business_id: selectedLicense
                ? selectedLicense.businessId.toString()
                : selectedBusinessLicense
                ? selectedBusinessLicense.businessId.toString()
                : '',
              tribal_nation: data.tribal_nation,
              tribal_nation_license_type: data.tribal_nation_license_type
            }
          : {
              license_number: data.license_number,
              license_name: data.license_name,
              expiration_date: data.expiration_date,
              tribal_nation: data.tribal_nation,
              tribal_nation_license_type: data.tribal_nation_license_type
            }
        : undefined;

    if (props.sharedRootDocs && selectedDocuments.length) {
      for (const rootDoc of props.sharedRootDocs) {
        for (const doc of rootDoc.documents) {
          if (selectedDocuments.some((sd) => sd.document_id === doc.id)) {
            documents.push({
              filename: doc.file_name,
              req_name: requirement.name,
              s3_key: doc.s3_key,
              linked_doc_id: `${doc.org_id}_${doc.id}`
            });
          }
        }
      }
    }

    const body: CreateDocumentsRequest = {
      documents,
      license_data
    };

    await addDocumentsToRequirement(body)
      .then(() => {
        toggleModal(false);
        setLoading(false);
        setFiles([]);
        setSelectedDocuments([]);
      })
      .catch((e) => {
        openFailureSnackbar();
        setLoading(false);
        setFiles([]);
        setSelectedDocuments([]);
      });

    setSelectedDocuments([]);
  };

  const licenseColumns: Column<LicenseTableData>[] = [
    {
      id: 'businessName',
      label: 'Business Name',
      widthPercent: 25,
      format: (row) => (row.businessName === '' ? '--' : row.businessName)
    },
    {
      id: 'licenseNumber',
      label: 'License Number',
      widthPercent: 20,
      format: (row) => (row.licenseNumber === '' ? '--' : row.licenseNumber)
    },
    {
      id: 'locality',
      label: 'Locality',
      widthPercent: 25,
      format: (row) => (row.locality === '' ? '--' : row.locality)
    },
    { id: 'state', label: 'State', widthPercent: 10, format: (row) => (row.state === '' ? '--' : row.state) },
    {
      id: 'licenseTypeDescription',
      label: 'License Type',
      widthPercent: 15,
      format: (row) =>
        !row.licenseTypeDescription || row.licenseTypeDescription === '' ? '--' : row.licenseTypeDescription
    },
    {
      id: 'expirationDate',
      label: 'Expiration Date',
      widthPercent: 15,
      format: (row) => {
        return !row.expirationDate || row.expirationDate === '' || row.expirationDate === '--'
          ? '--'
          : DateTimeHelpers.formatISOToTableDateString(row.expirationDate, dispensary.iana_timezone);
      }
    }
  ];

  const licenseRows: Row<LicenseTableData>[] = availableLicenses.map((license) => {
    return {
      id: license.id.toString(),
      disableSelect: form.watch('licenseNotFound'),
      data: {
        ...license
      }
    };
  });

  const businessColumns: Column<BusinessTableData>[] = [
    {
      id: 'businessName',
      label: 'Business Name',
      widthPercent: 70,
      format: (row) => (row.businessName === '' ? '--' : row.businessName)
    },
    {
      id: 'match_quality',
      label: 'Match Quality',
      widthPercent: 30,
      format: (row) => (row.match_quality.toString() === '' ? '--' : row.match_quality.toFixed(1) + '%')
    }
  ];

  const businessRows: Row<BusinessTableData>[] = availableBusinesses.map((business) => {
    return {
      id: business.id.toString(),
      disableSelect: form.watch('businessNotFound'),
      data: {
        ...business
      }
    };
  });

  const businessLicensesColumns: Column<BusinessLicensesTableData>[] = [
    {
      id: 'licenseNumber',
      label: 'License Number',
      widthPercent: 20,
      format: (row) => (row.licenseNumber === '' ? '--' : row.licenseNumber)
    },
    {
      id: 'licenseAuthority',
      label: 'License Authority',
      widthPercent: 20,
      format: (row) => (row.licenseAuthority === '' ? '--' : row.licenseAuthority)
    },
    {
      id: 'licenseType',
      label: 'License Type',
      widthPercent: 20,
      format: (row) => (row.licenseType === '' ? '--' : row.licenseType)
    },
    {
      id: 'med_rec_classification',
      label: 'Med/Rec',
      widthPercent: 20,
      format: (row) =>
        row.med_rec_classification === '' ? '--' : capitalizeWords(row.med_rec_classification)
    },
    {
      id: 'status',
      label: 'Status',
      widthPercent: 20,
      format: (row) => (row.status === '' ? '--' : row.status)
    }
  ];

  const businessLicensesRows: Row<BusinessLicensesTableData>[] = availableBusinessLicenses.map((business) => {
    return {
      id: business.id.toString(),
      disableSelect: form.watch('businessLicenseNotFound'),
      data: {
        ...business
      }
    };
  });

  const sharedDocumentsColumns: Column<SharedDocumentsTableData>[] = [
    {
      id: 'file_name',
      label: 'My Documents',
      widthPercent: !isMobile ? 45 : undefined,
      format: (td) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div>{isMobile ? cropInput(td.file_name, 12, 'document') : td.file_name}</div>
            <ContainerItem padding={0} margin={0}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (props.openDocumentNewWindow) {
                    props.openDocumentNewWindow(td.document_id, td.file_name);
                  }
                }}
              >
                <OpenExternalIcon />
              </div>
            </ContainerItem>
          </div>
        );
      }
    },
    { id: 'requirement_name', label: 'Requirement', widthPercent: !isMobile ? 35 : undefined },
    {
      id: 'uploaded_on',
      label: 'Uploaded On',
      widthPercent: !isMobile ? 20 : undefined,
      format: (row) =>
        DateTimeHelpers.formatISOToTableDateString(row.uploaded_on ?? '', dispensary.iana_timezone)
    }
  ];

  const sharedDocumentsRows: Row<SharedDocumentsTableData>[] = [];

  const isRootOrLinkedInRequirementResult = (sharedRootDoc: DocumentUpload): boolean => {
    if (!props.templateResults || !props.allDocumentsMap) return false;
    const requirementResult = props.templateResults.requirements_results.find(
      (docReqResult) => docReqResult.requirement_id === requirement.requirement_id
    );
    if (!requirementResult?.documents || Object.keys(requirementResult.documents).length === 0) {
      return false;
    } else {
      const requirementResultDocIds = Object.keys(requirementResult.documents);
      if (requirementResultDocIds.includes(sharedRootDoc.id)) {
        return true;
      }
      for (const requirementResultDocId of requirementResultDocIds) {
        const linkedDocId = (props.allDocumentsMap[requirementResultDocId] as DocumentUploadSymlink)
          .linked_doc_id;
        if (!linkedDocId) continue;
        const splitLinkedDocId = linkedDocId.split('_');
        if (splitLinkedDocId.length !== 2) continue;
        const rootDocId = splitLinkedDocId[1];
        if (sharedRootDoc.id === rootDocId) {
          return true;
        }
      }
      return false;
    }
  };

  if (props.sharedRootDocs && props.modalOpen) {
    props.sharedRootDocs.map((sharedRootDoc) => {
      if (
        sharedRootDoc.requirement.requirement_id === requirement.requirement_id ||
        requirement.category !== RequirementCategory.License
      ) {
        for (const document of sharedRootDoc.documents) {
          if (!document.deleted && !isRootOrLinkedInRequirementResult(document)) {
            sharedDocumentsRows.push({
              id: document.id,
              data: {
                file_name: document.file_name,
                uploaded_on: document.upload_date,
                requirement_name: sharedRootDoc.requirement.name,
                document_id: document.id
              }
            } as Row<SharedDocumentsTableData>);
          }
        }
      }
    });
  }

  const fileCount = () => {
    let count = 0;

    count += files.length;

    if (props.existingFiles) {
      count += props.existingFiles.length;
    }

    return count;
  };

  // this list is reg ticker 9123, An additional msg needs to be shown
  // if selected state is one from the below list
  const preCheckStates = [
    USStates.Arizona,
    USStates.District_of_Columbia,
    USStates.Delaware,
    USStates.Maine,
    USStates.Montana,
    USStates.Nevada
  ];

  const getInitiallySelectedDocIds = (): string[] => {
    const docIds: string[] = [];
    if (props.sharedRootDocs) {
      props.sharedRootDocs.map((doc) => {
        if (doc.requirement.requirement_id === requirement.requirement_id) {
          doc.documents.map((d) => docIds.push(d.id));
        }
      });
    }

    return docIds;
  };

  return (
    <>
      <Dialog
        handleClose={() => {
          toggleModal(false);
          setShowUploadForm(false);
          setSelectedDocuments([]);
          if (documents && documents.length !== 1) {
            form.reset({
              state: dispensary.state,
              license_name: '',
              license_number: '',
              expiration_date: '',
              tribal_nation: '',
              tribal_nation_license_type: '',
              isTribalNation: dispensary.is_tribal_nation
            });
          } else {
            documents &&
              form.reset({
                state: dispensary.state,
                license_name: documents[0].license_name,
                license_number: documents[0].license_number,
                expiration_date: documents[0].expiration_date,
                tribal_nation: documents[0].tribal_nation,
                tribal_nation_license_type: documents[0].tribal_nation_license_type,
                isTribalNation: dispensary.is_tribal_nation
              });
          }
        }}
        isOpen={modalOpen}
        secondAction={
          documentSharing &&
          !showUploadForm &&
          requirement.category === RequirementCategory.License &&
          sharedDocumentsRows.length > 0 ? (
            <Button
              label="Save and Upload New"
              onClick={() => {
                setShowUploadForm(true);
              }}
              color="primary"
            />
          ) : undefined
        }
        action={
          <Button
            color="primary"
            label={
              !documentSharing || showUploadForm || sharedDocumentsRows.length === 0
                ? 'Upload'
                : props.documents?.length
                ? 'Update'
                : 'Save'
            }
            isLoading={loading}
            onClick={handleSubmit(onSubmit)}
            disabled={((!documentSharing || selectedDocuments.length === 0) && fileCount() < 1) || loading}
            width={isMobile ? '100%' : 'fit-content'}
            dataCy="upload-button"
          />
        }
        title={
          documentSharing &&
          companyType === 'dispensary' &&
          sharedDocumentsRows.length > 0 &&
          (!showUploadForm || requirement.category !== RequirementCategory.License)
            ? `Select or upload ${requirement.name}`
            : `Upload ${requirement.name}`
        }
        dataCy="upload-document-modal"
        width={
          isMobile ||
          (companyType === 'dispensary' &&
            sharedDocumentsRows.length > 0 &&
            !showUploadForm &&
            documentSharing)
            ? '100%'
            : undefined
        }
      >
        <Container column data-cy="upload-container" margin="0" padding="0">
          {companyType === 'bank' ? (
            <ContainerItem justify="center" width="100%" margin="0" padding="0">
              <SubTitle content="The business will see files uploaded here in their Company Profile" />
            </ContainerItem>
          ) : documentSharing &&
            companyType === 'dispensary' &&
            sharedDocumentsRows.length > 0 &&
            !showUploadForm ? (
            <ContainerItem width="100%" margin="0" padding="0">
              <SubTitle content={`Choose from previously uploaded licenses`} />
            </ContainerItem>
          ) : null}
          <ContainerItem padding="0 0 8px 0" width="100%">
            <Container padding="0" width="100%">
              {documentSharing &&
                companyType === 'dispensary' &&
                sharedDocumentsRows.length > 0 &&
                (!showUploadForm || requirement.category !== RequirementCategory.License) && (
                  <ContainerItem padding="8px 0 8px 0" width={isMobile ? '100%' : '60%'}>
                    <TableContainer>
                      <Table
                        selectable
                        columns={sharedDocumentsColumns}
                        rows={sharedDocumentsRows}
                        maxHeight="360px"
                        defaultSort={'selected' as keyof SharedDocumentsTableData}
                        onClick={() => {
                          return;
                        }}
                        returnSelection={(selectedRows) => {
                          const selectedDocs: {
                            file_name: string;
                            uploaded_on: string;
                            requirement_name: string;
                            document_id: string;
                          }[] = [];
                          for (const row of sharedDocumentsRows) {
                            if (selectedRows.includes(row.id)) {
                              selectedDocs.push(row.data);
                            }
                          }
                          setSelectedDocuments(selectedDocs);
                        }}
                        defaultSelected={
                          props.sharedRootDocs &&
                          props.sharedRootDocs.some(
                            (srd) => srd.requirement.requirement_id === requirement.requirement_id
                          )
                            ? getInitiallySelectedDocIds()
                            : undefined
                        }
                        minWidth={isMobile ? '600px' : undefined}
                        noPagination
                      />
                    </TableContainer>
                  </ContainerItem>
                )}

              <ContainerItem
                width={
                  isMobile
                    ? '100%'
                    : companyType === 'dispensary' &&
                      sharedDocumentsRows.length > 0 &&
                      !showUploadForm &&
                      documentSharing
                    ? '40%'
                    : '100%'
                }
              >
                <Box
                  sx={{
                    borderLeft:
                      !isMobile &&
                      companyType === 'dispensary' &&
                      sharedDocumentsRows.length > 0 &&
                      !showUploadForm &&
                      documentSharing
                        ? `1px solid ${palette.border}`
                        : undefined,
                    minHeight:
                      !isMobile &&
                      companyType === 'dispensary' &&
                      sharedDocumentsRows.length > 0 &&
                      !showUploadForm &&
                      documentSharing
                        ? '400px'
                        : undefined,
                    paddingLeft:
                      !isMobile &&
                      companyType === 'dispensary' &&
                      sharedDocumentsRows.length > 0 &&
                      !showUploadForm &&
                      documentSharing
                        ? '8px'
                        : undefined
                  }}
                >
                  {documentSharing &&
                    companyType === 'dispensary' &&
                    sharedDocumentsRows.length > 0 &&
                    (!showUploadForm || requirement.category !== RequirementCategory.License) && (
                      <Box width="100%">
                        <Text content="Selected files" />
                        <Box
                          sx={{
                            border: selectedDocuments.length ? `1px solid ${palette.border}` : undefined,
                            width: '100%',
                            borderRadius: selectedDocuments.length ? '4px' : undefined,
                            minHeight: '35px'
                          }}
                        >
                          {selectedDocuments.length === 0 && (
                            <Text
                              type="body2"
                              sx={{ color: palette.text.secondary }}
                              content="There are no documents selected"
                            />
                          )}
                          {selectedDocuments.map((doc, index) => {
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mr: '0.25rem',
                                  justifyItems: 'center',
                                  padding: '6px'
                                }}
                              >
                                <Box sx={{ pt: '2px', pr: '4px' }}>
                                  <FileIcon />
                                </Box>
                                <Text sx={{ fontWeight: '700' }} content={doc.file_name} />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                  {(!documentSharing || sharedDocumentsRows.length === 0 || showUploadForm) &&
                  requirement.category === RequirementCategory.License ? (
                    <>
                      <ContainerItem padding="8px 0" width="100%">
                        {requirement.type === RequirementType.BusinessLicense ? (
                          <>
                            <Box sx={{ mb: '1rem' }}>
                              <Tooltip
                                title={
                                  form.watch('isTribalNation')
                                    ? 'To select a state, uncheck the Tribal Nation checkbox.'
                                    : ''
                                }
                              >
                                <InputSelect
                                  {...form}
                                  name={'state'}
                                  label={'State'}
                                  options={USStateOptions}
                                  rules={{
                                    required: {
                                      message: 'is required',
                                      value: form.watch('isTribalNation') ? false : true
                                    }
                                  }}
                                  dataCy="license-state"
                                  placeholder="Select State"
                                  readOnly={form.watch('isTribalNation')}
                                />
                              </Tooltip>
                            </Box>
                            {tribalLicenses && (
                              <>
                                <Box sx={{ mb: '1rem' }}>
                                  <InputCheckBox
                                    {...form}
                                    defaultValue={dispensary.is_tribal_nation}
                                    label={'Tribal nation license holder? Check this box.'}
                                    name={'isTribalNation'}
                                    readOnly={dispensary.is_tribal_nation}
                                    onChange={(e) => {
                                      form.setValue('state', '');
                                    }}
                                  />
                                </Box>
                                <>
                                  {form.watch('isTribalNation') && (
                                    <>
                                      <Box sx={{ mb: '1rem' }}>
                                        <InputSelectSearch
                                          {...form}
                                          options={tribalNationSelectList()}
                                          label={'Tribal Nation'}
                                          name={'tribal_nation'}
                                          rules={{ required: { message: 'is required', value: true } }}
                                          dataCy="tribal_nation"
                                          placeholder="Search"
                                        />
                                      </Box>
                                      <Box sx={{ mb: '1rem' }}>
                                        <InputSelect
                                          {...form}
                                          options={Object.entries(TribalNationLicenseType).map(
                                            ([key, value]) => {
                                              return { label: value, value: key };
                                            }
                                          )}
                                          label={'Tribal Nation License Type'}
                                          name={'tribal_nation_license_type'}
                                          rules={{ required: { message: 'is required', value: true } }}
                                          dataCy="tribal_nation_license_type"
                                          placeholder="Select Tribal Nation License Type"
                                        />
                                      </Box>
                                    </>
                                  )}
                                </>
                              </>
                            )}

                            {!form.watch('isTribalNation') && (
                              <InputSelect
                                {...form}
                                options={licenseNameOptions}
                                label={'License Name'}
                                name={'license_name'}
                                rules={{ required: { message: 'is required', value: true } }}
                                dataCy="license-name"
                              />
                            )}
                          </>
                        ) : (
                          <InputText
                            {...form}
                            name={'license_name'}
                            rules={{ required: { message: 'is required', value: true } }}
                            label={'Employee Name'}
                            dataCy="employee-name"
                          />
                        )}
                      </ContainerItem>

                      <ContainerItem padding="8px 0" width="100%">
                        <InputText
                          {...form}
                          name={'license_number'}
                          rules={{ required: { message: 'is required', value: true } }}
                          label={'License Number'}
                          dataCy="license-number"
                        />
                      </ContainerItem>

                      <ContainerItem padding="8px 0" width="100%">
                        <InputDate
                          {...form}
                          name={'expiration_date'}
                          rules={{ required: { message: 'is required', value: true } }}
                          label={'Expiration Date'}
                          timezone={dispensary.iana_timezone}
                          dataCy="license-expiration"
                        />
                      </ContainerItem>
                    </>
                  ) : null}
                  {(!documentSharing ||
                    sharedDocumentsRows.length === 0 ||
                    showUploadForm ||
                    requirement.category !== RequirementCategory.License) && (
                    <ContainerItem width="100%" margin="1rem 0 0 0" padding="8px 0">
                      <InputFile
                        fullWidth
                        onFilesChange={(allFiles) => setFiles(allFiles)}
                        acceptedTypes={'all'}
                        multiple={requirement.category !== RequirementCategory.License}
                        dataCy="upload-file-container"
                        existingFiles={
                          props.existingFiles && props.existingFiles.length > 1
                            ? undefined
                            : props.existingFiles
                        }
                      />
                    </ContainerItem>
                  )}
                </Box>
              </ContainerItem>
            </Container>
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            disabled
            color="primary"
            label="Uploading..."
            onClick={() => {
              return;
            }}
            style={{ minWidth: isMobile ? '100%' : '100px' }}
          />
        }
        handleClose={() => setOpenLicensesLoading(false)}
        height="356px"
        isOpen={openLicensesLoading}
        title=""
        width={isMobile ? '360px' : '459px'}
      >
        <Container column data-cy="verify-license-container" padding={0} width="100%">
          <ContainerItem padding="8px 0" justify="center" width="100%">
            <Spinner />
            <br />
            <Text
              sx={
                isMobile
                  ? {
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      color: palette.background.disabled
                    }
                  : {}
              }
              content="Checking license data..."
            />
            {isMobile ? (
              <Text
                sx={
                  isMobile
                    ? {
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px',
                        color: palette.background.disabled
                      }
                    : {}
                }
                content="(This could take up to one minute.)"
              />
            ) : (
              <SubTitle content="(This could take up to one minute.)" />
            )}
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            color="primary"
            disabled={!selectedLicense && !form.watch('licenseNotFound')}
            label="Continue"
            onClick={async () => {
              if (form.watch('licenseNotFound')) {
                setOpenLicenseValidation(false);
                setOpenBusinessesLoading(true);

                await crbmApi
                  .searchBusinesses(dispensary.id)
                  .then((res) => {
                    if (res.length === 0) {
                      form.setValue('businessNotFound', true);
                    } else {
                      const businesses = res
                        .map((b) => {
                          return {
                            id: b.id,
                            businessName: b.name ?? '--',
                            match_quality: b.match_quality
                          };
                        })
                        .slice(0, 5);

                      setAvailableBusinesses(businesses);
                    }
                  })
                  .catch((e) => {
                    console.log(e.message);
                  })
                  .finally(() => {
                    setOpenBusinessesLoading(false);
                    setOpenBusinessValidation(true);
                  });
              } else {
                setOpenLicenseValidation(false);
                setOpenSuccessModal(true);
              }
            }}
            style={{ minWidth: isMobile ? '100%' : '100px' }}
            dataCy="continue-license-button"
          />
        }
        cancelButtonText="Back"
        onCancel={() => {
          setAvailableLicenses([]);
          setOpenLicenseValidation(false);
          props.toggleModal(true);
        }}
        handleClose={() => {
          form.reset({ state: dispensary.state, license_name: '', license_number: '', expiration_date: '' });
          setOpenLicenseValidation(false);
        }}
        height={isMobile ? '400px' : '496px'}
        isOpen={openLicenseValidation}
        title="Marijuana Licenses"
        width={isTablet ? '85vw' : '898px'}
      >
        <Container column data-cy="verify-license-container" padding={0} width="100%">
          <ContainerItem justify="left" padding={isMobile ? '0' : '0 8px 16px 8px'} width="100%">
            <Text
              content="We’ve found the following matches, please choose your Marijuana License."
              sx={isMobile ? { fontSize: '14px', fontWeight: '500', lineHeight: '20px' } : {}}
            />
          </ContainerItem>

          <ContainerItem padding="8px 0" width="100%">
            <TableContainer>
              <Table
                minWidth={590}
                clickable
                noPagination
                singleSelectable
                columns={licenseColumns}
                defaultSort="businessName"
                defaultSortDirection="asc"
                noDataComponent={() => (
                  <ZeroState
                    noBorder
                    titleFont="medium"
                    title="Oops, we weren't able to find your license with the state and license number provided"
                    subTitle={
                      <Box textAlign="center">
                        {preCheckStates.includes(form.getValues('state')) && (
                          <>
                            <Text
                              sx={{ color: 'text.secondary' }}
                              type="body2"
                              content="This state does not make license data readily available. We will attempt to match
                          this license using our third-party license service, however, it’s possible a match
                          won’t be found. You should still go through the full matching process to upload your
                          license."
                            />
                            <br />
                          </>
                        )}
                        <Text
                          type="body2"
                          sx={{ color: 'text.secondary' }}
                          content="Please click “Continue” to try to match your license based on the business name."
                        />
                      </Box>
                    }
                    icon={'/img/empty-states/no-results.svg'}
                  />
                )}
                returnSelection={(selected) => {
                  const license = licenseRows.find((l) => l.id === selected[0]);

                  if (license) {
                    setSelectedLicense({
                      licenseId: license.data.licenseId,
                      businessId: license.data.businessId
                    });
                  }
                }}
                rows={licenseRows}
              />
            </TableContainer>
          </ContainerItem>

          <ContainerItem width="100%">
            <InputCheckBox
              {...form}
              readOnly={availableLicenses.length === 0}
              label="I don’t see my marijuana license"
              name="licenseNotFound"
            />
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            disabled
            color="primary"
            label="Searching..."
            onClick={() => {
              return;
            }}
            style={{ minWidth: isMobile ? '100%' : '100px' }}
            dataCy="continue-business-button"
          />
        }
        handleClose={() => setOpenBusinessesLoading(false)}
        height="356px"
        isOpen={openBusinessesLoading}
        title=""
        width={isMobile ? '360px' : '459px'}
      >
        <Container column data-cy="loading-businesses-container" padding={0} width="100%">
          <ContainerItem justify="center" width="100%">
            <Spinner />
            <br />
            <Text
              sx={
                isMobile
                  ? {
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      color: palette.background.disabled
                    }
                  : {}
              }
              content="Checking business data..."
            />
            {isMobile ? (
              <Text
                sx={
                  isMobile
                    ? {
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px',
                        color: palette.background.disabled
                      }
                    : {}
                }
                content="(This could take up to one minute.)"
              />
            ) : (
              <SubTitle content="(This could take up to one minute.)" />
            )}
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            color="primary"
            disabled={!selectedBusiness && !form.watch('businessNotFound')}
            label="Continue"
            onClick={async () => {
              setOpenBusinessValidation(false);

              if (form.watch('businessNotFound')) {
                setOpenErrorModal(true);
              } else {
                setOpenBusinessLicensesLoading(true);

                await crbmApi
                  .searchBusinessLicenses(selectedBusiness)
                  .then((res) => {
                    const licenses = res.map((l) => {
                      return {
                        id: l.id,
                        businessId: selectedBusiness ? selectedBusiness : '',
                        licenseNumber: l.license_identifier ?? '--',
                        licenseId: l.id.toString() ?? '--',
                        licenseAuthority: l.licensing_authority_name ?? '--',
                        licenseType: l.uniform_license_type_description ?? '--',
                        status: l.uniform_license_status_description ?? '--',
                        med_rec_classification: l.med_rec_classification ?? '--'
                      };
                    });

                    setAvailableBusinessLicenses(licenses);
                  })
                  .finally(() => {
                    setOpenBusinessLicensesLoading(false);
                    setOpenBusinessLicensesValidation(true);
                  });
              }
            }}
            style={{ minWidth: isMobile ? '100%' : '100px' }}
            dataCy="continue-business-name-button"
          />
        }
        cancelButtonText="Back"
        onCancel={() => {
          setAvailableBusinesses([]);
          setOpenBusinessValidation(false);
          setOpenLicenseValidation(true);

          if (availableLicenses.length === 0) {
            form.setValue('licenseNotFound', true);
          }
        }}
        handleClose={() => {
          setOpenBusinessValidation(false);
        }}
        height={isMobile ? '400px' : '496px'}
        isOpen={openBusinessValidation}
        title="Marijuana Businesses"
        width={isMobile ? '360px' : isTablet ? '85vw' : '898px'}
      >
        <Container column data-cy="verify-license-container" padding={0} width="100%">
          <ContainerItem justify="left" padding={isMobile ? '0' : '0 8px 16px 8px'} width="100%">
            <Text
              sx={isMobile ? { fontSize: '14px', fontWeight: '500', lineHeight: '20px' } : {}}
              content="We’ve found the following matches, please choose your Marijuana Business."
            />
          </ContainerItem>

          <ContainerItem padding="8px 4px 8px 0" width="100%">
            <TableContainer>
              <Table
                minWidth={isMobile ? 160 : 590}
                clickable
                noPagination
                singleSelectable
                columns={businessColumns}
                defaultSort="match_quality"
                defaultSortDirection="desc"
                noDataComponent={() => (
                  <ZeroState
                    noBorder
                    titleFont="medium"
                    title="Oops, we weren't able to find your business with the name provided"
                    icon={'/img/empty-states/no-results.svg'}
                  />
                )}
                returnSelection={(selected) => {
                  setSelectedBusiness(selected[0]);
                }}
                rows={businessRows}
              />
            </TableContainer>
          </ContainerItem>

          <ContainerItem width="100%">
            <InputCheckBox
              {...form}
              label="I don’t see my marijuana business"
              name="businessNotFound"
              readOnly={availableBusinesses.length === 0}
              checked={availableBusinesses.length === 0}
            />
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            disabled
            color="primary"
            label="Searching..."
            onClick={() => {
              return;
            }}
          />
        }
        handleClose={() => setOpenBusinessLicensesLoading(false)}
        height="356px"
        isOpen={openBusinessLicensesLoading}
        title=""
        width="459px"
      >
        <Container column data-cy="loading-business-licenses-container" padding={0} width="100%">
          <ContainerItem justify="center" width="100%">
            <Spinner />
            <br />
            <Text
              sx={
                isMobile
                  ? {
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      color: palette.background.disabled
                    }
                  : {}
              }
              content="Checking business license data..."
            />
            {isMobile ? (
              <Text
                sx={
                  isMobile
                    ? {
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px',
                        color: palette.background.disabled
                      }
                    : {}
                }
                content="(This could take up to one minute.)"
              />
            ) : (
              <SubTitle content="(This could take up to one minute.)" />
            )}
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        action={
          <Button
            color="primary"
            disabled={!selectedBusinessLicense && !form.watch('businessLicenseNotFound')}
            label="Continue"
            onClick={() => {
              setOpenBusinessLicensesValidation(false);

              if (form.watch('businessLicenseNotFound')) {
                setOpenErrorModal(true);
              } else {
                setOpenSuccessModal(true);
              }
            }}
            style={{ minWidth: isMobile ? '100%' : '100px' }}
          />
        }
        cancelButtonText="Back"
        onCancel={() => {
          setAvailableBusinessLicenses([]);
          setOpenBusinessLicensesValidation(false);
          setOpenBusinessValidation(true);
        }}
        handleClose={() => {
          setOpenBusinessLicensesValidation(false);
          form.reset({ state: dispensary.state, license_name: '', license_number: '', expiration_date: '' });
        }}
        height={isMobile ? '400px' : '730px'}
        isOpen={openBusinessLicensesValidation}
        title="Marijuana Licenses"
        width={isTablet ? '85vw' : '898px'}
      >
        <Container column data-cy="verify-business-license-container" padding={0} width="100%">
          <ContainerItem justify="left" padding={isMobile ? '0' : '0 8px 16px 8px'} width="100%">
            <Text content="We’ve found the following matches, please choose your Marijuana License." />
          </ContainerItem>

          <ContainerItem padding="8px 0" width="100%">
            <TableContainer>
              <Table
                minWidth={590}
                clickable
                singleSelectable
                columns={businessLicensesColumns}
                defaultSort="licenseNumber"
                defaultSortDirection="asc"
                noDataComponent={() => (
                  <ZeroState
                    noBorder
                    titleFont="medium"
                    title="Oops, we weren't able to find your license with the business provided"
                    icon={'/img/empty-states/no-results.svg'}
                  />
                )}
                returnSelection={(selected) => {
                  const businessLicense = businessLicensesRows.find((bl) => bl.id === selected[0]);

                  if (businessLicense) {
                    setSelectedBusinessLicense({
                      licenseId: businessLicense.data.licenseId,
                      businessId: businessLicense.data.businessId
                    });
                  }
                }}
                rows={businessLicensesRows}
              />
            </TableContainer>
          </ContainerItem>

          <ContainerItem width="100%">
            <InputCheckBox
              {...form}
              label="I don’t see my marijuana license"
              name="businessLicenseNotFound"
            />
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        handleClose={async () => {
          setOpenSuccessModal(false);
          await updateFileData(uploadedFiles, licenseData);
          form.reset({ state: dispensary.state, license_name: '', license_number: '', expiration_date: '' });
        }}
        isOpen={openSuccessModal}
        noCancelAction
        title=""
        action={
          <Button
            fullWidth
            color="primary"
            label="Great!"
            onClick={async () => {
              setOpenSuccessModal(false);
              await updateFileData(uploadedFiles, licenseData);
              form.reset({
                state: dispensary.state,
                license_name: '',
                license_number: '',
                expiration_date: ''
              });
            }}
          />
        }
      >
        <Container column data-cy="verify-license-container" padding={0} width="100%">
          <ContainerItem justify="center" height="300px" width="100%">
            <Box sx={{ color: (theme) => theme.palette.primary.main }}>
              <SuccessIcon />
            </Box>
            <br />
            <Header type="h2" content="Success!" />
            <br />
            <Text content="Your license has been officially verified." />
          </ContainerItem>
        </Container>
      </Dialog>

      <Dialog
        handleClose={async () => {
          setOpenErrorModal(false);
          await updateFileData(uploadedFiles, licenseData);
          form.reset({ state: dispensary.state, license_name: '', license_number: '', expiration_date: '' });
        }}
        isOpen={openErrorModal}
        title=""
        noCancelAction
        action={
          <Button
            fullWidth
            color="primary"
            label="Ok, upload anyway"
            onClick={async () => {
              setOpenErrorModal(false);
              await updateFileData(uploadedFiles, licenseData);
              form.reset({
                state: dispensary.state,
                license_name: '',
                license_number: '',
                expiration_date: ''
              });
            }}
            dataCy="ok-button"
          />
        }
      >
        <Container column data-cy="verify-license-container" padding={0} width="100%">
          <ContainerItem justify="center" height="330px" width="100%">
            <Box sx={{ color: (theme) => theme.palette.error.main }}>
              <ErrorIcon />
            </Box>
            <br />
            <Header type="h2" content="License was not found" />
            <br />
            <Text content="We are sorry about that. We use a third-party license validation service and it appears we couldn't find a match given the details provided." />
          </ContainerItem>
        </Container>
      </Dialog>
    </>
  );
};
