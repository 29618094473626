import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Container, ContainerItem, Form, Header, InputEmail, InputPhone } from 'ui';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  isMobile: boolean;
  onChange?: () => void;
}
export const ContactInfoForm: React.FC<Props> = ({ form, isMobile, onChange }) => {
  return (
    <Container padding="0" dataCy="contact-info-container">
      <Container padding={isMobile ? '20px 0 8px 0' : '28px 0 8px 0'}>
        <Header mobileHeader={isMobile} content="Contact Information" type="h2" />
      </Container>
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputEmail
              label="Email"
              name="email"
              rules={{ required: { message: 'is required', value: true } }}
              tooltip="Please reach out to support@greencheckverified.com to change your email"
              dataCy="email"
              readOnly
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputPhone
              name="phone"
              label="Phone"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="phone"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
        </Container>
      </Form>
    </Container>
  );
};
