import { GCD_RIVERBANK_BANK_ID } from '@gcv/shared';
import { SsnMask } from 'domain/consts/input-masks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Container, ContainerItem, Form, Header, InputDate, InputMasked, InputText } from 'ui';
import { IdVerificationPresenter } from '../id-verification-presenter';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  isMobile: boolean;
  onChange?: (data: any) => void;
  onBlur?: () => void;
}
export const PersonalDetailsForm: React.FC<Props> = ({ form, isMobile, onChange, onBlur }) => {
  const presenter = useInjection(IdVerificationPresenter);

  return (
    <Container padding="0" dataCy="personal-details-card">
      <Container padding="0 0 8px 0">
        <Header mobileHeader={isMobile} content="Personal Details" type="h2" />
      </Container>
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="First Name"
              name="firstName"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="first-name"
              onChange={onChange}
              onBlur={onBlur}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="Middle Name"
              name="minitial"
              dataCy="middle-name"
              onChange={onChange}
              onBlur={onBlur}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="Last Name"
              name="lastName"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="last-name"
              onChange={onChange}
              onBlur={onBlur}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputDate
              label="Date of Birth"
              name="dateOfBirth"
              timezone={presenter.crbDispensaryStore.currentDispensary.iana_timezone}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="date-of-birth"
              onChange={onChange}
              onBlur={onBlur}
              {...form}
            />
          </ContainerItem>
          {Object.keys(presenter.crbBankStore.banks).includes(GCD_RIVERBANK_BANK_ID) && (
            <ContainerItem
              padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
              width="100%"
              maxWidth={isMobile ? 'none' : '20%'}
              minWidth={isMobile ? 'none' : '200px'}
            >
              <InputMasked
                mask={SsnMask}
                label="SSN"
                name="ssn"
                rules={{
                  required: { message: 'is required', value: true },
                  minLength: { message: 'must be in ###-##-#### format', value: 11 },
                  maxLength: { message: 'must be in ###-##-#### format', value: 11 }
                }}
                onChange={onChange}
                {...form}
              />
            </ContainerItem>
          )}
        </Container>
      </Form>
    </Container>
  );
};
