import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';
import { ReactComponent as WarningIcon } from 'assets/images/ic-modal-alert.svg';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSave: () => void;
}

export const SectionDeleteModal: React.FC<Props> = (props) => {
  return (
    <Dialog noActions title="" isOpen={props.isOpen} handleClose={() => props.setIsOpen(false)} width="459px">
      <Container column padding={0} width="100%">
        <ContainerItem justify="center" padding="0 0 20px 0" width="100%">
          <WarningIcon />
        </ContainerItem>
        <ContainerItem justify="center" padding="0 0 20px 0" width="100%">
          <Header type="h2" content="Delete this section?" />
        </ContainerItem>
        <ContainerItem justify="center" padding="0 0 50px 0" width="100%">
          <Text content="If you need fields from a section, remove them from the section before deleting the section. Deleting this section will remove all fields within it, including fields with smart rules." />
        </ContainerItem>
        <ContainerItem justify="center" width="100%">
          <Button
            label="Delete Section"
            color="warning"
            onClick={() => {
              props.onSave();
              props.setIsOpen(false);
            }}
            width="335px"
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
