import { CustomRequirementResult, QuestionnaireResponse, TemplateResponse } from '@gcv/shared';
import { QuestionnairesApi, TemplateApi } from 'api';

// TODO This util is in my sights to deprecate. Some of the functionality in here are bandaids to bugs that have been fxied upstream,
// or functionality that we don't want to use anymore (like saving all sections at once)
export class AdditionalInfoUtil {
  templateApi = new TemplateApi();
  questionnaireApi = new QuestionnairesApi();

  templateResponse: TemplateResponse | QuestionnaireResponse;
  dispensaryId: string;
  isBank: boolean;

  constructor(
    templateResponse: TemplateResponse | QuestionnaireResponse,
    dispensaryId: string,
    isBank: boolean
  ) {
    this.templateResponse = templateResponse;
    this.dispensaryId = dispensaryId;
    this.isBank = isBank;
  }

  // check that we are saving only the fields associated with the section
  // and not the entire fieldset in each section
  public deDupeCustomFields = (customFields: Record<string, any>, sectionId: string) => {
    const sectionFields: Record<string, any> = { responses: {} };
    const templateSection = this.templateResponse.custom_requirements[sectionId];

    templateSection.custom_section.fields.forEach((field) => {
      if (customFields.responses[field.id] != null) {
        sectionFields.responses[field.id] = customFields.responses[field.id];
      }
    });

    return sectionFields;
  };

  public saveAndCompleteOnboardingTemplate = async (
    customFieldValues: Record<string, any>
  ): Promise<CustomRequirementResult[]> => {
    try {
      if (this.templateResponse) {
        const requirementIds = Object.entries(this.templateResponse.custom_requirements)
          .filter(([k, v]) => !v.archived)
          .map(([k, v]) => k);

        const savePromises: Promise<CustomRequirementResult | null>[] = [];

        for (let index = 0; index < requirementIds.length; index++) {
          const sectionId = requirementIds[index];

          savePromises.push(
            this.saveSection(this.deDupeCustomFields(customFieldValues, sectionId), sectionId)
          );
        }

        const results = await Promise.all(savePromises);
        return results.filter((r) => r != null) as CustomRequirementResult[];
      }
      return [];
    } catch (error) {
      console.log('Issue saving section', error);
      return [];
    }
  };

  public saveSection = async (
    customFieldValues: Record<string, any>,
    sectionId: string
  ): Promise<CustomRequirementResult | null> => {
    if (!this.templateResponse.custom_requirements[sectionId].archived) {
      if (this.isBank) {
        return await this.templateApi.updateBankCrbTemplateRequirement(
          this.dispensaryId,
          this.templateResponse.bank_id,
          this.templateResponse.template_id,
          sectionId,
          customFieldValues
        );
      } else {
        return await this.templateApi.updateCrbTemplateRequirement(
          this.dispensaryId,
          this.templateResponse.bank_id,
          this.templateResponse.template_id,
          sectionId,
          customFieldValues
        );
      }
    } else {
      return null;
    }
  };

  public saveQuestionnaireSection = async (
    customFieldValues: Record<string, any>,
    questionnaireResultId: string,
    sectionId: string
  ): Promise<CustomRequirementResult | null> => {
    if (!this.templateResponse.custom_requirements[sectionId].archived) {
      return await this.questionnaireApi.updateQuestionnaireRequirementResult(
        this.dispensaryId,
        this.templateResponse.bank_id,
        this.templateResponse.template_id,
        questionnaireResultId,
        sectionId,
        customFieldValues
      );
    } else {
      return null;
    }
  };
}
