import { GCD_RIVERBANK_BANK_ID, PAYQWICK_BANK_ID } from '@gcv/shared';
import { useMediaQuery, useTheme } from '@mui/material';
import { GcdRoleOptions, PayqwickRoleOptions } from 'domain/consts/roles';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { getCrbBankStore } from 'stores/CrbBankStore';
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Form,
  Hyperlink,
  InputEmail,
  InputSelect,
  InputText,
  Text
} from 'ui';
import { OnboardingPresenter } from '../../../onboarding.presenter';
import { AccountOwnersPresenter } from '../account-owners-presenter';

export const AddAccountOwnerModal: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const crbBankStore = getCrbBankStore();
  const form = useForm({ mode: 'onBlur', defaultValues: {} as FieldValues });
  const presenter = useInjection(AccountOwnersPresenter);
  const onboardingPresenter = useInjection(OnboardingPresenter);

  return (
    <Dialog
      action={
        <Button
          color="primary"
          label="Invite"
          onClick={form.handleSubmit(presenter.inviteUser)}
          disabled={!form.formState.isValid}
          dataCy="invite-button"
          style={{ width: '100%' }}
        />
      }
      handleClose={() => presenter.setAccountOwnerModal(false)}
      isOpen={presenter.viewModel.accountOwnerModalOpen}
      title="Invite this user to Green Check"
      noCancelAction={isMobile}
    >
      <Form>
        <Container column padding={0} width="100%">
          <ContainerItem padding="8px 0" width="100%">
            <InputText
              {...form}
              name="firstName"
              label="First Name"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="first-name"
            />
          </ContainerItem>

          <ContainerItem padding="8px 0" width="100%">
            <InputText
              {...form}
              name="lastName"
              label="Last Name"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="last-name"
            />
          </ContainerItem>

          <ContainerItem padding="8px 0" width="100%">
            <InputEmail
              {...form}
              name="email"
              label="Email"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="email"
            />
          </ContainerItem>
        </Container>
      </Form>
      <Container padding="0 0 0 0">
        <ContainerItem padding="8px 0">
          <Text
            content="This user will receive an email with instructions for setting up their account on Green Check."
            dataCy="helper-text"
            sx={
              isMobile
                ? { fontSize: '13px', fontWeight: '500', lineHeight: '15.6px' }
                : { fontSize: '16px', fontWeight: '400', lineHeight: '24px' }
            }
          />
        </ContainerItem>
      </Container>

      {crbBankStore.payqwickOrgId === PAYQWICK_BANK_ID ? (
        <>
          <ContainerItem width="100%" margin="0.75rem 0" padding="0">
            <Text sx={{ fontWeight: 'bold' }} content="Permission to access the PayQwick system" />
          </ContainerItem>

          <ContainerItem width="100%" padding="0">
            <Text inline content="Learn more about PayQwick user roles and permissions in this " />
            <Hyperlink
              label="support article."
              newTab
              url="https://support.greencheckverified.com/knowledge/payqwick-user-access-comparison-chart"
            />
          </ContainerItem>

          <ContainerItem width="100%" margin="0.75rem 0" padding="0">
            <InputSelect
              {...form}
              label="Select Role"
              name="pq_user_role"
              options={PayqwickRoleOptions}
              rules={{
                required: { message: 'is required', value: true }
              }}
              defaultValue={'none'}
              dataCy="pq_user_role"
            />
          </ContainerItem>
        </>
      ) : null}
      {crbBankStore.payqwickOrgId === GCD_RIVERBANK_BANK_ID ? (
        <>
          <ContainerItem width="100%" margin="0.75rem 0" padding="0">
            <Text sx={{ fontWeight: 'bold' }} content="Permission to access the Green Check Direct system" />
          </ContainerItem>

          <ContainerItem width="100%" padding="0">
            <Text inline content="Learn more about Green Check Direct user roles and permissions in this " />
            <Hyperlink
              label="support article."
              newTab
              url="https://support.greencheckverified.com/knowledge/payqwick-user-access-comparison-chart"
            />
          </ContainerItem>

          <ContainerItem width="100%" margin="0.75rem 0" padding="0">
            <InputSelect
              {...form}
              label="Select Role"
              name="gcd_user_role"
              options={GcdRoleOptions}
              rules={{
                required: { message: 'is required', value: true }
              }}
              defaultValue={'none'}
              dataCy="gcd_user_role"
            />
          </ContainerItem>
        </>
      ) : null}
    </Dialog>
  );
};
