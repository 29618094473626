import {
  AccountReviewData,
  AccountReviewSections,
  BankCoreTransactionSnapshot,
  CORE_TRANSACTIONS_ORPHAN_REASON,
  CoreSftpUploadReport,
  CoreTransactionPaymentType,
  CoreTransactionTransactionType,
  DispensaryAccountReviewTableData,
  DispensaryAccountReviewWithData,
  DispensaryMonitoringData,
  DispensaryPeriodSummaryCoreTransactions,
  DispensaryRiskRating,
  IngestionReportResponse,
  MinifiedOrphanTransaction,
  MinifiedReviewCoreTransaction,
  ReviewPeriodType,
  ReviewTargets
} from '@gcv/shared';
import { ReviewWithData } from 'ui/apps/fi/monitoring/pages/review/components/analysis/model';
import { MUODispensaryAccountReviewWithData } from 'ui/apps/fi/monitoring/pages/review/model';
import { api, banksApi } from './api-util/api';

export class MonitoringApi {
  async getMonitoring(
    bankId: string,
    startDate?: string,
    endDate?: string
  ): Promise<DispensaryMonitoringData[]> {
    return await banksApi().get(`/banks/${bankId}/monitoring?startDate=${startDate}&endDate=${endDate}`);
  }

  async getOpenReviews(
    bankId: string,
    startDate?: string,
    endDate?: string
  ): Promise<DispensaryAccountReviewTableData[]> {
    return await banksApi().get(
      `/banks/${bankId}/reviews?status=open&startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getCompletedReviews(
    bankId: string,
    startDate?: string,
    endDate?: string
  ): Promise<DispensaryAccountReviewTableData[]> {
    return await banksApi().get(
      `/banks/${bankId}/reviews?status=completed&startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getReviewById(
    reviewId: string
  ): Promise<DispensaryAccountReviewWithData | MUODispensaryAccountReviewWithData> {
    return await api().get(`/account-review/${reviewId}`);
  }

  async getReviewByIdMinified(reviewId: string): Promise<DispensaryAccountReviewTableData> {
    return await api().get(`/account-review/${reviewId}/minified`);
  }

  async startReview(
    reviewId: string
  ): Promise<DispensaryAccountReviewWithData | MUODispensaryAccountReviewWithData> {
    return await api().post(`/account-review/${reviewId}/start`, {});
  }

  async putApproveSection(sectionName: AccountReviewSections, reviewId: string): Promise<ReviewWithData> {
    return await api().put(`/account-review/${reviewId}/section/${sectionName}/status`, {
      status: 'completed'
    });
  }

  async updateRiskRating(newValue: string, reviewId: string): Promise<ReviewWithData> {
    return await api().put(`/account-review/${reviewId}/risk-rating`, { final_risk_rating: newValue });
  }

  async updateNarrative(newValue: string, reviewId: string): Promise<ReviewWithData> {
    return await api().put(`/account-review/${reviewId}/narrative`, { narrative: newValue });
  }

  async updateDocumentIds(ids: string[], reviewId: string): Promise<ReviewWithData> {
    return await api().post(`/account-review/${reviewId}/documents`, { documents: ids });
  }

  async deleteReviews(bankId: string, ids: string[]): Promise<ReviewWithData> {
    return await banksApi().post(`/banks/${bankId}/account-review-batch-delete`, { reviewIds: ids });
  }

  async getAccountActivity(
    bankId: string,
    dispId: string,
    startDate: string,
    endDate: string,
    chunkSize: ReviewPeriodType,
    numberOfDaysInChunk?: number
  ) {
    let url = `/banks/${bankId}/dispensaries/${dispId}/monitoring?startDate=${startDate}&endDate=${endDate}&chunkSize=${chunkSize}`;
    if (chunkSize === ReviewPeriodType.Custom && numberOfDaysInChunk) {
      url = url + `&numberOfDays=${numberOfDaysInChunk}`;
    }
    return banksApi().get(url);
  }

  async getAccountReviewData(dispensaryIds: string[]): Promise<ReviewWithData> {
    const reviewIdString = dispensaryIds.map((id) => `reviewId=${id}`).join('&');
    const url = `/account-reviews?${reviewIdString}`;
    return await api().get(url);
  }

  async queryAccountNumbers(reviewId: string, targets: ReviewTargets): Promise<AccountReviewData> {
    return await api().post(`/account-review/${reviewId}/query-account-numbers`, { targets });
  }

  /**
   *
   * @param reviewIds For all reviews, pass in the location review IDS
   * @param targets For V2 reviews, also pass in targets
   * @returns
   */
  async queryAccountReviews(reviewIds: string[], targets?: ReviewTargets): Promise<AccountReviewData> {
    const url = `/query-account-reviews`;
    return await api().post(url, { reviewIds, targets });
  }

  async completeReview(
    reviewId: string
  ): Promise<DispensaryAccountReviewWithData | MUODispensaryAccountReviewWithData> {
    return await api().post(`/account-review/${reviewId}/complete`, {});
  }

  async queryAccountActivity(
    bankId: string,
    dispId: string,
    startDate: string,
    endDate: string,
    chunkSize: ReviewPeriodType,
    locationIds: string[],
    numberOfDaysInChunk?: number,
    reviewTargets?: ReviewTargets
  ) {
    let url = `/banks/${bankId}/dispensaries/${dispId}/monitoring?startDate=${startDate}&endDate=${endDate}&chunkSize=${chunkSize}`;
    if (chunkSize === ReviewPeriodType.Custom && numberOfDaysInChunk) {
      url = url + `&numberOfDays=${numberOfDaysInChunk}`;
    }
    return banksApi().post(url, { filterDispensaryIds: locationIds, targets: reviewTargets });
  }
  async individualRiskRating(
    bankId: string,
    muoId: string,
    reviewId: string | number,
    body: Record<string, DispensaryRiskRating>
  ): Promise<ReviewWithData> {
    return await banksApi().put(`/banks/${bankId}/muo/${muoId}/review/${reviewId}/risk-rating`, body);
  }

  async getSftpCoreUploads(
    bankId: string,
    startDate: string,
    endDate: string,
    includeDryRuns: boolean
  ): Promise<CoreSftpUploadReport[]> {
    return await banksApi().get(
      `/banks/${bankId}/sftp-core-txn-reports?startDate=${startDate}&endDate=${endDate}&includeDryruns=${includeDryRuns}`
    );
  }

  /**
   * Return Promise containing number of comments associated with report.
   *
   * The number comprises all comments made by either financial institution users
   * or dispensary users during the report's period.
   *
   * @param bankId bank ID string for report.
   * @param crbId dispensary ID string for report.
   * @param startDate as YYYY-MM-DD string for report period start date.
   * @param endDate as YYYY-MM-DD string for report period end date.
   *
   * @returns number promise containing comments count.
   */
  async getCommentsCount(bankId: string, crbId: string, startDate: string, endDate: string): Promise<number> {
    // The API accepts start and end parameters as optional, but we will not be taking advantage of that.
    const result: { count: number } = await banksApi().get(
      `/banks/${bankId}/crb/${crbId}/comment-history-count?start=${startDate}&end=${endDate}`
    );

    return result.count;
  }

  /**
   * Request back-end generate report of comments created during review period.
   *
   * @param bankId bank ID string for report.
   * @param crbId dispensary ID string for report.
   * @param startDate as YYYY-MM-DD string for report period start date.
   * @param endDate as YYYY-MM-DD string for report period end date.
   *
   * @returns void promise.
   */
  async createCommentsReport(
    bankId: string,
    crbId: string,
    startDate: string,
    endDate: string
  ): Promise<void> {
    return await banksApi().post(`/banks/${bankId}/reporting/comments/${crbId}`, {
      startDate: startDate,
      endDate: endDate
    });
  }

  /** Request to get sftp report transaction list for report id **/
  async getSftpReport(bankId: string, reportId: string): Promise<BankCoreTransactionSnapshot[]> {
    return await banksApi().get(`/banks/${bankId}/sftp-upload-report/${reportId}`);
  }

  async getOrphanTransactions(
    bankId: string,
    startDate: string,
    endDate: string
  ): Promise<MinifiedOrphanTransaction[]> {
    return await banksApi().get(
      `/banks/${bankId}/core-transactions/unmatched?startDate=${startDate}&endDate=${endDate}`
    );
  }

  /** Put endpoint to ignore a list of transaction ids **/
  async ignoreTransactions(bankId: string, txIds: string[]): Promise<MinifiedOrphanTransaction[]> {
    return await banksApi().put(`/banks/${bankId}/core-transactions/ignore`, { ids: txIds });
  }

  async fetchTransactionsForDispensaries(
    bankId: string,
    dispensaryIds: string[],
    startDate: string,
    endDate: string,
    paymentType?: CoreTransactionPaymentType,
    transactionType?: CoreTransactionTransactionType
  ): Promise<MinifiedReviewCoreTransaction[]> {
    return await banksApi().post(
      `/banks/${bankId}/core-transactions/dispensaries/query`,
      { dispensaryIds: dispensaryIds },
      { startDate, endDate, paymentType, transactionType }
    );
  }
  async fetchTransactionsByAccountNumbers(
    bankId: string,
    accountNumbers: string[],
    startDate: string,
    endDate: string,
    paymentType?: CoreTransactionPaymentType,
    transactionType?: CoreTransactionTransactionType
  ): Promise<MinifiedReviewCoreTransaction[]> {
    return await banksApi().post(
      `/banks/${bankId}/core-transactions/internalId/query`,
      {
        internalIds: accountNumbers
      },
      { startDate, endDate, paymentType, transactionType }
    );
  }

  async exportUnmatchedTransactions(
    bankId: string,
    userId: string,
    startDate?: string,
    endDate?: string,
    orphanReason?: CORE_TRANSACTIONS_ORPHAN_REASON
  ): Promise<null> {
    return await banksApi().post(`/banks/${bankId}/reporting/core-transactions/unmatched`, {
      orgId: bankId,
      userId: userId,
      reportData: {
        bankId,
        startDate,
        endDate,
        orphanReason
      },
      reportType: 'fi_unmatched_core_transaction_report'
    });
  }

  async getIngestionData(
    bankID: string,
    startDate?: string,
    endDate?: string
  ): Promise<IngestionReportResponse[]> {
    return await banksApi().get(
      `/banks/${bankID}/ingestion-reports?startDate=${startDate}&endDate=${endDate}`
    );
  }

  async fetchTransactionsByAccountNumber(
    bankId: string,
    reviewId: string
  ): Promise<
    | {
        [account_number: string]: DispensaryPeriodSummaryCoreTransactions;
      }
    | {
        [account_number: string]: DispensaryPeriodSummaryCoreTransactions;
      }[]
  > {
    return await banksApi().get(`/banks/${bankId}/account-review/${reviewId}/account-number-transactions`);
  }
}
