import { Box } from '@mui/material';
import * as React from 'react';
import { theme } from 'ui/theme';

interface Props {
  align?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'stretch';
  borderBottom?: boolean;
  column?: boolean;
  dataCy?: string;
  gap?: boolean;
  grow?: boolean;
  height?: string;
  id?: string;
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly';
  margin?: number | string;
  maxWidth?: string;
  minWidth?: string;
  nowrap?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  overflow?: string;
  padding?:
    | string
    | number
    | { xs?: string | number; sm?: string | number; md?: string | number; lg?: string | number };
  position?: string;
  transparent?: boolean;
  width?: string;
  wrapReverse?: boolean;
  children?: React.ReactNode;
}

export const Container: React.FC<Props> = (props) => {
  const align = props.align ? props.align : 'flex-start';
  const direction = props.column ? 'column' : 'row';
  const grow = props.grow ? 1 : 0;
  const height = props.height ? props.height : 'auto';
  const justify = props.justify ? props.justify : 'flex-start';
  const margin = props.margin !== undefined ? props.margin : 0;
  const maxWidth = props.maxWidth ? props.maxWidth : 'none';
  const minWidth = props.minWidth ? props.minWidth : 'none';
  const overflow = props.overflow ? props.overflow : 'unset';
  const padding = props.padding !== undefined ? props.padding : 1;
  const position = props.position ? props.position : 'none';
  const width = props.width ? props.width : 'auto';
  const wrap = props.nowrap ? 'nowrap' : props.wrapReverse ? 'wrap-reverse' : 'wrap';

  return (
    <Box
      data-cy={props.dataCy}
      id={props.id}
      onClick={(e) => {
        if (props.onClick) props.onClick(e);
      }}
      sx={{
        alignItems: align,
        borderBottom: props.borderBottom ? `1px solid ${theme.palette.divider}` : '0px',
        display: 'flex',
        flexDirection: direction,
        flexGrow: grow,
        flexWrap: wrap,
        gap: props.gap ? 2 : 0,
        height: height,
        justifyContent: justify,
        m: margin,
        maxWidth: maxWidth,
        minWidth: minWidth,
        opacity: props.transparent ? '40%' : '100%',
        overflow: overflow,
        p: padding,
        position: position,
        width: width
      }}
    >
      {props.children}
    </Box>
  );
};
