import * as React from 'react';
import { Box } from '@mui/material';
import { Spinner } from 'ui';

interface SpinnerProps {
  text?: string;
  dataCy?: string;
  dataCyText?: string;
  fullHeight?: boolean;
  solidBackground?: boolean;
}

export const OverlaySpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        height: props.fullHeight ? '100%' : 'calc(100% - 1rem)',
        zIndex: 9999,
        background: 'white',
        opacity: props.solidBackground ? '1' : '0.7'
      }}
    >
      <Spinner {...props} />
    </Box>
  );
};
