import { IANATimezones, QuestionnaireStatus, TaskStatus, User } from '@gcv/shared';
import Dinero from 'dinero.js';
import { DateTime } from 'luxon';
import { theme } from 'ui/theme';
import { DateTimeHelpers } from './dateTime.util';

export const cropInput = (string: string, length: number, type?: string) => {
  const ellipsis = '...';
  if (string && string.length > length) {
    const splitDocStrings = string.split('.');
    const extension = splitDocStrings[splitDocStrings.length - 1];
    const name =
      splitDocStrings.length > 2
        ? splitDocStrings.slice(0, splitDocStrings.length - 2).join('')
        : splitDocStrings[0];
    if (type === 'document' && extension) {
      const remainingLength = length - extension.length - ellipsis.length;
      const truncatedNameBeginning = name.slice(0, remainingLength - extension.length - 1);
      const truncatedNameEnding = name.slice(name.length - extension.length, name.length);
      return truncatedNameBeginning + ellipsis + truncatedNameEnding + '.' + extension;
    } else {
      return string.slice(0, length - 3) + ellipsis;
    }
  } else {
    return string;
  }
};

export const formatOrdinalIndicator = (i: number) => {
  if (!i) return '';

  const fixedNumber = Number(i.toFixed(0));

  const j = fixedNumber % 10,
    k = fixedNumber % 100;

  if (j == 1 && k != 11) {
    return fixedNumber + 'st';
  }

  if (j == 2 && k != 12) {
    return fixedNumber + 'nd';
  }

  if (j == 3 && k != 13) {
    return fixedNumber + 'rd';
  }

  return fixedNumber + 'th';
};

export const formatMoney = (value?: number) => {
  if (value == null || typeof value !== 'number' || isNaN(value)) {
    return '';
  }
  if (!Number.isInteger(value)) {
    value = Math.ceil(value) === 1 ? 1 : Math.floor(value);
  }
  const format = value === 0 ? '$0' : '$0,0.00';
  return Dinero({ amount: value }).toFormat(format);
};

export const formatMoneyWithNegatives = (value: number) => {
  if (value == null || typeof value !== 'number' || isNaN(value)) {
    return '';
  }
  if (!Number.isInteger(value)) {
    value = Math.ceil(value) === 1 ? 1 : Math.floor(value);
  }
  const format = '$0,0.00';
  if (value < 0) {
    return '(' + Dinero({ amount: value * -1 }).toFormat(format) + ')';
  } else {
    return Dinero({ amount: value }).toFormat(format);
  }
};

export const formatUserFirstAndLastName = (user: User) => {
  return user ? user.firstName + ' ' + user.lastName : '';
};

export const formatNotAvailable = (item: string) => (item ? item : 'Not Available');

export const formatMissingDash = (item: any) => (item === 0 || item ? item : '--');

export const formatPercentage = (numerator?: number, denominator?: number) => {
  if (!denominator || typeof denominator !== 'number' || typeof numerator !== 'number') {
    return '0.0%';
  } else if ((numerator / denominator) * 100 < 0.1 && (numerator / denominator) * 100 > 0) {
    return '< 0.1%';
  } else {
    return ((numerator / denominator) * 100).toFixed(1) + '%';
  }
};

export const formatPercentageInteger = (numerator?: number, denominator?: number) => {
  if (!denominator || typeof denominator !== 'number' || typeof numerator !== 'number') {
    return '0%';
  } else if ((numerator / denominator) * 100 < 0.1 && (numerator / denominator) * 100 > 0) {
    return '< 0%';
  } else {
    return Math.floor((numerator / denominator) * 100) + '%';
  }
};

export const formatChangePercentage = (initialValue: number, currentValue: number) => {
  if (!initialValue || typeof initialValue !== 'number' || typeof currentValue !== 'number') {
    return '0.0%';
  } else if (
    ((currentValue - initialValue) / initialValue) * 100 < 0.1 &&
    ((currentValue - initialValue) / initialValue) * 100 > 0
  ) {
    return '0.1%';
  } else if (
    ((currentValue - initialValue) / initialValue) * 100 > -0.1 &&
    ((currentValue - initialValue) / initialValue) * 100 < 0
  ) {
    return '-0.1%';
  } else {
    return (((currentValue - initialValue) / initialValue) * 100).toFixed(1) + '%';
  }
};

export const parsePhoneNumber = (phoneValue: string): string => {
  if (phoneValue !== '') {
    const phoneNumber = phoneValue.replace(/[- )(]/g, '');
    return phoneNumber;
  } else {
    return '';
  }
};

export const formatPhoneNumber = (phoneString: string | undefined): string => {
  if (!phoneString) {
    return '';
  }

  if (phoneString.length === 10) {
    return `(${phoneString.substr(0, 3)}) ${phoneString.substr(3, 3)}-${phoneString.substr(6, 4)}`;
  } else if (phoneString.length === 11) {
    return `${phoneString.substr(0, 1)} (${phoneString.substr(1, 3)}) ${phoneString.substr(
      4,
      3
    )}-${phoneString.substr(7, 4)}`;
  } else {
    return phoneString;
  }
};

export const formatAmPm = (time: string | undefined) => {
  if (!time) {
    return '';
  }

  const splitTime = time.split(':');
  const hourNumber = parseInt(splitTime[0]);

  if (0 === hourNumber || 24 === hourNumber) {
    return '12 AM';
  } else if (12 > hourNumber) {
    return `${hourNumber}:${splitTime[1]} AM`;
  } else if (12 === hourNumber) {
    return `12:${splitTime[1]} PM`;
  } else {
    return `${hourNumber - 12}:${splitTime[1]} PM`;
  }
};

export const getQuestionnaireStatusText = (status: QuestionnaireStatus) => {
  return status === QuestionnaireStatus.Open
    ? 'Not Started'
    : status === QuestionnaireStatus.ReadyToComplete
    ? QuestionnaireStatus.InProgress
    : status;
};

export const getQuestionnaireStatusColor = (status: QuestionnaireStatus) => {
  if (status === QuestionnaireStatus.Open) {
    return theme.palette.text.secondary;
  } else if (status === QuestionnaireStatus.Completed) {
    return theme.palette.primary.main;
  } else if (status === QuestionnaireStatus.Cancelled) {
    return theme.palette.error.main;
  } else {
    return theme.palette.secondary.main;
  }
};

export const getTaskStatusText = (status: TaskStatus) => {
  if (status === TaskStatus.COMPLETE) {
    return 'Completed';
  } else if (status === TaskStatus.PROCESSING) {
    return 'In Progress';
  } else {
    return 'Open';
  }
};

export const getCrbTaskStatusText = (status: TaskStatus) => {
  if (status === TaskStatus.COMPLETE) {
    return 'Completed';
  } else if (status === TaskStatus.PROCESSING) {
    return 'In Progress';
  } else {
    return 'New';
  }
};

export const getTaskStatusColor = (status: TaskStatus) => {
  if (status === TaskStatus.COMPLETE) {
    return theme.palette.primary.main;
  } else if (status === TaskStatus.PROCESSING) {
    return theme.palette.secondary.main;
  } else {
    return theme.palette.text.primary;
  }
};

export const getDueDateColor = (dueDate: string, timezone: IANATimezones) => {
  const today = DateTime.now().startOf('day');
  const dueDateString = DateTimeHelpers.parseFromISOString(dueDate, timezone).startOf('day');
  const overdueDays = Math.floor(today.diff(dueDateString, 'days').toObject().days ?? 0);
  return -2 <= overdueDays ? theme.palette.error.main : theme.palette.text.primary;
};

export const removeUuid = (text: string) => {
  return text.replace(/\([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\)/g, '');
};

/**
 * Purposefully not using any timezone such that we return the datetime localized to the user
 */
export const getModifiedDateText = (date: string): string => {
  const datetime = DateTime.fromISO(date);
  const startOfToday = DateTime.now().startOf('day');
  const startOfYesterday = DateTime.now().minus({ day: 1 }).startOf('day');

  if (datetime > startOfToday) {
    return `Today at ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else if (datetime > startOfYesterday) {
    return `Yesterday at ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  } else {
    return `${datetime.toLocaleString(DateTime.DATE_MED)}`;
  }
};
