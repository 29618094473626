import { CoreTransactionPaymentType } from '@gcv/shared';
import { SelectOption } from '../../ui';

export const CoreTransactionPaymentTypeOptions: { [key in CoreTransactionPaymentType]: string } = {
  [CoreTransactionPaymentType.ACH]: 'ACH',
  [CoreTransactionPaymentType.ACHWIRE]: 'ACH Wire',
  [CoreTransactionPaymentType.Adjustment]: 'Adjustment',
  [CoreTransactionPaymentType.BillPayments]: 'Bill Payments',
  [CoreTransactionPaymentType.Card]: 'Card',
  [CoreTransactionPaymentType.CardCredit]: 'Card Credit',
  [CoreTransactionPaymentType.CardDebit]: 'Card Debit',
  [CoreTransactionPaymentType.Cash]: 'Cash',
  [CoreTransactionPaymentType.Check]: 'Check',
  [CoreTransactionPaymentType.Crypto]: 'Crypto',
  [CoreTransactionPaymentType.Dividend]: 'Dividend',
  [CoreTransactionPaymentType.Fee]: 'Fee',
  [CoreTransactionPaymentType.InterestDividend]: 'Interest Dividend',
  [CoreTransactionPaymentType.Interest]: 'Interest',
  [CoreTransactionPaymentType.MonetaryInstrument]: 'Monetary Instrument',
  [CoreTransactionPaymentType.Payments]: 'Payments',
  [CoreTransactionPaymentType.P2P]: 'P2P',
  [CoreTransactionPaymentType.Physical]: 'Physical',
  [CoreTransactionPaymentType.ReturnedItems]: 'Returned Items',
  [CoreTransactionPaymentType.NSF]: 'NSF',
  [CoreTransactionPaymentType.RTP]: 'RTP',
  [CoreTransactionPaymentType.Tax]: 'Tax',
  [CoreTransactionPaymentType.Transfer]: 'Transfer',
  [CoreTransactionPaymentType.Wire]: 'Wire',
  [CoreTransactionPaymentType.Digital]: 'Digital',
  [CoreTransactionPaymentType.LoanAdvance]: 'Loan Advance',
  [CoreTransactionPaymentType.Disbursement]: 'Disbursement',
  [CoreTransactionPaymentType.PaymentReceived]: 'Payment Received',
  [CoreTransactionPaymentType.Escrow]: 'Escrow',
  [CoreTransactionPaymentType.Payoff]: 'Payoff',
  [CoreTransactionPaymentType.Other]: 'Other',
  [CoreTransactionPaymentType.SharedBranch]: 'Shared Branch'
};

export const CoreTransactionPaymentTypeSelectOptions: SelectOption[] = Object.entries(
  CoreTransactionPaymentTypeOptions
)
  .sort((a, b) => (a[1] > b[1] ? 1 : -1))
  .map(([value, label]) => {
    return { label, value };
  });
