//TODO: As we go along, the model-actions implementation should be migrated to the presenter-repo pattern.

import {
  CrbServiceProvider,
  CreateDocumentsRequest,
  Dispensary,
  DocumentUpload,
  DueDiligenceDocument,
  OnboardingDocumentRequirement,
  User
} from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { CompanyProfile } from './company-profile.model';
import { DispensariesApi, DocumentsApi, TemplateApi } from 'api';
import { SnackbarStore } from 'stores/SnackBarStore';
import { CrbBankStore } from 'stores/CrbBankStore';

export interface PM {
  isLoading: boolean;
  dispensary: Dispensary | null;
  dispensaryStaff: User[];
  allRootDocuments: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
  allDocuments: DocumentUpload[];
  serviceProviders: {
    [bankId: string]: CrbServiceProvider;
  };
  providerDocMap: {
    [docId: string]: string[];
  };
}

@injectable()
export class CompanyProfileRepo {
  documentsApi = new DocumentsApi();
  @inject(TemplateApi) private templateApi: TemplateApi;
  @inject(SnackbarStore) private snackbarStore: SnackbarStore;

  public programmersModel: PM = {
    isLoading: true,
    dispensary: null,
    dispensaryStaff: [],
    allRootDocuments: [],
    allDocuments: [],
    serviceProviders: {},
    providerDocMap: {}
  };

  @inject(CrbDispensaryStore)
  private dispStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  private bankStore: CrbBankStore;

  @inject(DispensariesApi)
  private dispensariesApi: DispensariesApi;

  private updateProgrammersModel = action((programmersModel: Partial<PM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  constructor() {
    makeAutoObservable(this);
  }

  public load = async () => {
    this.updateProgrammersModel({
      dispensary: this.dispStore.currentDispensary,
      dispensaryStaff: this.dispStore.currentDispensaryStaff,
      serviceProviders: this.bankStore.banks
    });
    await this.getDocuments();

    this.updateProgrammersModel({ isLoading: false });
  };

  public getServiceProviderDocs = action(async (sp: CrbServiceProvider) => {
    const spTemplateResult = await this.templateApi.getCrbTemplateResult(
      this.dispStore.currentDispensary.id,
      sp.templates.value.onboarding.template_id,
      sp.templates.value.onboarding.template_result_id
    );
    return spTemplateResult;
  });

  public getDocuments = async () => {
    const allRootDocs = await this.dispensariesApi.getAllRootDocuments(this.dispStore.currentDispensary.id);
    const documents = await this.documentsApi.getDocuments(this.dispStore.currentDispensary.id);
    this.updateProgrammersModel({
      allDocuments: documents,
      allRootDocuments: allRootDocs
    });
  };

  public updateDispensaryConfig = action(async (data: CompanyProfile) => {
    await this.dispensariesApi.updateDispensaryConfig(this.dispStore.currentDispensary.id, {
      allow_edit_profile: data.allow_edit_profile
    });
  });

  public updateCompanyProfile = action(async (data: CompanyProfile) => {
    const disp = await this.dispensariesApi.updateDispensaryBusinessDetails(
      this.dispStore.currentDispensary.id,
      data
    );

    await this.dispStore.updateDispensary(disp);
    this.updateProgrammersModel({ dispensary: disp });
  });

  shareExistingDocument = action(
    async (requirementId: string, documents: CreateDocumentsRequest, templateIds: string[]) => {
      try {
        const docsAdded = await Promise.all(
          templateIds.map(async (templateId) => {
            return this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
              this.dispStore.currentDispensary.id,
              templateId,
              requirementId,
              documents
            );
          })
        );
      } catch (error) {
        this.snackbarStore.showErrorSnackbarMessage(
          'There was an issue uploading license. Contact support for additional help.'
        );
      }
    }
  );

  addDocToOnboarding = action(
    async (
      requirementId: string,
      documents: CreateDocumentsRequest,
      templateIds: string[],
      linkedDocId?: string
    ) => {
      try {
        const rootDoc = await this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
          this.dispStore.currentDispensary.id,
          templateIds[0],
          requirementId,
          documents
        );
        const mjLicenseReq = this.programmersModel.allRootDocuments.find(
          (doc) => doc.requirement.requirement_id === requirementId
        );
        const newAllDocuments = await this.dispensariesApi.getAllRootDocuments(
          this.dispStore.currentDispensary.id
        );
        const newmjLicenseReq = newAllDocuments.find(
          (doc) => doc.requirement.requirement_id === requirementId
        );
        const newmjDocIds = newmjLicenseReq?.documents.map((doc) => doc.id);
        const existingDocIds = mjLicenseReq?.documents.map((doc) => doc.id);
        const newId = (newmjDocIds as string[]).filter((docId) => !existingDocIds?.includes(docId));

        // In order to prevent adding the document multiple times we need to add the linked_doc_id,
        // in order to get the id to construct that we need to get the new document id by doing a diff
        // between the existing document ids in the model and the new doc ids in the new all docs call
        // this is because we don't have a way of determining the new document id from the root doc id
        // that reponse contains multiple documents and multiple documents with new ids
        const docsAdded = await Promise.all(
          templateIds.map(async (templateId, index) => {
            if (index === 0) return;
            delete documents.license_data;
            documents.documents = documents.documents.map((doc) => {
              return {
                ...doc,
                linked_doc_id: linkedDocId ? linkedDocId : `${rootDoc.crb_id}_${newId[0]}`,
                shared: true
              };
            });
            return this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
              this.dispStore.currentDispensary.id,
              templateId,
              requirementId,
              documents
            );
          })
        );
      } catch (error) {
        this.snackbarStore.showErrorSnackbarMessage(
          'There was an issue uploading license. Contact support for additional help.'
        );
      }
    }
  );
}
