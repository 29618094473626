export const productCategoryFriendlyNameMap: { [category: string]: string } = {
  flower: 'Flower',
  'oil/concentrate': 'Oil/Concentrate',
  edible: 'Edible',
  other: 'Other',
  seedling: 'Seedling',
  seed: 'Seed',
  liquid: 'Liquid',
  solid: 'Solid',
  extract: 'Extract',
  plant: 'Plant',
  accessory: 'Accessory',
  marijuana_product: 'Marijuana Product',
  topical: 'Topical',
  edible_liquid: 'Edible Liquid'
};
