import { action } from 'mobx';

import { model, Model } from './model';
export class Actions {
  constructor(private model: Model) {}

  // Note: This is used to maintain the custom time range label. There's a circular interaction with
  // the AppViewStateStore where we use the store as the default value for the TimePeriodDropdown,
  // but then update the store anytime it changes, which updates the default value, and re-renders the dropdown,
  // which resets the label for the Custom time period option. Consider decoupling/consolidating this logic
  setCustomTimeRangeOptionLabel = action((label: string) => {
    this.model.customTimeRangeOptionLabel = label;
  });
}

export const actions = new Actions(model);
