import { CommentResponse, IANATimezones, RootComment } from '@gcv/shared';
import { Menu, MenuItem, Popover } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/images/ic-check.svg';
import { ReactComponent as MoreVert } from 'assets/images/more-vert-icon.svg';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { UserStore } from 'stores/UserStore';
import { Dialog, InputTextArea } from 'ui';
import { Avatar, Button, Container, ContainerItem, Divider, Header, Label, Text } from 'ui/atoms';
import { theme } from 'ui/theme';
import palette from 'ui/theme/palette';
import { DateTimeHelpers } from 'util/dateTime.util';
import { CommentsPresenter } from '../comments.presenter';
import { CommentContentBody } from './comment-content-body';
import { CommentsModalTaskArchive } from './comments-modal-task-archive';

const POPOVER_CLASS_NAME = 'archive-unarchive-popover';

interface Props {
  bankId: string;
  comment: RootComment;
  iana_timezone: IANATimezones;
  orgId: string;
  archived?: boolean;
  isChild?: boolean;
}

export const CommentContentContainer: React.FC<Props> = (props) => {
  const presenter = useInjection(CommentsPresenter);
  const userStore = useInjection(UserStore);

  const form = useForm();

  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const [viewMoreReplies, setViewMoreReplies] = React.useState(false);
  const [menuEl, setMenuEl] = React.useState<Element>();
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = React.useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);

  const handleMoreClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const names = props.comment.poster ? props.comment.poster.split(' ') : [];
  const firstName = names[0];
  const lastName = names[1];
  const posterInitials = firstName && lastName ? firstName[0] + lastName[0] : '';

  return (
    <>
      <Container width="100%" padding={props.isChild ? '8px 0px' : undefined}>
        <ContainerItem width="40px" padding="0" align="center" justify="center" height="100%" flex>
          <Avatar content={posterInitials} size="medium"></Avatar>
        </ContainerItem>
        <ContainerItem padding="0 0 0 8px" grow>
          <div style={{ display: 'flex' }}>
            <Header content={props.comment.poster} type="h4" />
            {!props.isChild && !props.comment.internal ? (
              <div
                style={{
                  marginLeft: '.5rem',
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  lineHeight: '14px',
                  fontSize: '10px',
                  backgroundColor: 'rgba(198, 204, 218, 0.5)',
                  borderRadius: '50px',
                  padding: '2px 7px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                External
              </div>
            ) : null}
          </div>
          <Label
            content={DateTimeHelpers.formatISOToDateAtTimeString(
              props.comment.date_posted,
              props.iana_timezone
            )}
            type="subtitle2"
            sx={{ paddingTop: '2px' }}
          />
        </ContainerItem>
        {!props.isChild && presenter.isNotSupportUser() ? (
          <>
            <ContainerItem width="50px" justify="right" align="center" flex padding="0px" height="100%">
              <MoreVert style={{ marginRight: '-10px', cursor: 'pointer' }} onClick={handleMoreClick} />
            </ContainerItem>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleMoreClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              style={{ zIndex: 10011, borderRadius: '20px' }}
              PaperProps={{
                sx: {
                  borderRadius: '6px',
                  boxShadow: '0px 2px 10px rgba(5, 30, 39, 0.2)'
                }
              }}
              className={POPOVER_CLASS_NAME}
            >
              <Container padding="2px 10px">
                <ContainerItem padding="0px" width="150px">
                  <Button
                    className={POPOVER_CLASS_NAME}
                    label={props.archived ? 'Restore Comment' : 'Archive Comment'}
                    color="default-text"
                    onClick={() => {
                      handleMoreClose();

                      if (!props.archived && props.comment.hasOwnProperty('task') && props.comment.task_id) {
                        setArchiveModalOpen(true);
                      } else {
                        presenter.archiveOrUnarchiveComment(!!props.archived, props.comment.id);
                      }
                    }}
                    variant="text"
                    type="submit"
                    isLoading={isLoading}
                  ></Button>
                </ContainerItem>
              </Container>
            </Popover>
          </>
        ) : null}
        <div
          style={{
            marginLeft: '19px',
            borderLeft: `1px solid ${props.comment?.children?.length ? theme.palette.divider : 'white'}`,
            paddingLeft: '28px',
            marginTop: '14px',
            marginBottom: '10px',
            width: '100%'
          }}
        >
          <Container width="100%" padding="0">
            <ContainerItem width="100%" padding="0" margin="0">
              <CommentContentBody comment={props.comment} />
            </ContainerItem>
            {!props.isChild ? (
              <>
                {!props.archived ? (
                  <>
                    <ContainerItem width="30%" justify="left" padding="0" margin="0 0 4px -16px">
                      <Button
                        dataCy="reply-button"
                        color="default-text"
                        label="Reply"
                        onClick={() => {
                          setViewMoreReplies(true);
                          presenter.setReply(props.comment);
                          presenter.setCurrentCommentTask((props.comment as CommentResponse).task);
                        }}
                      />
                    </ContainerItem>

                    {props.comment.hasOwnProperty('task') && props.comment.task_id && (
                      <>
                        {(props.comment as CommentResponse).task?.completed_on ? (
                          <ContainerItem width="70%" justify="right" padding="0" margin="10px 0 4px 0">
                            <Text
                              content={'Task Marked as Complete'}
                              startIcon={<CheckIcon style={{ marginBottom: '-8px' }} />}
                              type="body3"
                              sx={{ color: palette.text.secondary, fontWeight: 700 }}
                            />
                          </ContainerItem>
                        ) : (
                          <ContainerItem width="70%" justify="right" padding="0" margin="2px 0 4px 0">
                            <Button
                              dataCy="task-assigned-button"
                              color="default-text"
                              endIcon="expand_more"
                              label="Task assigned"
                              onClick={(event) => {
                                setMenuEl(event.currentTarget);
                              }}
                              style={{ paddingRight: 0, marginRight: '-19px' }}
                            />

                            <Menu
                              id="multi-action-button"
                              anchorEl={menuEl}
                              open={Boolean(menuEl)}
                              onClose={() => setMenuEl(undefined)}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                            >
                              <MenuItem
                                disabled={
                                  !presenter.viewModel.isBankAdmin &&
                                  !(props.comment as CommentResponse)?.task?.assigned_user_ids?.includes(
                                    userStore.user.id
                                  ) &&
                                  props.comment.posted_by !== userStore.user.id
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setMenuEl(undefined);
                                  setMarkAsCompleteModalOpen(true);
                                  presenter.setReply(null);
                                }}
                              >
                                Mark Task as Complete
                              </MenuItem>
                            </Menu>
                          </ContainerItem>
                        )}
                      </>
                    )}
                  </>
                ) : null}
                {props.comment?.children?.length ? (
                  <>
                    <ContainerItem
                      width="100%"
                      padding="0"
                      margin={`${props.archived ? '0' : '-8px'} 0 0 0`}
                      flex
                      align="center"
                    >
                      <div style={{ width: '50px' }}>
                        <Divider />
                      </div>
                      <Button
                        color="default-text"
                        label={
                          viewMoreReplies
                            ? `Hide ${props.comment?.children?.length} replies`
                            : `View ${props.comment?.children?.length} replies`
                        }
                        onClick={() => setViewMoreReplies(!viewMoreReplies)}
                      />
                    </ContainerItem>
                    {viewMoreReplies ? (
                      <ContainerItem width="100%" padding="0">
                        {props.comment?.children?.map((child) => (
                          <CommentContentContainer
                            key={child.id}
                            isChild
                            comment={child as RootComment}
                            iana_timezone={props.iana_timezone}
                            orgId={props.orgId}
                            bankId={props.bankId}
                          />
                        ))}
                      </ContainerItem>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </Container>
        </div>
      </Container>

      {archiveModalOpen && (
        <CommentsModalTaskArchive
          archived={props.archived}
          comment={props.comment}
          isOpen={archiveModalOpen}
          onClose={() => setArchiveModalOpen(false)}
        />
      )}

      {markAsCompleteModalOpen && (
        <Dialog
          handleClose={() => setMarkAsCompleteModalOpen(false)}
          isOpen={markAsCompleteModalOpen}
          title={'Mark as Complete'}
          action={
            <Button
              color="primary"
              label="Mark as Complete"
              onClick={form.handleSubmit(async (data: FieldValues) => {
                await presenter.markTaskAsComplete(
                  props.bankId,
                  props.comment.task_id ?? '',
                  data.completed_note
                );
                await presenter.getPost(props.bankId);
                setMarkAsCompleteModalOpen(false);
              })}
            />
          }
        >
          <Container padding="0" width="100%">
            <ContainerItem padding="0" width="100%">
              <InputTextArea
                {...form}
                label={'Add a Note'}
                name={'completed_note'}
                placeholder="Add a Note (optional)"
                background="lightGray"
              />
            </ContainerItem>
          </Container>
        </Dialog>
      )}
    </>
  );
};
