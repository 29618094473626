import { MainFeatures, OrganizationRoleResolver } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { FiBankStore } from 'stores/FiBankStore';
import { UserStore } from 'stores/UserStore';
import { orgFeatureEnabled } from 'util/org.util';

export interface VM {
  activeLink: string;
  dataOpen: boolean;
  userCanViewFincen: boolean;
  userCanViewMonitoring: boolean;
  userCanViewData: boolean;
  userCanViewUsers: boolean;
  isBankExaminer: boolean;
  userCanViewDeposits: boolean;
  isLoading: boolean;
}

@injectable()
export class FiNavDrawerPresenter {
  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(UserStore)
  private userStore: UserStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    activeLink: 'dashboard',
    dataOpen: false,
    userCanViewFincen: false,
    userCanViewMonitoring: false,
    userCanViewData: false,
    userCanViewUsers: false,
    isBankExaminer: false,
    userCanViewDeposits: false,
    isLoading: true
  };

  load = () => {
    const resolver = new OrganizationRoleResolver();
    const isFincenFeatureEnabled: boolean =
      orgFeatureEnabled(this.bankStore.bank, MainFeatures.FinCEN, 'CTR') ||
      orgFeatureEnabled(this.bankStore.bank, MainFeatures.FinCEN, 'SAR');

    this.viewModel.userCanViewFincen =
      isFincenFeatureEnabled &&
      resolver.userCanDoAction(this.bankStore.bank.groups, this.userStore.user, 'fincen_report_view');
    this.viewModel.userCanViewMonitoring = resolver.userCanDoAction(
      this.bankStore.bank.groups,
      this.userStore.user,
      'account_review_view'
    );
    this.viewModel.userCanViewData = resolver.userCanDoAction(
      this.bankStore.bank.groups,
      this.userStore.user,
      'data_view'
    );
    this.viewModel.userCanViewUsers = resolver.userCanDoAction(
      this.bankStore.bank.groups,
      this.userStore.user,
      'user_add'
    );
    this.viewModel.isBankExaminer = resolver.userHasRole(
      this.bankStore.bank.groups,
      this.userStore.user,
      'bank_examiner'
    );
    this.viewModel.userCanViewDeposits = orgFeatureEnabled(this.bankStore.bank, MainFeatures.Deposits);
    this.viewModel.isLoading = false;
  };

  setActiveLink = (link: string) => {
    if (link !== 'daily-summaries' && link !== 'data') {
      this.viewModel.dataOpen = false;
    } else {
      this.viewModel.dataOpen = true;
    }
    this.viewModel.activeLink = link;
  };

  setActiveLinkFromPath = (path: string) => {
    if (path.indexOf('/secure/fi/dashboard') > -1) {
      this.setActiveLink('dashboard');
    } else if (path.indexOf('/secure/fi/data/daily-summaries') > -1) {
      this.setActiveLink('daily-summaries');
    } else if (path.indexOf('/secure/fi/data') > -1) {
      this.setActiveLink('data');
    } else if (path.indexOf('/secure/fi/deposits') > -1) {
      this.setActiveLink('deposits');
    } else if (path.indexOf('/secure/fi/accounts') > -1) {
      this.setActiveLink('accounts');
    } else if (path.indexOf('/secure/fi/fincen') > -1) {
      this.setActiveLink('fincen');
    } else if (path.indexOf('/secure/fi/reports') > -1) {
      this.setActiveLink('reports');
    } else if (path.indexOf('/secure/fi/monitoring') > -1) {
      this.setActiveLink('monitoring');
    } else if (path.indexOf('/secure/fi/tasks') > -1) {
      this.setActiveLink('tasks');
    } else if (path.indexOf('/secure/fi/settings') > -1) {
      this.setActiveLink('settings');
    } else if (path.indexOf('/secure/fi/users') > -1) {
      this.setActiveLink('users');
    }
  };

  toggleDataOpen = () => {
    this.viewModel.dataOpen = !this.viewModel.dataOpen;
  };
}
