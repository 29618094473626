import { debounce, useMediaQuery, useTheme } from '@mui/material';
import { useComponent } from 'hooks/useComponent';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Container, ContainerItem, OnBoardingPage } from 'ui';
import { getCommentStore } from '../../../../../../stores/CommentStore';
import { ContactInfoForm } from './components/contact-info';
import { IdentificationForm } from './components/identification-form';
import { PersonalDetailsForm } from './components/personal-details-form';
import { UserHomeAddressForm } from './components/user-home-address-form';
import { IdVerificationPresenter } from './id-verification-presenter';

interface Props extends Record<string, unknown> {
  onBack?: () => void;
  onNext?: () => void;
  isLastStep?: boolean;
  stepNumber?: number;
  totalSteps?: number;
}

export const IdVerification: React.FC<Props> = useComponent((props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { bank_id } = useParams() as { bank_id: string };
  const presenter = useInjection(IdVerificationPresenter);
  const commentStore = getCommentStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    presenter.load(bank_id);
  }, []);

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: presenter.userStore.user.firstName,
      minitial: presenter.userStore.user.minitial,
      lastName: presenter.userStore.user.lastName,
      email: presenter.userStore.user.email,
      dateOfBirth: presenter.userStore.user.dateOfBirth,
      phone: presenter.userStore.user.phone,
      address: presenter.userStore.user.address,
      city: presenter.userStore.user.city,
      state: presenter.userStore.user.state,
      zipcode: presenter.userStore.user.zipcode,
      ssn: presenter.userStore.user.ssn,
      idType: presenter.userStore.user.identification?.idType,
      idNumber: presenter.userStore.user.identification?.idNumber,
      idState: presenter.userStore.user.identification?.idState,
      hasUploadedId: !!presenter.viewModel.idFrontDocument && !!presenter.viewModel.idBackDocument
    } as FieldValues
  });

  const uploadedIdCallback = (isUploaded: boolean) => {
    form.setValue('hasUploadedId', isUploaded);
    form.trigger('hasUploadedId');
  };

  const onChangeSave = React.useCallback(
    debounce(() => {
      presenter.autoSaveUserIdentification(form.getValues());
    }, 2000),
    []
  );

  const afterSubmit = props.onNext ? props.onNext : () => navigate('dashboard');

  return (
    <OnBoardingPage
      alignBottomActions="center"
      dataCy="id-verification-page"
      title="ID Verification"
      subtitle={
        props.totalSteps
          ? `${presenter.viewModel.serviceProviderName} requires this information to comply with Know Your Customer (KYC) requirements.`
          : 'Please add personal details to verify your identity. Financial institutions need this information to comply with their Know Your Customer (KYC) requirements.'
      }
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container justify="flex-end">
          {props.onBack ? (
            <ContainerItem>
              <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
            </ContainerItem>
          ) : null}
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : props.totalSteps ? 'Continue' : 'Save & Submit'}
              color="primary"
              onClick={form.handleSubmit((data) => presenter.submitUserIdentification(data, afterSubmit))}
              isLoading={
                presenter.viewModel.isSavingIdVerification ||
                (!props.totalSteps && presenter.viewModel.isAutoSaving)
              }
              dataCy="save-button"
            />
          </ContainerItem>
        </Container>
      }
    >
      <Container column padding={0}>
        <ContainerItem padding="0" width="100%">
          <PersonalDetailsForm
            isMobile={isMobile}
            form={form}
            onChange={onChangeSave}
            onBlur={onChangeSave}
          />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <UserHomeAddressForm isMobile={isMobile} form={form} onChange={onChangeSave} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <ContactInfoForm isMobile={isMobile} form={form} onChange={onChangeSave} />
        </ContainerItem>

        <ContainerItem padding="0" width="100%">
          <IdentificationForm
            isMobile={isMobile}
            form={form}
            uploadedIdCallback={uploadedIdCallback}
            onChange={onChangeSave}
          />
        </ContainerItem>
      </Container>
    </OnBoardingPage>
  );
});

export default IdVerification;
