import { Box, Drawer as MuiDrawer } from '@mui/material';
import * as React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { Container, ContainerItem, Divider, Header, Text, Tooltip } from 'ui/atoms';
import { NAV_DRAWER_WIDTH } from 'domain/consts/ui';

interface Props {
  open: boolean;
  onClose: () => void;
  anchor?: 'left' | 'right';
  width?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  action?: React.ReactNode;
  bottomAction?: React.ReactNode;
  bottomActionMargin?: string;
  bottomActionPadding?: string;
  dataCy?: string;
  defaultPadding?: boolean;
  hideBackdrop?: boolean;
  id?: string;
  permanent?: boolean;
  subtitle?: string;
  title?: string;
  children?: React.ReactNode;
}

const widthMap = {
  xsmall: NAV_DRAWER_WIDTH,
  small: { xs: '100%', sm: '384px' },
  medium: { xs: '100%', sm: '482px' },
  large: { xs: '100%', sm: '600px' },
  xlarge: { xs: '100%', md: '754px' } // sm breakpoint is 600px, so need to use md breakpoint of 1024px for xlarge so that the drawer doesn't overflow.
};

export const Drawer: React.FC<Props> = ({
  open,
  onClose,
  anchor = 'right',
  width = 'medium',
  action,
  bottomAction,
  bottomActionMargin,
  dataCy,
  defaultPadding,
  hideBackdrop,
  id,
  permanent,
  subtitle,
  title,
  children
}) => {
  const drawerPadding = defaultPadding ? '24px' : '0';
  const titleCutOff = width && Number.parseInt(width) > 600 ? 60 : 27;
  const variant = permanent ? 'permanent' : 'temporary';
  const drawerWidth = widthMap[width];

  return (
    <MuiDrawer
      ModalProps={{ disableEnforceFocus: true }} // this prevents stealing focus from inputs, ie when the chat widget is opened over a drawer
      anchor={anchor}
      data-cy={dataCy}
      hideBackdrop={hideBackdrop}
      id={id}
      onClose={onClose}
      open={open}
      sx={{
        flexShrink: 0,
        width: drawerWidth,

        '& .MuiDrawer-paper': {
          borderLeft: (theme) => (anchor === 'right' ? `1px solid ${theme.palette.grey[400]}` : 'none'),
          borderRight: (theme) => (anchor === 'left' ? `1px solid ${theme.palette.grey[400]}` : 'none'),
          height: '100%',
          overflowX: 'hidden',
          padding: drawerPadding,
          paddingBottom: bottomAction ? '0' : '100px',
          width: drawerWidth
        }
      }}
      variant={variant}
    >
      {anchor === 'right' && (
        <Container align="center" padding="0" width="100%">
          <Container width="100%" padding="16px">
            <ContainerItem width="70%" grow padding="0">
              {title && (
                <Tooltip title={title} disabled={title.length < titleCutOff + 1}>
                  <Header dataCy="drawer-title" type="h2" content={title} textOverflow="ellipsis" />
                </Tooltip>
              )}
              {subtitle && (
                <Tooltip title={subtitle} disabled={subtitle.length < 35}>
                  <Text
                    dataCy="drawer-subtitle"
                    content={
                      subtitle ? (subtitle.length > 34 ? subtitle.substring(0, 33) + '...' : subtitle) : ''
                    }
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: '16px',
                      pt: '4px',
                      width: '90%'
                    }}
                    textOverflow="ellipsis"
                  />
                </Tooltip>
              )}
            </ContainerItem>

            {action && <ContainerItem padding="0">{action}</ContainerItem>}

            <ContainerItem flex padding="0">
              <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </ContainerItem>
          </Container>

          <ContainerItem flex margin={'0 -24px'} padding="0" width="calc(100% + 48px)">
            <Divider />
          </ContainerItem>
        </Container>
      )}

      {bottomAction ? (
        <Container padding={0} width="100%">
          <Box
            sx={{
              height: bottomActionMargin ? 'calc(100vh - 185px)' : 'calc(100vh - 144px)',
              overflow: 'auto',
              width: drawerWidth
            }}
          >
            <ContainerItem padding={0} width="100%">
              {children}
            </ContainerItem>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              bottom: 0,
              justifyContent: 'center',
              margin: bottomActionMargin || '0',
              position: 'fixed',
              width: drawerWidth
            }}
          >
            <ContainerItem textAlign="center" padding="0" width="100%">
              <Box
                sx={{
                  padding: '0.5rem 0',
                  width: '100%'
                }}
              >
                <Divider />
              </Box>

              {bottomAction}
            </ContainerItem>
          </Box>
        </Container>
      ) : (
        <Container padding={0} width="100%">
          <ContainerItem padding={0} width="100%">
            {children}
          </ContainerItem>
        </Container>
      )}
    </MuiDrawer>
  );
};
