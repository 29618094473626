import { GCApplicationAccess } from 'api/api-util/api-auth0-session';
import { environment } from 'environments/environment';
import qs from 'query-string';

/*
 * Because Auth0 is hosted elsewhere, our app unloads and reloads every time the
 * Auth0 functionality is used. This poses no real problem for a straight-forward
 * login, but we have some edge cases that need handling. So, if we need to save
 * data across these reloading cycles, where do we? Auth0 has a mechanism for this,
 * but it is both limited and awkward. The simplest solution is to use the browser's
 * session storage. That is what is going on here.
 */

// ---------------------------------------------
// Store user's browser path at browser refresh
// ---------------------------------------------

/**
 * Used to return to correct page on browser refresh when running under Auth0.
 */
const redirectUrl = 'GCVRedirectUrl';

/**
 * Set value of path in storage.
 *
 * @param path user's path at the time of a browser refresh.
 */
export const setRedirectUrl = (path: string) => {
  window.sessionStorage.setItem(redirectUrl, path);
};

/**
 * Delete path stored prior to browser refresh.
 */
export const deleteRedirectUrl = () => {
  window.sessionStorage.removeItem(redirectUrl);
};

/**
 * Return path stored prior to browser refresh.
 *
 * @param deleteValue deletes stored value if true; otherwise, value is retained.
 * @returns the stored path value.
 */
export const getRedirectUrl = (deleteValue = false) => {
  const path = window.sessionStorage.getItem(redirectUrl);

  if (deleteValue) {
    deleteRedirectUrl();
  }

  return path;
};

// ------------------------------
// Store redirect path for login
// ------------------------------

const uriRedirectKey = 'GCVUriRedirectCookieKey';
const uriReturnToKey = 'GCVUriReturnToCookieKey';

/**
 * Return facade class to session cache for (PayQwick) redirect URI.
 */
class LoginRedirectURI {
  private getCachedRedirectURI() {
    return window.sessionStorage.getItem(uriRedirectKey);
  }

  private getCachedReturnToURI() {
    return window.sessionStorage.getItem(uriReturnToKey);
  }

  clearCache() {
    return window.sessionStorage.removeItem(uriRedirectKey);
  }

  cacheRedirectURI(uri?: string) {
    if (!uri) {
      this.clearCache();
    } else {
      window.sessionStorage.setItem(uriRedirectKey, uri);
    }
  }

  cacheReturnToURI(uri?: string) {
    if (!uri) {
      this.clearCache();
    } else {
      window.sessionStorage.setItem(uriReturnToKey, uri);
    }
  }

  getPayQwickRedirectURI(app: GCApplicationAccess): URL {
    const defaultUri =
      app === GCApplicationAccess.PQ
        ? environment.payqwickDefaultRedirectUri
        : environment.gcdDefaultRedirectUri;
    const urlString = this.getCachedRedirectURI() ?? defaultUri;
    console.log('cached uri', this.getCachedRedirectURI());
    // angular webpages all have /#/ in the path, but new URL assumes this is part of the hash
    // we need to parse it out manually as I haven't found any nice solution for this
    if (urlString?.includes('#/')) {
      console.log('original', urlString);
      const parts = urlString.split('#/');
      const url = new URL(urlString);

      // stomp out the hash, because what new URL parses is incorrect
      url.hash = '';

      // need to manually parse out the path and query string values
      const pathParts = parts[1].split('?');
      const queryStringParams = qs.parse(pathParts[1]);

      // manually append the parsed query string to our URL
      for (const [key, val] of Object.entries(queryStringParams)) {
        url.searchParams.append(key, val as string);
      }

      // manually set the path name
      url.pathname = '/#/' + pathParts[0];
      console.log('after', url.toString());
      return url;
    } else {
      return new URL(urlString);
    }
  }

  getPayQwickReturnToURI(): URL | undefined {
    const urlString = this.getCachedReturnToURI();
    // angular webpages all have /#/ in the path, but new URL assumes this is part of the hash
    // we need to parse it out manually as I haven't found any nice solution for this
    if (urlString?.includes('#/')) {
      const parts = urlString.split('#/');
      const url = new URL(urlString);

      // stomp out the hash, because what new URL parses is incorrect
      url.hash = '';

      // need to manually parse out the path and query string values
      const pathParts = parts[1].split('?');
      const queryStringParams = qs.parse(pathParts[1]);

      // manually append the parsed query string to our URL
      for (const [key, val] of Object.entries(queryStringParams)) {
        url.searchParams.append(key, val as string);
      }

      // manually set the path name
      url.pathname = '/#/' + pathParts[0];
      console.log('after', url.toString());
      return url;
    }

    return undefined;
  }
}

/**
 * Return instance of loging redirect cache object.
 *
 * @returns object managing the login redirect cache.
 */
export const getRedirectURICache = (): LoginRedirectURI => new LoginRedirectURI();

// ------------------------------
// Store username used for login
// ------------------------------

const usernameLoginKey = 'GCVUsernameLoginCookieKey';

/**
 * Set username in storage.
 *
 * @param username user email used for logging in.
 */
export const setStoredUsername = (username: string) => {
  window.sessionStorage.setItem(usernameLoginKey, username);
};

/**
 * Delete username from storage.
 */
export const deleteStoredUsername = () => {
  window.sessionStorage.removeItem(usernameLoginKey);
};

/**
 * Return stored username.
 *
 * @param deleteValue deletes stored value if true; otherwise, value is retained.
 * @returns the stored username.
 */
export const getStoredUsername = (deleteValue = false) => {
  const username = window.sessionStorage.getItem(usernameLoginKey);

  if (deleteValue) {
    deleteStoredUsername();
  }

  return username;
};
