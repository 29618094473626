const palette = {
  primary: {
    contrastText: '#FFFFFF',
    dark: '#03904F',
    main: '#03A65B',
    light: '#D8F4E7',
    screen: '#03A65B12',
    border: '#03A65B'
  },
  secondary: {
    contrastText: '#FFFFFF',
    dark: '#0457D2',
    main: '#1270FC',
    light: '#6896FB'
  },

  error: {
    contrastText: '#FFFFFF',
    dark: '#EC2F31',
    main: '#FF4E50',
    light: '#FE989A'
  },
  warning: {
    contrastText: '#FFFFFF',
    dark: '#DC8C08',
    main: '#F5A623',
    light: '#FFD48C'
  },
  info: {
    contrastText: '#FFFFFF',
    dark: '#266DD7',
    main: '#3989FF',
    light: '#98C5FF',
    notice: '#98c5ff66'
  },
  success: {
    contrastText: '#FFFFFF',
    dark: '#5F9422',
    main: '#93C954',
    light: '#CCEDA6'
  },
  text: {
    primary: '#3A3C45',
    secondary: '#757783',
    disabled: '#C6CCDA'
  },
  link: '#1270FC',
  icon: '#757783',
  background: {
    default: '#F9FAFD',
    paper: '#FFFFFF',
    secondary: '#F2F4FA',
    disabled: '#828599'
  },
  divider: '#C6CCDA',
  border: '#dbe0eb'
};

export default palette;
