import { useRollbarPerson } from '@rollbar/react';
import { useComponent } from 'hooks';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { updateLogRocketConfig } from 'third-party-integrations/log-rocket';
import { Spinner } from 'ui';
import { getUserType, userCanDoAction, userHasRole } from 'ui/routing/router-util';
import { getUserStore } from '../../stores/UserStore';

export const CrbRoute: React.FC<{ path: string }> = useComponent(({ path }) => {
  let isDispensaryUser = false;
  let userHasRoles = false;
  let userHasAction = false;

  const dispensaryStore = getCrbDispensaryStore();
  const userStore = getUserStore();

  //check user type
  if (userStore.isLoaded) {
    const type = getUserType(userStore.user);

    if (type === 'dispensary') {
      isDispensaryUser = true;
    }

    useRollbarPerson({
      id: userStore.user?.id,
      name: userStore.user?.firstName + ' ' + userStore?.user?.lastName,
      email: userStore.user?.email
    });
    updateLogRocketConfig(userStore.user);
  }

  //check user role and action
  if (userStore.isLoaded && dispensaryStore.isLoaded && dispensaryStore.currentDispensary.groups) {
    userHasRoles = userHasRole(dispensaryStore.currentDispensary.groups, userStore.user, path);
    userHasAction = userCanDoAction(dispensaryStore.currentDispensary.groups, userStore.user, path);
  }

  return userStore.user.marketing_user_in_progress || (isDispensaryUser && userHasRoles && userHasAction) ? (
    <Outlet />
  ) : !userStore.isLoaded || !dispensaryStore.isLoaded || !dispensaryStore.currentDispensary.groups ? (
    <Spinner />
  ) : !userHasRoles ? (
    <Navigate to={{ pathname: '/error' }} />
  ) : (
    <Navigate to={{ pathname: '/' }} />
  );
});
