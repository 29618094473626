import {
  BankQuestionnaireMetadataResponse,
  BankTemplates,
  CustomRequirement,
  CustomRequirementResult,
  CustomSection,
  QuestionnaireResponse,
  QuestionnaireResultResponse,
  QuestionnaireStatus,
  QuestionnaireUsageResponse,
  TemplateResponse,
  VerboseCrbQuestionnaireUsageResponse,
  VerboseCrbTemplateQuestionnaireResponse,
  VerboseQuestionnaireResultResponse,
  VerboseQuestionnaireUsageResponse
} from '@gcv/shared';
import { injectable } from 'inversify';
import { FieldValues } from 'react-hook-form';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { api, banksApi } from './api-util/api';

interface QuestionnaireCustomFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

@injectable()
export class QuestionnairesApi {
  async addNewQuestionnaire(bankId: string, data: FieldValues): Promise<QuestionnaireResponse> {
    return await banksApi().post(`/banks/${bankId}/questionnaire`, data);
  }

  async addQuestionnaireSection(
    bankId: string,
    questionnaireId: string,
    data: CustomSection
  ): Promise<CustomRequirement> {
    const payload = { ...data } as { id?: string };

    if (payload.id !== undefined && payload.id === '') {
      // Front-end typing relies on this property, but the back-end doesn't want an empty string.
      delete payload.id;
    }

    return await banksApi().post(`/banks/${bankId}/questionnaire/${questionnaireId}/custom-fields`, payload);
  }

  async getQuestionnaire(
    bankId: string,
    questionnaireId: string,
    version = 'latest'
  ): Promise<QuestionnaireResponse> {
    // There was a bug in the backend for awhile that allowed version 0 to be assigned
    // this should never happen because version 0 is a draft
    // This check is for older template responses that may have version 0
    // Throwing an error because this should never happen and we want to fail fast if it does occur
    if (version.toString() === '0') {
      getSnackbarStore().showErrorSnackbarMessage(
        'There is an issue with the version of this Questionnaire. Please contact support.'
      );

      throw new Error('Invalid version number');
    }

    return await banksApi().get(`/banks/${bankId}/questionnaire/${questionnaireId}?version=${version}`);
  }

  async publishQuestionnaire(bankId: string, questionnaireId: string): Promise<QuestionnaireResponse> {
    return await banksApi().put(`/banks/${bankId}/onboarding-template/${questionnaireId}/publish`, {});
  }

  async updateQuestionnaire(
    bankId: string,
    questionnaireId: string,
    data: FieldValues
  ): Promise<BankTemplates> {
    return await banksApi().put(`/banks/${bankId}/questionnaire/${questionnaireId}`, data);
  }

  async editQuestionnaireSection(
    bankId: string,
    questionnaireId: string,
    section: CustomSection
  ): Promise<QuestionnaireResponse> {
    if (!('label' in section && 'order' in section && 'fields' in section)) {
      console.log(
        `ERROR: Custom section missing values in required properties (order, label, or field): ${JSON.stringify(
          section
        )}`
      );

      // The back-end requires these fields, or may return 400. Also, enforcing type requires these
      // fields. If you're seeing this, it means a phantom bug that occurred (intermittently, and
      // only on my machine) during development has now reared its ugly head and should be fixed.
      //
      // Once we're convinced this isn't an issue, we can remove this check. - mdiana 2022.04.05
      throw new Error('Questionnaire custom section object invalid.');
    }

    return await banksApi().put(
      `/banks/${bankId}/questionnaire/${questionnaireId}/requirement/${section.id}/custom-fields`,
      section
    );
  }

  async reorderSections(
    bankId: string,
    questionnaireId: string,
    sectionIds: string[]
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${questionnaireId}/custom-fields/reorder`,
      {
        requirement_ids: sectionIds
      }
    );
  }

  async archiveQuestionnaireSections(
    bankId: string,
    questionnaireId: string,
    sections: CustomSection[]
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/questionnaire/${questionnaireId}/archive-custom-requirements`,
      {
        requirementIds: sections.map((s) => s.id)
      }
    );
  }

  async getQuestionnairesForBank(bankId: string): Promise<BankQuestionnaireMetadataResponse> {
    return await banksApi().get(`/banks/${bankId}/questionnaires`);
  }

  async getQuestionnairesForCrb(crbId: string): Promise<VerboseQuestionnaireResultResponse[]> {
    const uri = `/crb/${crbId}/questionnaires/results`;
    return await api().get(uri);
  }

  async getCRBsUsingQuestionnaire(
    bankId: string,
    questionnaireId: string
  ): Promise<QuestionnaireUsageResponse> {
    return await banksApi().get(`/banks/${bankId}/questionnaire/${questionnaireId}/crbs`);
  }

  async bulkAssignQuestionnaireToCrb(
    bankId: string,
    questionnaireId: string,
    data: { startDate: string; crbIds: string[]; timeToRespond: string; reoccurring: boolean }
  ): Promise<QuestionnaireUsageResponse> {
    return await banksApi().post(`/banks/${bankId}/questionnaire/${questionnaireId}/assign`, data);
  }

  async bulkUnassignQuestionnaireToCrb(
    bankId: string,
    questionnaireId: string,
    data: { crbIds: string[] }
  ): Promise<QuestionnaireUsageResponse> {
    return await banksApi().post(
      `/banks/${bankId}/questionnaire/${questionnaireId}/unassign?permanent=false`, // soft delete
      data
    );
  }

  async updateQuestionnaireDistributionStatus(
    bankId: string,
    questionnaireId: string,
    data: { distribute: boolean; startDate?: string }
  ): Promise<BankTemplates> {
    return await banksApi().put(`/banks/${bankId}/questionnaire/${questionnaireId}/distribution`, data);
  }

  async getCRBQuestionnaires(
    bankId: string,
    crbId: string
  ): Promise<VerboseCrbTemplateQuestionnaireResponse> {
    return await banksApi().get(`/banks/${bankId}/crb/${crbId}/questionnaires`, {
      includeDisabled: true
    });
  }

  async sendQuestionnaireToCRBOnDate(
    bankId: string,
    templateId: string,
    crbId: string,
    sendDate: Date,
    note: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const body: any = {
      startDate: sendDate.toISOString(),
      reoccurring: false
    };
    if (note !== '') {
      body.note = note;
    }

    return await banksApi().post(`/banks/${bankId}/questionnaire/${templateId}/crb/${crbId}`, body);
  }

  async bulkAssignBankQuestionnairesForCRB(bankId: string, questionnaireIds: string[], crbId: string) {
    const payload = {
      templateIds: questionnaireIds,
      startDate: new Date().toISOString(),
      reoccuring: true
    };

    return await banksApi().post(`/banks/${bankId}/crb/${crbId}/assign-questionnaires`, payload);
  }

  async bulkUnassignBankQuestionnairesForCRB(bankId: string, questionnaireIds: string[], crbId: string) {
    const payload = {
      templateIds: questionnaireIds
    };

    return await banksApi().post(`/banks/${bankId}/crb/${crbId}/unassign-questionnaires`, payload);
  }

  async updateQuestionnaireRequirementResult(
    crbId: string,
    bankId: string,
    templateId: string,
    resultsId: string,
    requirementId: string,
    customFields: QuestionnaireCustomFields
  ): Promise<CustomRequirementResult> {
    const uri = `/crb/${crbId}/fi/${bankId}/questionnaire/${templateId}/results/${resultsId}/requirement/${requirementId}/custom-fields`;
    return await api().put(uri, customFields);
  }

  async completeQuestionnaireResultForCRB(
    templateId: string,
    questionnaireId: string,
    crbId: string,
    bankId: string
  ): Promise<QuestionnaireResultResponse> {
    const uri = `/crb/${crbId}/fi/${bankId}/questionnaire/${templateId}/results/${questionnaireId}/custom-fields/complete`;
    return await api().put(uri, {});
  }

  async markQuestionnaireAsReadyToComplete(
    ready: boolean,
    questionnaireId: string,
    questionniareResultId: string,
    crbId: string,
    bankId: string
  ): Promise<QuestionnaireResultResponse> {
    const uri = `/crb/${crbId}/fi/${bankId}/questionnaire/${questionnaireId}/results/${questionniareResultId}/ready-to-complete`;
    const status = ready ? QuestionnaireStatus.ReadyToComplete : QuestionnaireStatus.InProgress;
    const payload = { status: status };
    return await api().put(uri, payload);
  }

  async getCRBQuestionnaireResponse(
    crbId: string,
    questionnaireId: string,
    questionnaireResultId: string
  ): Promise<QuestionnaireResultResponse> {
    return await api().get(
      `/crb/${crbId}/questionnaires/${questionnaireId}/results/${questionnaireResultId}`
    );
  }

  async getQuestionnaireOverview(
    bankId: string,
    startDate: string,
    endDate: string
  ): Promise<VerboseQuestionnaireUsageResponse[]> {
    return await banksApi().get(
      `/banks/${bankId}/questionnaire-overview?startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getQuestionnaireOverviewByCrb(
    bankId: string,
    crbId: string,
    startDate: string,
    endDate?: string
  ): Promise<VerboseCrbQuestionnaireUsageResponse[]> {
    let uri = `/banks/${bankId}/crb/${crbId}/questionnaire-overview?startDate=${startDate}`;
    if (endDate) {
      uri = `${uri}&endDate=${endDate}`;
    }
    return await banksApi().get(uri);
  }

  async deleteDistributedQuestionnaire(bankId: string, dispensary_id: string, template_result_id: string) {
    const uri = `/banks/${bankId}/dispensaries/${dispensary_id}/questionnaire-results/${template_result_id}`;
    return await banksApi().delete(uri);
  }

  async deleteBulkDistributedQuestionnaires(
    bankId: string,
    templateId: string,
    sentOn: string,
    dueDate: string,
    reoccuring: boolean
  ) {
    const uri = `/banks/${bankId}/questionnaire/${templateId}/distribution?sent_on=${sentOn}&due_date=${dueDate}&reoccurring=${reoccuring}`;
    return await banksApi().delete(uri);
  }
}
