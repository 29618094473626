import { DocumentUpload, UserIdentification } from '@gcv/shared';
import { DispensariesApi, DocumentsApi, UsersApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from '../../../../../../stores/UserStore';
import { DateTimeHelpers } from '../../../../../../util/dateTime.util';
import { uploadUserDocumentToS3 } from '../../../../../../util/s3.util';
import { OnboardingRepo } from '../../onboarding.repo';

export interface IdVerificationProgrammersModel {
  idFront: DocumentUpload | undefined;
  idBack: DocumentUpload | undefined;
}

@injectable()
export class IdVerificationRepo {
  @inject(OnboardingRepo)
  public onboardingRepo: OnboardingRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(DispensariesApi)
  public dispensariesApi: DispensariesApi;

  @inject(UsersApi)
  public usersApi: UsersApi;

  @inject(UserStore)
  public userStore: UserStore;

  @inject(DocumentsApi)
  public documentsApi: DocumentsApi;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: IdVerificationProgrammersModel = {
    idFront: undefined,
    idBack: undefined
  };

  updateProgrammersModel = action((programmersModel: Partial<IdVerificationProgrammersModel>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async () => {
    if (!this.onboardingRepo.programmersModel.idFront) {
      const docs = await this.onboardingRepo.getUserDocs();
      this.updateProgrammersModel({
        idFront: docs.idFront,
        idBack: docs.idBack
      });
    } else {
      this.updateProgrammersModel({
        idFront: this.onboardingRepo.programmersModel.idFront,
        idBack: this.onboardingRepo.programmersModel.idBack
      });
    }
  });

  updateUserIdentification = async (data: FieldValues, onNext: () => void) => {
    try {
      const timezone = this.dispensaryStore.currentDispensary.iana_timezone;
      const user = this.userStore.user;

      const updateRequest = {
        firstName: data.firstName,
        minitial: data.minitial,
        lastName: data.lastName,
        dateOfBirth: DateTimeHelpers.formatISOToDateString(data.dateOfBirth, timezone),
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        ssn: data.ssn,
        identification: {
          ...user.identification,
          idType: data.idType,
          idNumber: data.idNumber,
          idState: data.idState
        }
      };

      if (user) {
        const returnedUser = await this.usersApi.updateUserIdentification(
          this.userStore.user.id,
          updateRequest
        );
        this.userStore.updateUser(returnedUser);
        onNext();
        this.snackbarStore.showSnackbar(
          SnackbarSeverity.Success,
          'User identification has been updated successfully.'
        );
        return returnedUser;
      }
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue updating user identification.'
      );
    }
  };

  autoSaveUserIdentification = async (data: FieldValues) => {
    this.onboardingRepo.updateProgrammersModel({ isAutoSaving: true });
    const timezone = this.dispensaryStore.currentDispensary.iana_timezone;
    const user = this.userStore.user;

    const updateRequest = {
      firstName: data.firstName,
      minitial: data.minitial,
      lastName: data.lastName,
      dateOfBirth: DateTimeHelpers.formatISOToDateString(data.dateOfBirth, timezone),
      phone: data.phone,
      address: data.address,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      ssn: data.ssn,
      identification: {
        ...user.identification,
        idType: data.idType,
        idNumber: data.idNumber,
        idState: data.idState
      }
    };
    const returnedUser = await this.usersApi.updateUserIdentification(user.id, updateRequest);
    this.userStore.updateUser(returnedUser);
    this.onboardingRepo.updateProgrammersModel({ isAutoSaving: false });
  };

  uploadNewId = async (file: File) => {
    const s3Key = await uploadUserDocumentToS3(file, this.userStore.user.id);
    return await this.documentsApi.putUserDocument(file.name, s3Key, this.userStore.user.id);
  };

  updateIdentityDocs = action(async (newIdFront: File | undefined, newIdBack: File | undefined) => {
    try {
      const user = this.userStore.user;
      const updatedIdentification = { ...user.identification };
      let frontDoc: DocumentUpload | undefined = undefined;
      let backDoc: DocumentUpload | undefined = undefined;
      if (newIdFront) {
        frontDoc = await this.uploadNewId(newIdFront);
        updatedIdentification.idFront = {
          filename: frontDoc.file_name,
          document_id: frontDoc.id,
          status: 'review'
        };
        this.updateProgrammersModel({ idFront: frontDoc });
      }
      if (newIdBack) {
        backDoc = await this.uploadNewId(newIdBack);
        updatedIdentification.idBack = {
          filename: backDoc.file_name,
          document_id: backDoc.id,
          status: 'review'
        };
        this.updateProgrammersModel({ idBack: backDoc });
      }

      const updatedUser = await this.usersApi.updateUserIdentification(user.id, {
        identification: updatedIdentification
      });
      this.userStore.updateUser(updatedUser);
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'Documents have been uploaded successfully.');
      return { frontDoc, backDoc, updatedUser };
    } catch (e) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue uploading documents.');
    }
  });

  deleteIdDoc = action(
    async (updatedIdentification: {
      idType?: string | undefined;
      idNumber?: string | undefined;
      idState?: string | undefined;
      idFront?: UserIdentification | undefined;
      idBack?: UserIdentification | undefined;
    }) => {
      try {
        const returnedUser = await this.usersApi.updateUserIdentification(this.userStore.user.id, {
          identification: updatedIdentification
        });
        this.userStore.updateUser(returnedUser);
        return returnedUser;
      } catch (e) {
        this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'There was an issue removing the documents.');
      }
    }
  );
}
