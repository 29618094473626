import { IANATimezones } from '@gcv/shared';
import { ClickAwayListener } from '@mui/material';
import { Box } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { TimePeriod } from 'stores/AppViewStateStore';
import {
  InputSearch,
  FilterHeader,
  FilterList,
  FilterFooter,
  InputDate,
  Text,
  Button,
  Container,
  InputLabel
} from 'ui';

export interface FilterListItem {
  label: string;
  value: string;
  children: FilterListChild[];
  disabled?: boolean;
  selected?: boolean;
}

export interface FilterListChild {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  parentValue?: string;
  visible?: boolean;
  archivedOption?: boolean;
  selectValue?: TimePeriod;
  sublabel?: string;
}

interface Props {
  filterHeader: string;
  filterList: FilterListItem[];
  dateFilter?: string;
  includeSearch?: boolean;
  includeDate?: boolean;
  dateTimezone?: IANATimezones;
  dateHelperText?: string;
  dateTitle?: string;
  onApplyFilter: (
    selected: FilterListChild[],
    filterList: FilterListItem[],
    date?: string | undefined
  ) => void;
  onCancel: () => void;
  right?: boolean;
}

export const FilterDropdown: React.FC<Props> = (props) => {
  const getSelectedItemsFromProps = () => {
    const selectedList: FilterListChild[] = [];
    props.filterList.forEach((filterListItem: FilterListItem) => {
      if (!filterListItem.children.length && filterListItem.selected) {
        selectedList.push(filterListItem);
      } else {
        filterListItem.children.forEach((filterListChild: FilterListChild) => {
          if (filterListChild.selected) {
            selectedList.push(filterListChild);
          }
        });
      }
    });

    // remove any duplicates based on the value property
    const uniqueSelectedList = Array.from(new Map(selectedList.map((item) => [item.value, item])).values());

    return uniqueSelectedList;
  };

  const [selectedList, setSelectedList] = React.useState<FilterListChild[]>(getSelectedItemsFromProps());
  const [localFilterList, setLocalFilterList] = React.useState(props.filterList);
  const [parentSelectAllChanged, setParentSelectAllChanged] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [date, setDate] = React.useState<string | undefined>(undefined);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const isSelected = (item: FilterListChild) => {
    return selectedList.some(
      (selectedItem) => selectedItem.value === item.value && selectedItem.parentValue === item.parentValue
    );
  };

  const searchTermChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
    clear?: boolean
  ) => {
    let searchTerm = '';
    if (!clear && e !== null) {
      searchTerm = e.target.value;
    }
    if (searchTerm.length) {
      setSearchTerm(searchTerm);

      const tempList = props.filterList
        .filter(
          (item) =>
            item.children.length > 0 || item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        )
        .map((listSection) => {
          const newListSection = JSON.parse(JSON.stringify(listSection));

          if (newListSection.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
            newListSection.children = listSection.children?.map((subListItem) => {
              subListItem.visible = true;

              if (isSelected(subListItem)) {
                subListItem.selected = true;
              }
              return subListItem;
            });

            if (isSelected(newListSection)) {
              newListSection.selected = true;
            }

            return newListSection;
          } else {
            newListSection.children = listSection.children?.map((subListItem) => {
              if (subListItem.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
                subListItem.visible = true;
              } else {
                subListItem.visible = false;
              }
              // when the filter changes we want to make sure that no matter the item visibility the local
              // filter list has the correct selection state
              if (isSelected(subListItem)) {
                subListItem.selected = true;
              }
              return subListItem;
            });

            if (isSelected(newListSection)) {
              newListSection.selected = true;
            }

            return newListSection;
          }
        })
        .filter((a) => {
          return (
            (a.children.length > 0 && a.children.some((c: any) => c.visible)) ||
            a.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
          );
        });
      setLocalFilterList(tempList);
    } else {
      const tempList = props.filterList.map((listSection) => {
        const newListSection = JSON.parse(JSON.stringify(listSection));
        newListSection.children = listSection.children?.map((subListItem) => {
          subListItem.visible = true;

          if (isSelected(subListItem)) {
            subListItem.selected = true;
          }
          return subListItem;
        });

        if (isSelected(newListSection)) {
          newListSection.selected = true;
        }
        return newListSection;
      });
      setLocalFilterList(tempList);
    }
  };

  const selectAll = () => {
    const selectedList: FilterListChild[] = [];
    localFilterList.forEach((filterListItem: FilterListItem) => {
      filterListItem.children.forEach((filterListChild: FilterListChild) => {
        if (filterListChild.visible !== false) {
          filterListChild.selected = true;
          filterListChild.parentValue = filterListItem.value;
          selectedList.push(filterListChild);
        }
      });

      if (filterListItem.children.length === 0) {
        filterListItem.selected = true;
        selectedList.push(filterListItem);
      }
    });
    setSelectedList(selectedList);
    setLocalFilterList(localFilterList);
    setParentSelectAllChanged(parentSelectAllChanged + 1);
  };

  const clearFilter = () => {
    setSelectedList([]);
    localFilterList.forEach((item) => {
      item.selected = false;
    });
    props.filterList.forEach((item) => {
      item.selected = false;
    });
    setDate(undefined);
  };

  const listItemSelected = (listItem: FilterListChild) => {
    if (
      selectedList.find((item) => {
        return item.value === listItem.value;
      })
    ) {
      clearSubItems([listItem]);
    } else {
      const newSelectedList = [...selectedList, listItem];
      setSelectedList(newSelectedList);
    }
  };

  const clearSubItems = (subListItems: FilterListChild[]) => {
    const newSelectedList = selectedList.filter((selectedListItem) => {
      return !subListItems.find((subListItem) => subListItem.value === selectedListItem.value);
    });
    setSelectedList(newSelectedList);
  };

  const selectSubItems = (subListItems: FilterListChild[], parentValue: string) => {
    let newSelectedList: FilterListChild[] = [...selectedList].filter(
      (item) => item.parentValue !== parentValue && item.value !== parentValue
    );
    const parentListItem = localFilterList.find((item) => item.value === parentValue);

    if (parentListItem && parentListItem?.children.length === 0) {
      if (parentListItem.selected) {
        parentListItem.selected = false;
        newSelectedList = newSelectedList.filter((item) => item.value !== parentValue);
      } else {
        parentListItem.selected = true;
        newSelectedList.push(parentListItem);
      }
    } else {
      subListItems.forEach((filterListChild: FilterListChild) => {
        if (filterListChild.visible !== false) {
          filterListChild.parentValue = parentValue;
          filterListChild.selected = true;
          newSelectedList.push(filterListChild);
        }
      });
    }
    setSelectedList(newSelectedList);
    setLocalFilterList(localFilterList);
  };

  const onCancel = () => {
    searchTermChanged(null, true);
    props.onCancel();
  };

  const onSave = () => {
    searchTermChanged(null, true);
    // When we have a combined list and the parent items are selected the localFilterList
    // is the only place the selection changes, since that list is incomplete if there is
    // a search term and you can save when a search term is currently applied the props.filterlist is not
    // updated. Here we are manually checking the props.filterList which we pass back to the parent against
    // the flat selected list to make sure things that might not be selected there (but are at the local level)
    // are getting selected. Note this is not an issue when the filter is removed prior to applying and the children are modified in place
    // but since the source of truth is the selectedList it is a reliable source for selection check

    props.filterList.forEach((listItem) => {
      listItem.selected = !!isSelected(listItem);
    });
    props.onApplyFilter(selectedList, props.filterList, date);
  };

  const form = useForm({
    date: props.dateFilter || ''
  } as FieldValues);

  React.useEffect(() => {
    if (props.includeDate) {
      form.register('date', {
        onChange: (e) => {
          setDate(e.target.value);
        }
      });
    }
  }, [props.includeDate]);

  React.useEffect(() => {
    if (props.dateFilter) {
      setDate(props.dateFilter);
      form.setValue('date', props.dateFilter);
    }
  }, [props.dateFilter]);

  if (!props.filterList.length) {
    return (
      <ClickAwayListener onClickAway={onCancel} mouseEvent={'onMouseUp'}>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              width: '150px',
              height: '35px',
              padding: '5px',
              marginTop: '8px',
              background: '#fff',
              left: isMobile ? (!props.right ? '0' : 'unset') : 'unset',
              right: isMobile ? (!props.right ? 'unset' : '0') : '0',
              zIndex: '1000',
              boxShadow:
                '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            No Filters Available
          </Box>
        </Box>
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={onCancel} mouseEvent={'onMouseUp'}>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            width: '350px',
            background: '#fff',
            left: isMobile ? (!props.right ? '0' : 'unset') : 'unset',
            right: isMobile ? (!props.right ? 'unset' : '0') : '0',
            marginTop: '8px',
            zIndex: '1000',
            boxShadow:
              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FilterHeader
            label={props.filterHeader}
            selectedItems={selectedList}
            onClearFilter={clearFilter}
            onSelectAll={selectAll}
          />
          {props.includeSearch && (
            <Box
              sx={{
                padding: '12px 20px',
                overflowY: 'auto',
                borderBottom: '1px solid',
                borderColor: 'divider'
              }}
            >
              <InputSearch label={'Search'} onChange={searchTermChanged} />
            </Box>
          )}
          <Box
            sx={{
              overflowY: 'auto',
              overflowX: 'clip'
            }}
          >
            {props.includeDate && (
              <Box
                sx={{
                  mt: '1rem',
                  padding: '0 1rem'
                }}
              >
                <Container
                  width="100%"
                  padding={0}
                  align="center"
                  justify="space-between"
                  margin={'0 0 0.5rem 0'}
                >
                  <InputLabel label={props.dateTitle || '--'} name="date" />
                  {date && (
                    <Button
                      color="primary-text"
                      label="Clear"
                      onClick={() => {
                        form.setValue('date', null);
                        setDate(undefined);
                      }}
                      style={{ padding: '0', minWidth: '0', height: '0' }}
                    />
                  )}
                </Container>
                <InputDate
                  label={''}
                  name="date"
                  timezone={props.dateTimezone || IANATimezones.America_Adak}
                  {...form}
                />
                <Text
                  type="body3"
                  content={props.dateHelperText}
                  sx={{
                    mt: '4px',
                    color: (theme) => theme.palette.text.secondary
                  }}
                />
              </Box>
            )}
            <FilterList
              filterList={localFilterList}
              onChange={listItemSelected}
              clearSubItems={clearSubItems}
              selectedList={selectedList}
              selectAllState={parentSelectAllChanged}
              searchTerm={searchTerm}
              selectSubItems={selectSubItems}
              timezone={props.dateTimezone || IANATimezones.America_Adak}
              includeDate={props.includeDate}
            />
          </Box>
          <FilterFooter onCancel={onCancel} onSave={onSave} />
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
