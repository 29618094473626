export const HomeToolTips = {
  '1': `1. Filing Name. Enter a descriptive
name to identify the filing.`,
  '1a': `1a. Check this box if this is an Initial report.`,
  '1b': `1b.
Check this box if this report amends
(adds missing data)
or corrects errors in the prior report.`,
  '1c': `1c.
Check this box if this is a
FinCEN directed Backfiling.`,
  '1d': `1d.
Prior report BSA Identifier.
If "Correct/Amend prior report"
is selected as the filing type,
enter the BSA Identifier (BSA ID)
assigned to the previous CTR by FinCEN.
Enter all zeros if the prior report
BSA ID is unknown. `
};

export const PartOneToolTips = {
  '52': `52. Identify the type of financial institution
recorded in Item 44.  If "Other" is selected,
enter a brief description of the financial
institution in the associated
"Other (specify)" text field.`,
  '43': `43. Identify the financial institution’s
primary federal regulator or BSA
examiner. If more than one
regulator option could apply,
select the regulator that has
primary responsibility for
enforcing compliance with the BSA.
Option "D" for Internal Revenue
Service must be entered if the
institution is subject to US law
and none of the other codes apply.`,
  '53': `53. If "Casino/Card club"
is recorded in Item 52,
identify the type of gaming
institution.  If "Other"
is selected, record a
brief description of the
gaming institution in the
associated text field.`,
  '44': `44. Enter the legal name of
the financial institution
as recorded on articles of
incorporation or other documents
establishing the institution.
If an individual’s name is
recorded, enter the name in
(first name) (middle name)
(last name) or equivalent format,
e.g. John Jacob Doe or Richard R.
Roe II, etc.  An individual’s name
might be recorded when, for example,
the financial institution is a sole
proprietorship. Do not enter a
trade or DBA name in this field.`,
  '45': `45. If the financial institution
has a separate trade name or
doing business as (DBA) name,
enter that name here.  Do not
enter a legal name in this field.
Do not include acronyms such as
DBA or AKA with the alternate name.`,
  '46': `46. Enter the filing institution’s Employer
Identification Number (EIN).
If the filer is a sole proprietorship
operating under the sole proprietor’s
Taxpayer Identification Number (TIN)
instead of an EIN, enter that TIN here.
Enter the EIN or TIN as a single text
string without hyphens. The EIN in the
format NN-NNNNNNN or the TIN in the format
NNN-NN-NNNN would be entered as NNNNNNNNN.`,
  '47': `47. Enter the financial institution's permanent
street address, city, U.S. State or Territory
code, ZIP Code, and U.S. Country or Territory
code. If the address is located within a U.S.
Territory, select the territory from the Country
drop-down list, at which time the State field will
be auto-populated with the same code. An apartment
number or suite number (if known) must follow the
street address.  A non-location address such as a
post office box or rural route number should be used
only if the street address information is not available.
ZIP Codes must be five or nine digits.  ZIP Codes must be
entered without formatting or special characters such as
spaces or hyphens.  For example, the ZIP Code 12354-6120
would be entered as 123546120. No abbreviations are permitted
in city names, which must be completely spelled out. A U.S.
city name should match the city name used by the U.S. Postal
Service for the associated state or U.S. Territory and ZIP Code.`,
  '54': `54. Record the filing institution’s identification type and number.
Enter the number as a single text string without formatting or
special characters such as hyphens, periods, or spaces.
An identifying number in the format NNN-NN-NNNN would be entered
as NNNNNNNNN.  ID numbers may include both letter and number characters.`,
  '55': `55. Enter the title of the administrative office that should be
 contacted to obtain additional information about the report.  This
 office title must be associated with the contact telephone number recorded
 in Item 56 "Phone number." Do not enter data such as the financial
 institution legal name or branch name, financial institution addresses,
 generic names such as "Main Office" or "Home Office," or the names of employees.`,
  '56': `56. Enter the single telephone number associated with the contact office
recorded in Item 55.  Enter then telephone extension associated with
the contact phone number.  Leave the extension field blank if there is
no extension.`
};

export const PartTwoToolTips = {
  '38': `38. Identify the type of financial institution
recorded in Item 30.  If "Other" is selected,
enter a brief description of the financial institution
in the associated "Other (specify)" text field.`,
  '29': `29. Identify the financial institution’s primary federal
regulator or BSA examiner.  If more than one regulator option could apply, select the regulator that has primary responsibility for enforcing compliance with the BSA.  Option "D" for Internal Revenue Service must be entered if the institution is subject to US law and none of the other codes apply.`,
  '39': `39. If "Casino/Card club" is recorded in Item 38,
identify the type of gaming institution.  If "Other"
is selected, record a brief description of the gaming
institution in the associated "Other" text field.`,
  '30': `30. Enter the legal name of the financial institution
as recorded on articles of incorporation or other
documents establishing the institution.  If an
individual’s name is recorded, enter the name in
(first name) (middle name) (last name) or equivalent format,
e.g. John Jacob Doe or Richard R. Roe II, etc.
An individual’s name might be recorded when, for example,
the financial institution is a sole proprietorship. Do not
enter a trade or DBA name in this field.`,
  '31': `31. If the financial institution has a separate trade
name or doing business as (DBA) name, enter that
name here.  Do not enter a legal name in this field.
Do not include acronyms such as DBA or AKA with the
alternate name.`,
  '32': `32. Enter the financial institution’s Employer
Identification Number (EIN).  If the financial
institution is a sole proprietorship operating under
the sole proprietor’s Taxpayer Identification Number
(TIN) instead of an EIN, enter that TIN here. Enter the
EIN or TIN as a single text string without hyphens. The
EIN in the format NN-NNNNNNN or the TIN in the format
NNN-NN-NNNN would be entered as NNNNNNNNN.`,
  '33': `33. Financial institution address items. Enter the
financial institution's permanent street address,
city, U.S. State or Territory code, ZIP Code, and
U.S. Country or Territory code. If the address is
located within a U.S. Territory, select the territory
from the Country drop-down list, at which time the
tate field will be auto-populated with the same code.
An apartment number or suite number (if known) must
follow the street address.  A non-location address such
as a post office box or rural route number should be
used only if the street address information is not
available. ZIP Codes must be five or nine digits.
ZIP Codes must be entered without formatting or special
characters such as spaces or hyphens.  For example,
the ZIP Code 12354-6120 would be entered as 123546120.
No abbreviations are permitted in city names, which must
be completely spelled out.  A U.S. city name should match
the city name used by the U.S. Postal Service for the associated
state or U.S. Territory and ZIP Code.`,
  '40': `40. Record the financial institution’s identification type and number.
Enter the number as a single text string without formatting or
special characters such as hyphens, periods, or spaces.
An identifying number in the format NNN-NN-NNNN would be entered
as NNNNNNNNN.  ID numbers may include both letter and number characters.`,
  '41': `41. Enter the cash in amount transacted at this location.
This amount must equal the item 25 total cash in amount
if a single transaction at one location is involved.
If multiple locations are involved the amount must be
less than the Item 25 amount. The multiple Part III Item
41 amounts must equal the Item 25 amount with rounding
differences permitted.  If no amount is entered in item
41 then Item 42 "Cash out amount for this location" must
have an amount.`,
  '42': `42. Enter the cash out amount transacted at this location.
This amount must equal the item 27 total cash out amount
if a single transaction at one location is involved.
If multiple locations are involved the amount must be
less than the Item 27 amount. The multiple Part III Item
42 amounts must equal the Item 27 amount with rounding
differences permitted.  If no amount is entered in item
42 then Item 41 "Cash in amount for this location" must
have an amount.`
};

export const PartThreeToolTips = {
  '1': `2. Select option 2a if the person recorded in Part I conducted the transaction(s) on his or her own behalf.
  Select option 2b if the person recorded in Part I conducted the transaction(s) on behalf of another person.
  Options 2a and 2b cannot be selected if box 4b‚ “If entity” is checked.
  Select option 2c if the transaction was conducted by another for the person recorded in Part I.
  If option 2d is selected because an armored car service under contract with the customer is involved in the transaction(s),
  the information on the armored car service, not the individual agent of that armored car service,
  will be recorded in Part I (see FIN-2013-R001). If box 2d is checked to indicate an armored car service under contract with the customer then box 4b,
  “If entity” must be checked. If more than one Item 2 option applies to a Part I person,
  a separate Part I section will be prepared on that person for each Item 2 option.
  For example, if the Part I person conducted a $5,000 deposit into their personal account and a separate $7,000 deposit into the account of another person/entity,
  there will be one Part I on that person reporting option 2a on the personal deposit with that amount and account number in Item 21 “Cash in amount”.
  There will be a second Part I on that person reporting option 2b on the person/entity account transaction with that amount and account number in Item 21.`,
  '2': `Select Yes if an entity is being recorded in Part I. Yes can only be selected when question 2 is answered “Person on whose behalf transaction was conducted” or “Common carrier.” If “Common carrier” is selected to indicate an armored car service under contract with the customer then “If entity” must be answered Yes. If “Common carrier” is selected to indicate any other type of private courier under contract with the customer, “If entity” must be answered No. Do not answer Yes if the person involved in the transaction is a sole proprietorship. See FinCEN Ruling FIN-2008-R001 for additional information on reporting currency transactions that involve sole proprietorships. As stated in FIN-2008-R001, FinCEN will continue to accept two Part I sections (previously “Section A” on FinCEN Form 104) on sole proprietorships containing both the personal and business information.`,
  '3': `3. Select Yes if multiple transactions of any type (cash in and/or cash out) were conducted in a single business day by or for the person recorded in Part I. “Multiple transactions” is not the same as the Item 24 option “Aggregated transactions,” which only involves multiple transactions all of which are below the reporting the reporting requirements.`,
  '4': `4. Individual's last name or entity's legal name:
Enter the individual's last name or the entity's
legal name. The entity legal name is the name on
the articles of incorporation or other document that
established the entity. If an entity is being recorded
in Part I, check box "If entity." If box 2d is checked
to indicate an armored car service under contract with
the customer then box "If entity" must be checked.  If
box 2d is checked to indicate any other type of private
common carrier under contract with the customer, box "If
entity" must not be checked. Do not check box "If entity"
if the person involved in the transaction is a sole proprietorship.
See FinCEN Ruling FIN-2008-R001 for additional information on
reporting currency transactions that involve sole proprietorships.
Do not check box "If entity" if an individual's name is entered in
Items 4-6. If the individual’s last name or entity’s legal name is
unknown leave Item 4 blank and check box 4 "Unknown." Do not record
also known as (AKA) or doing business as (DBA) names in Item 4. Record
these names instead in Item 8 "Alternate Name."`,
  '5': `5.
Enter the individual's first name.  Select "Unknown"
if the first name is unknown. Do not record also known
as (AKA) or doing business as (DBA) names in Item 5.`,
  '6': `6.
Enter the individual's middle name or middle initial.
Leave this item blank if the middle name or middle
initial is unknown or does not apply. Do not record
also known as (AKA) or doing business as (DBA) names
in Item 6.`,
  '7': `7. Select "Male" or "Female" if the individual’s gender
is known.  Select "Unknown" if the individual’s gender
is unknown. This item does not create an obligation
for a financial institution to collect this data when
such collection would be in conflict with the financial
institution’s obligations under any other federal law.
Leave Gender blank of the Part I person is an entity.`,
  '8': `8. Enter the individual’s name suffix such as JR,
SR, III, etc., if it is part of the individual's
legal name. Leave this item blank if the suffix
is unknown or does not apply.`,
  '9': `9. Enter the individual's also known as (AKA) name or the
entity's doing business as (DBA) name if different from
the name entered in Items 4-6.  Do not include the acronyms
AKA or DBA with the name.`,
  '10': `10. Record the occupation, profession, or type of business of the
individual or entity recorded in Part I.  Use specific descriptions
such as doctor, carpenter, attorney, used car dealership, plumber,
truck driver, hardware store, etc.  Do not use non-descriptive
items such as businessman, merchant, retailer, retired, or self-employed.
If words like self-employed, unemployed, or retired are used, add the
current or former profession if known (e.g. self-employed building contractor,
retired teacher, or unemployed carpenter).`,
  '9a': `9a. Select the North American Industry Classification System
(NAICS) code that most closely matches
the occupation entered in Item 9.`,
  '11': `11. Enter the person's permanent street address, city,
state/territory/province (U.S./Canada/Mexico only),
ZIP Code or foreign postal code, and country or U.S.
Territory. If the address is located within a U.S.
Territory, select the territory from the Country drop-down
list, at which time the State field will be auto-populated
with the same code. Complete any address item that is known,
even if the whole address is unknown.  If an address item is
unknown, leave that item blank and check "Unknown."  An
apartment number or suite number (if known) must follow the
street address.  A non-location address such as a post office
box or rural route number should be used only if the street
address information is not available. ZIP Codes must be five
or nine digits. ZIP Codes and foreign postal codes must be
entered without formatting or special characters such as spaces
or hyphens. For example, the ZIP Code 12354-6120 would be entered
as 123546120. The foreign postal code HKW  702 would be entered
HKW702. For other foreign addresses enter the street address,
city, postal code, and country or address equivalent. Leave the
state item blank (including the "Unknown" box for state). If a
foreign address contains address information that does not conform
to the  CTR address format, record equivalent address information
in the CTR address items (except state) and ignore non-conforming
data. Complete any address item that is known, even if the complete
address is unknown. No abbreviations are permitted in city names,
which must be completely spelled out. A U.S. city name should match
the city name used by the U.S. Postal Service for the associated state
and ZIP Code.`,
  '15': `15. Enter the person's U.S. or foreign taxpayer identification
number (TIN) without formatting or punctuation.  Select "Unknown"
if the number is unknown. Enter the TIN as a single text string
without formatting or special characters such as hyphens or periods.
An identifying number in the format NNN-NN-NNNN would be entered as
NNNNNNNNN. Foreign TINs may include letter and number characters.`,
  '17': `17. Enter the individual's date of birth in MM/DD/YYYY format where
MM = month, DD = day, and YYYY = year.  Any single digit month or
day must be preceded by a zero.  If the birth day and or month is
unknown, enter "00" for the unknown day or month.  For example, a
date of birth with an unknown day in February 1978 would be entered
as 02/00/1978. Do not enter zeros for the year if the year is unknown.  If the year of birth or the entire date of birth is unknown, check "Unknown."`,
  '18': `18. Enter the person's U.S. or foreign contact telephone
number and extension (if applicable). If the telephone
number or extension is unknown, leave blank.  Record
the telephone number as a single number string without
formatting or special characters such as parentheses,
spaces, or hyphens.  For example, a number in the format
(NNN) NNN-NNNN would be recorded as NNNNNNNNNN.  If known,
provide the telephone extension number in the associated field.
Telephone numbers that are part of the North American Numbering
Plan used by the U.S., Canada, many Caribbean countries, and
present/former U.S. Pacific island protectorates must consist
of an area code and seven-digit telephone number.  Other foreign
telephone numbers should include the country number code.  If
only a partial telephone number is known, enter the partial
number in the phone number field.`,
  '19': `19. Enter the person's E-mail address if known. Include all formatting,
punctuation, and special characters in the E-mail address. An
E-mail address must contain the "@" sign with a period in the
following text, e.g. johndoe@business.com or richardroephd@college.edu.
Leave Item 19 blank if the E-mail address is unknown.`,
  '20': `20. Enter in Item 20 the information used to identify the individual
or entity recorded in Item 4. Check "Other" if a different
identification than the ones listed was provided and describe
that identification in the "Other" text field. "Other" identification
could include such things as an entity’s business license or
incorporation documents, corporate ID cards, local government ID
cards, etc.  Enter the identification number if known.  Do not include
formatting such as spaces, hyphens, or periods in the number. Select
the appropriate country where the identification was issued. If the
identification issuer was a U.S. state, Mexican state, or Canadian
province or territory select the appropriate entry from the issuing
state list after selecting the appropriate country in the country list.
If the identification issuer is a U.S. Territory, select that territory
in the country list.  Enter all identification data that is available.
Select "Unknown" only if the individual or entity is known and all
identification information is unknown.  When entering the identification
number, enter as a single text string without formatting or special
characters such as hyphens or periods.  An identifying number in the
format NNN-NN-NNNN would be entered as NNNNNNNNN. `,
  '21': `21. Enter the total cash in amount denominated in U.S. Dollars for the
person recorded in Item 4.  This amount cannot be greater than the
amount in Item 25 "Total cash in."  Record the account number or
other unique account identifier for each account involved at the
financial institution where the transaction(s) occurred. Record all
U.S. Dollar amounts rounded up to the next whole dollar. The amount
$5,265.25 would be recorded as $5,266. A foreign currency amount can
only be recorded in Item 26 "Foreign cash in" or Item 28 "Foreign cash
out," again rounded up to the next whole amount. When converting a
foreign currency to dollars use an exchange rate for the date of the
transaction.`,
  '22': `22. Enter the total cash out amount denominated in U.S.
Dollars for the person recorded in Item 4. This amount
cannot be greater than the amount in Item 27 "Total cash
out."   Record the account number or other unique account
identifier for each account involved at the financial institution
where the transaction(s) occurred. The amount $5,265.25 would be
recorded as $5,266. A foreign currency amount can only be recorded
in Item 26 "Foreign cash in" or Item 28 "Foreign cash out," again
rounded up to the next whole amount. When converting a foreign currency
to dollars use an exchange rate for the date of the transaction.`,
  '16': `16. Identify the type of TIN recorded in Item 15. Select
option "EIN" if the person has a U.S. Employer Identification
Number (EIN). Select option "SSN-ITIN" if the person has a U.S.
Social Security Number (SSN) or Individual Taxpayer Identification
Number (ITIN). Select option "Foreign" if the person has a foreign
TIN of any type. Box 4b. Record a TIN type only if a TIN is entered
in Item 15. If a 9-digit TIN is entered in Item 15 but the TIN type is
unknown, select "EIN" if the person is an entity and "SSN-ITIN" if the
person is an individual. TINs that are not 9 digits or that contain alpha
characters are presumed to be foreign, so option "Foreign" would be selected.`
};

export const PartFourToolTips = {
  '24': `24. Indicate whether any of the following
delivery/payment methods were involved
in any part of the transaction(s). Check
all that apply.  Check "Armored car
(FI contract) if a reported transaction
involved a pick-up or delivery of currency
by an armored car service under contract
to the financial institution listed in Part
III (do not check this box if the armored car
service was under contract to a person recorded
in Part I).  Check "ATM" if a reported transaction
occurred at an automated teller machine (ATM).
Check "Mail Deposit or Shipment" if a reported
transaction was made by mail deposit or shipment.
Check "Night Deposit" if a reported transaction
involved a night deposit of cash.  Check "Aggregated
transactions" if the financial institution did not
identify transactor(s) because the CTR reports aggregated
transactions each of which was below the reporting
requirement. The option "Aggregated transactions" is not
the same as Item 3 "Multiple transactions," which can involve
transactions that are above the reporting requirement. Check
"Shared branching" if the transaction was conducted by or on
behalf of another financial institution that is a member of a
co-operative network (this option applies only to credit unions
that are members of a cooperative).`,
  '23': `23. Enter the date of the transaction(s) in MM/DD/CCYY format
where MM = month, DD = day, and YYYY = year.  Any single
digit month or day must be preceded by a zero.`,
  '25': `25. Record the cash in amount(s) involved in the transaction(s)
if the amount or aggregated amount is greater than $10,000.
Enter the amount(s) on whichever of lines 25a through 25i
best describe the transaction or aggregated transactions.
A total of the entered amount(s) will appear on the "Total
cash in" line. If any portion of the total amount is not
described by any of those options, record that portion on line
25z and provide a brief description of the transaction(s) in the
"Other" text field.  If the total amount of the cash in transaction
or aggregated transactions is $10,000 or less, do not record any
amounts in Item 25. &#xD;&#xD;Only cash transactions that, if alone
or when aggregated, exceed $10,000 should be reported on the CTR.
Transactions shall not be offset against one another.  If there are
both Cash In and Cash Out transactions that are reportable, the amounts
should be considered separately and not aggregated. However, they may be
reported on a single CTR.  If there is a currency exchange, it should
be aggregated separately with each of the cash in and cash out totals.`,
  '27': `27. Record the cash out amount(s) involved in the transaction(s) if
the amount or aggregated amount is greater than $10,000. Enter
the amount(s) on whichever of lines 27a through 27j best describe
the transaction or aggregated transactions. A total of the entered
amount(s) will appear on the "Total cash out" line. If any portion
of the total amount is not described by any of those options, record
that portion on line 27z and provide a brief description of the
transaction in the "Other" text field.  If the total amount of the
cash out transaction or aggregated transactions is $10,000 or less,
do not record any amounts in Item 27. Note: In the casino industry
"TITO" refers to ticket in/ticket out and "OTB" refers to off-track
betting. &#xD;&#xD;Only cash transactions that, if alone or when
aggregated, exceed $10,000 should be reported on the CTR.
Transactions shall not be offset against one another.  If there
are both Cash In and Cash Out transactions that are reportable,
the amounts should be considered separately and not aggregated.
However, they may be reported on a single CTR.  If there is a currency
exchange, it should be aggregated separately with each of the cash in
and cash out totals.`,
  '26': `26. If the cash in transaction(s) involved a foreign currency or
currencies, enter the total amount of each foreign currency and
select the country that issued the currency. Complete a separate
Item 26 for each foreign currency involved in the cash in transaction(s).
Identify each foreign currency involved.  Do not convert amounts to U.S.
Dollars.  Round fractional amounts up to the next whole amount.
For example, 21,527.25 Euros would be recorded as 21528.`,
  '28': `28.
If the cash out transaction(s) involved a foreign currency
or currencies, enter the total amount of each foreign currency
and select the country that issued the currency. Foreign cash
out" the sentence "Complete a separate Item 28 for each foreign
currency involved in the cash out transaction(s).  Identify each
foreign currency involved.  Do not convert amounts to U.S. Dollars.
Round fractional amounts up to the next whole amount.  For example,
21,527.25 Euros would be recorded as 21527.`
};
