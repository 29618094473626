import { Box } from '@mui/material';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';

export const ClearNotificationsModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = (props) => {
  return (
    <Dialog
      title=""
      isOpen={props.isOpen}
      handleClose={props.onClose}
      dataCy="confirm-clear-notifications-modal"
      noActions
    >
      <Container column align="center" margin="1rem 0" width="100%">
        <Container grow column align="center">
          <Box
            className="material-icons"
            sx={{ color: (theme) => theme.palette.error.main, mb: '1.25rem', fontSize: '60px' }}
          >
            error_outline
          </Box>

          <ContainerItem padding="0" margin="0 0 1.75rem 0">
            <Header type="h2" content={'Confirm Clear All Notifications'} />
          </ContainerItem>

          <ContainerItem padding="0" margin="0 0 4rem 0">
            <Text
              type="body1"
              sx={{ textAlign: 'center' }}
              content={
                'Are you sure you want to remove all notifications? Notifications cannot be recovered once deleted.'
              }
            />
          </ContainerItem>
        </Container>

        <ContainerItem column padding="0" justify="center" align="center">
          <Button
            color="primary"
            label="Confirm"
            onClick={props.onSubmit}
            style={{ marginBottom: '0.5rem', width: '335px' }}
          />

          <Button
            color="default-text"
            label="Close"
            style={{ alignSelf: 'center' }}
            onClick={props.onClose}
          />
        </ContainerItem>
      </Container>
    </Dialog>
  );
};
