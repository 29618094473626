import { Activity, CommentTask, OrganizationRoleResolver, Post, RootComment, TaskStatus } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { MentionItem, SuggestionDataItem } from 'react-mentions';
import { CommentStore } from 'stores/CommentStore';
import { CommentsRepo, PM } from './comments.repo';
import { FieldValues } from 'react-hook-form';
import { uploadCommentDocumentToS3 } from 'util/s3.util';
import { CommentsApi } from 'api';
import { SnackbarStore } from 'stores/SnackBarStore';
import heic2any from 'heic2any';
import { FiBankStore } from 'stores/FiBankStore';
import { UserStore } from 'stores/UserStore';

interface VM {
  activities: Activity[];
  commentVal: string;
  currentCommentTask: Partial<CommentTask> | null;
  externalOrgId: string;
  files: File[];
  focused: boolean;
  hasError: boolean;
  isArchiveModalOpen: boolean;
  isBankAdmin: boolean;
  isCreateTaskModalOpen: boolean;
  isExternalComment: boolean;
  isExternalConfirmationModalOpen: boolean;
  isMarkAsCompleteModalOpen: boolean;
  mentionedUsers: MentionItem[];
  orgId: string;
  post: Post | null;
  reply: RootComment | null;
  usersDictionary: Record<string, any>;
  usersList: SuggestionDataItem[];
}

@injectable()
export class CommentsPresenter {
  @inject(CommentsRepo)
  private repo: CommentsRepo;

  @inject(CommentsApi)
  private commentsApi: CommentsApi;

  @inject(FiBankStore)
  private fiBankStore: FiBankStore;

  @inject(CommentStore)
  private commentStore: CommentStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  @inject(UserStore)
  private userStore: UserStore;

  @inject(OrganizationRoleResolver)
  private resolver: OrganizationRoleResolver;

  public viewModel: VM = {
    activities: [],
    commentVal: '',
    currentCommentTask: null,
    externalOrgId: '',
    files: [],
    focused: false,
    hasError: false,
    isArchiveModalOpen: false,
    isBankAdmin: false,
    isCreateTaskModalOpen: false,
    isExternalComment: false,
    isExternalConfirmationModalOpen: false,
    isMarkAsCompleteModalOpen: false,
    mentionedUsers: [],
    orgId: '',
    post: null,
    reply: null,
    usersDictionary: {},
    usersList: []
  };

  get isCrbApp() {
    return window.location.href.indexOf('/secure/crb') > -1;
  }

  get activeCommentsCount() {
    const post = this.viewModel.post;
    let count = 0;

    if (post && post.comments) {
      post.comments
        .filter((c) => !c.archived_by)
        .forEach((comment) => {
          count += 1;

          if (comment.children) {
            comment.children.forEach(() => {
              count += 1;
            });
          }
        });
    }

    return count;
  }

  get commentBarLoading() {
    return this.commentStore.loading || this.repo.programmersModel.isLoading;
  }

  get showCommentsBar() {
    return !!this.commentStore.currentPostId;
  }

  get showCommentsBarFooter() {
    return this.commentStore.activeTab === 'comments';
  }

  get userMentioned() {
    return this.viewModel.mentionedUsers.length > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action(async (orgId: string, externalOrgId?: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({
        activities: programmersModel.activities,
        post: programmersModel.post,
        usersDictionary: programmersModel.usersDictionary,
        usersList: programmersModel.usersList
      });
    });
    this.updateViewModel({
      orgId,
      externalOrgId,
      isBankAdmin:
        this.fiBankStore.bank.groups && this.userStore.user
          ? this.resolver.userHasRole(this.fiBankStore.bank.groups, this.userStore.user, 'bank_admin') ||
            this.resolver.userHasRole(
              this.fiBankStore.bank.groups,
              this.userStore.user,
              'bank_primary_contact'
            )
          : false
    });
  });

  public addFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);

    // Convert heic images to jpeg
    if (newFiles[0].type === 'image/heic' || newFiles[0].type === 'image/heif') {
      const convertedBlob = await heic2any({
        blob: newFiles[0],
        toType: 'image/jpeg'
      });
      const regexHeic = new RegExp(`.heic`, 'ig');
      const regexHeif = new RegExp(`.heif`, 'ig');

      const convertedFile = new File(
        [convertedBlob as BlobPart],
        newFiles[0].name.replaceAll(regexHeic, '.jpeg').replaceAll(regexHeif, '.jpeg'),
        {
          lastModified: newFiles[0].lastModified,
          type: 'image/jpeg'
        }
      );

      newFiles[0] = convertedFile;
    }

    this.setFiles([...this.viewModel.files, ...newFiles]);
  };

  public archiveOrUnarchiveComment = async (archived: boolean, commentId: string) => {
    try {
      let newPost: Post;

      if (archived) {
        newPost = await this.commentsApi.unarchiveComment(commentId);
      } else {
        newPost = await this.commentsApi.archiveComment(commentId);
      }

      this.updateCommentsInPost(newPost.comments);
      this.commentStore.shouldFetchCount(true);

      this.openArchiveModal(false);
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage('There was an issue archiving comment.');
    }
  };

  public flagError = (hasError: boolean) => {
    this.updateViewModel({
      hasError
    });
  };

  public focusCommentInput = (commentInputRef: React.MutableRefObject<HTMLTextAreaElement | null>) => {
    commentInputRef?.current?.focus();
  };

  public getActivities = async (bankId?: string) => {
    if (bankId) {
      await this.repo.getActivities(bankId);
    }
  };

  public getMentionableUsers = (orgId: string, externalOrgId?: string) => {
    this.repo.getMentionableUsers(orgId, externalOrgId, this.viewModel.isExternalComment);
  };

  public getPost = async (bankId?: string) => {
    this.resetCommentForm();
    await this.repo.getPost(this.isCrbApp, bankId);
  };

  public getTasks = async (orgId: string, bankId: string, status: TaskStatus) => {
    return await this.repo.getTasks(orgId, bankId, status);
  };

  public isNotSupportUser = () => {
    const url = window.location.pathname;
    if (url.includes('/secure/crb')) return true;
    return !this.resolver.userHasRole(
      this.fiBankStore.bank.groups,
      this.userStore.user,
      'gcv_customer_support'
    );
  };

  public markCommentExternal = (isExternal: boolean) => {
    this.updateViewModel({
      isExternalComment: isExternal
    });
  };

  public markTaskAsComplete = async (bankId: string, taskId: string, completedNote: string) => {
    await this.repo.markTaskAsComplete(bankId, taskId, completedNote);
  };

  public mentionUsers = (mentionedUsers: MentionItem[]) => {
    this.updateViewModel({
      mentionedUsers
    });
  };

  public onSubmitComment = async (data: FieldValues) => {
    if (!this.viewModel.commentVal) {
      this.flagError(true);
      return;
    }
    if (data.assignee && data.dueDate) {
      // create external task and post external comment with task
      const task: { assigned_user_ids: any[]; process_date: string; dispensary_id?: string } | undefined =
        data.assignee && data.dueDate
          ? {
              assigned_user_ids: [data.assignee],
              process_date: new Date(data.dueDate).toISOString()
            }
          : undefined;

      if (this.viewModel.isExternalComment && task) {
        task.dispensary_id = this.viewModel.externalOrgId;
      }

      if (this.viewModel.reply && data.completeTask) {
        await this.repo.markTaskAsComplete(
          this.viewModel.reply.bank_id,
          this.viewModel.reply.task_id ?? '',
          data.completed_note
        );
      }

      await this.postComment(task);
    } else if (this.viewModel.isExternalComment && !this.viewModel.reply) {
      // open the external confirmation modal or create task modal
      if (data.createTask) {
        this.openCreateTaskModal(true);
      } else {
        this.openExternalConfirmationModal(true);
      }
    } else if (!this.viewModel.isCreateTaskModalOpen && data.createTask) {
      // is create task checked
      this.openCreateTaskModal(true);
    } else if (!this.viewModel.isMarkAsCompleteModalOpen && data.completeTask) {
      // are we completing a task
      this.openMarkAsCompleteModal(true);
    } else {
      await this.postComment();
    }
  };

  public openArchiveModal = (isOpen: boolean) => {
    this.updateViewModel({
      isArchiveModalOpen: isOpen
    });
  };

  public openExternalConfirmationModal = (isOpen: boolean) => {
    this.updateViewModel({
      isExternalConfirmationModalOpen: isOpen
    });
  };

  public openCreateTaskModal = (isOpen: boolean) => {
    this.updateViewModel({
      isCreateTaskModalOpen: isOpen
    });
  };

  public openMarkAsCompleteModal = (isOpen: boolean) => {
    this.updateViewModel({
      isMarkAsCompleteModalOpen: isOpen
    });
  };

  public postComment = async (taskData?: { assigned_user_ids: string[]; process_date: string }) => {
    if (!this.viewModel.commentVal) {
      this.flagError(true);
      return;
    }
    this.openExternalConfirmationModal(false);

    try {
      const documents = await Promise.all(
        this.viewModel.files.map(async (file) => {
          const s3Key = await uploadCommentDocumentToS3(file, this.viewModel.orgId);
          return {
            filename: file.name,
            s3Key: s3Key
          };
        })
      );

      const commentBody = {
        content: this.viewModel.commentVal,
        internal: this.viewModel.reply ? this.viewModel.reply.internal : !this.viewModel.isExternalComment,
        parentId: this.viewModel.reply?.id,
        type: this.commentStore.type,
        idComponents: this.commentStore.idComponents,
        mentionedUsers: this.viewModel.mentionedUsers.map((u) => u.id),
        muoDispensaryId:
          this.viewModel.reply && this.viewModel.reply.internal
            ? this.viewModel.orgId
            : this.viewModel.isExternalComment
            ? this.viewModel.externalOrgId
            : this.viewModel.orgId,
        documents: documents,
        fiId: this.commentStore.idComponents.fiId,
        crbId: this.commentStore.idComponents.crbId,
        task: taskData
      };

      await this.commentsApi.addCommentV2(commentBody);
    } catch (e) {
      this.snackbarStore.showErrorSnackbarMessage('There was an issue commenting.');
    } finally {
      this.commentStore.fetchCount([
        {
          type: this.commentStore.type,
          idComponents: this.commentStore.idComponents
        }
      ]);
      await this.getPost(this.commentStore.idComponents.fiId);
    }
  };

  public removeFile = (file: File) => {
    this.setFiles(this.viewModel.files.filter((f) => f !== file));
  };

  public resetCommentForm = () => {
    this.updateViewModel({
      commentVal: '',
      files: [],
      hasError: false,
      reply: null,
      mentionedUsers: []
    });
  };

  public setCommentValue = (commentVal: string) => {
    this.updateViewModel({
      commentVal
    });
  };

  public setCurrentCommentTask = (currentCommentTask: Partial<CommentTask> | undefined) => {
    this.updateViewModel({
      currentCommentTask
    });
  };

  public setFiles = (files: File[]) => {
    this.updateViewModel({
      files
    });
  };

  public setReply = (reply: RootComment | null) => {
    this.updateViewModel({
      reply
    });
  };

  public updateCommentsInPost = (comments: RootComment[]) => {
    if (this.viewModel.post) {
      this.updateViewModel({ post: { ...this.viewModel.post, comments } });
    }
  };

  public areCommentsEnabledForUser = async (orgId: string) => {
    return await this.repo.areCommentsEnabledForUser(orgId);
  };
}
