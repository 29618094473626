// tslint:disable:max-line-length
export const homeToolTips = {
  '1': `Enter a descriptive name to identify the Suspicious Activity Report (SAR).`,
  '2': `Select "Initial report" if this is the first report filed on the suspicious activity. SARs filed as a result of a historical or other review of past transactional activity, either directed by a regulating authority or conducted as a financial institution initiative, must always be initial reports.  Select "Correct/Amend prior report" if the report corrects or amends a previously-filed report. Select "Continuing activity report" if this report involves suspicious activity reported on one or more previous reports. Continuing reports should be filed at least every 90 days on continuing suspicious activity. If this report is being jointly filed with another financial institution, select "Joint report" in addition to selecting the appropriate box(es) for the type of filing mentioned above.  Filers must clearly identify in Part V which Part III financial institutions are the joint filers on the SAR. If "Correct/Amend prior report" is selected and/or "Continuing activity report" is selected, enter the BSA Identification Number (BSA ID) assigned by FinCEN to the previously filed SAR.`,
  '3': `If "Correct/Amend prior report" and/or "Continuing activity report" is selected as the filing type, enter the BSA Identification Number (BSA ID) assigned by FinCEN to the previously filed SAR; otherwise, enter all zeros if the prior report BSA ID is unknown.`,
  '4': `If the FinCEN SAR is being filed in response to a current specific geographic targeting order (GTO), advisory, or other activity, enter the GTO or advisory title/reference or provide a brief description of the other activity. Leave this field blank if the FinCEN SAR does not relate to a GTO, advisory, or other targeted activity.`
};

export const partOneToolTips = {
  '79': `79. Select the appropriate type of financial institution that best describes the filing institution recorded in Item 76.  If none of the options apply, select "Other" and enter a brief description in the associated text field.  If necessary, include a more-detailed description in Part V.`,
  '76': `76. Enter the legal name of the filing institution as recorded on articles of incorporation or other documents establishing the institution. Enter the full name of the filer if the filer is an individual, such as a sole proprietorship, acting as a financial institution. If an individual’s name is recorded, enter the name in (first name) (middle name) (last name) or equivalent format, e.g. John Jacob Doe or Richard R. Roe II, etc.`,
  '75': `75. Select the appropriate Primary Federal Regulator or BSA Examiner of the filing institution recorded in Item 76.  If more than one regulator option could apply, select the regulator that has primary responsibility for enforcing compliance with the BSA.  Select "Internal Revenue Service (IRS)" if the filing institution is subject to U.S. law and none of the other codes apply or if the filing institution type is "Casino/Card Club," "Insurance company," or "MSB."  Select "Not Applicable" if the filing institution is a government agency or is not subject to U.S. law.`,
  '77': `77. Enter the Taxpayer Identification Number (TIN), either U.S. or foreign, of the financial institution or individual recorded in Item 76.  Enter the TIN as a single text string without formatting or special characters such as hyphens or periods.  A TIN in the format NNN-NN-NNNN would be entered as NNNNNNNNN.`,
  '78': `78. Select the type of TIN entered by selecting either "EIN," "SSN/ITIN," or "Foreign." Select "Foreign" if the subject has a foreign TIN of any type and record in Part V the name of the country that issued the foreign TIN.`,
  '80': `80. If "Securities/Futures" was selected for the type of financial institution, check all boxes that apply to indicate the type of securities and futures institution.  If "Other" is selected, record a brief description of the type of securities and futures institution in the associated text field.  If necessary, include a more-detailed description in Part V. Please select at least one type of securities and futures institution or individual filing this report.`,
  '81': `81. Select the appropriate identification type if the financial institution recorded in Item 76 has a Central Registration Depository (CRD) number, Investment Adviser Registration Depository (IARD) number, National Association of Commissioners (NAIC) Number, National Futures Association number, Nationwide Mortgage Licensing System (NMLS) number, Research, Statistics, Supervision, and Discount (RSSD) number, or Securities and Exchange Commission (SEC) Reporting File Number (RFN). Do not enter an SEC Central Index Key (CIK) number in place of the RFN. Enter the identification number as a single text string without formatting or special characters such as hyphens or periods. For example, an identification number in the format NNN-NN-NNNN would be entered as NNNNNNNNN.`,
  '82-86': `82-86. Enter the permanent street address, city, state/territory/province (U.S./Canada/Mexico only), ZIP Code or foreign postal code, and country of the financial institution identified in Item 76.  Provide the apartment number or suite number, if known, following the street address.  A non-location address such as a post office box or rural route number should be used only if no other street address information is available. ZIP Codes must be five or nine digits.  ZIP Codes and foreign postal codes must be entered without formatting or special characters such as spaces or hyphens.  For example, the ZIP Code 12354-6120 would be entered as 123546120. The foreign postal code HKW  702 would be entered HKW702.  For other foreign addresses enter the street address, city, postal code, and two letter country abbreviation or address equivalent.  If a foreign address contains other address information that does not conform to the SAR address format, record equivalent address information in the SAR address items and the full address in Part V.  No abbreviations are permitted in city names, which must be completely spelled out. A U.S. city name must match the city name used by the U.S. Postal Service for the associated state and ZIP Code.`,
  '87': `87. If the financial institution recorded in Item 76 has a separate doing business as (DBA) name, enter the name here. If an individual recorded in Item 76 has a separate also known as (AKA) name, enter that name here.  Do not include the acronyms DBA or AKA with the alternate name.`,
  '89': `89. Enter the name of the law enforcement agency, if any, which has been informed of the suspicious activity. If more than one LE agency has been contacted about the suspicious activity, record the information on one agency in Items 89-98 and the information on additional agencies in Part V.`,
  '88': `88. Enter the internal control number or file number assigned to the SAR by the financial institution recorded in Item 76.  This number should be unique to the SAR if possible, allowing interested parties such as law enforcement or the financial institution to reference the SAR without committing a potentially illegal disclosure of SAR data.`,
  '90': `90. Enter the name of the person contacted at the law enforcement agency.`,
  '91': `91. Enter the law enforcement contact telephone number, as well as the extension of the law enforcement telephone number (if applicable). Record all telephone numbers, both foreign and domestic, as a single number string without formatting or special characters such as parentheses or hyphens.  For example, a number in the format (NNN) NNN-NNNN would be recorded as NNNNNNNNNN.  Telephone numbers that are part of the North American Numbering Plan used by the U.S., Canada, many Caribbean countries, and current/former U.S. Pacific island protectorates must consist of an area code and seven-digit telephone number.  Other foreign telephone numbers should include the country number code.  If only a partial telephone number is known record that number in the phone number item and explain in Part V that the entry is a partial number.`,
  '92': `92. Enter the most-recent date the law enforcement agency was contacted about the suspicious activity. The date must be in MM/DD/YYYY format where MM = Month, DD = Day, and YYYY = Century and Year (e.g. 2018). If the agency was contacted on multiple dates, record the earlier contact dates in Part V.`,
  '93': `93. Enter the name of the filing institution designated contact office where additional information about the FinCEN SAR or supporting documentation can be requested.  If the FinCEN SAR is jointly filed, enter in Part V the contact office and telephone number information for all Part III joint filers.`,
  '94': `94. Enter the filing institution contact office telephone number, as well as the extension of the contact office telephone number (if applicable). Record all telephone numbers, both foreign and domestic, as a single number string without formatting or special characters such as parentheses or hyphens.  For example, a number in the format (NNN) NNN-NNNN would be recorded as NNNNNNNNNN.  Telephone numbers that are part of the North American Numbering Plan used by the U.S., Canada, many Caribbean countries, and current/former U.S. Pacific island protectorates must consist of an area code and seven-digit telephone number.  Other foreign telephone numbers should include the country number code.  If only a partial telephone number is known record that number in the phone number item and explain in Part V that the entry is a partial number.`
};

export const partTwoToolTips = {
  '51': `51. Select the appropriate type of financial institution that best describes the financial institution identified in Item 57.  If none of the options apply, select "Other" and enter a brief description in the associated text field.  If necessary, include a more-detailed description in Part V.  If "Casino/Card Club" is selected, then Item 53 for type of gaming institution must be completed.  If "Securities/Futures" is selected, then Item 54 for type of securities and futures institution must be completed.`,
  '52': `52. Select the appropriate Primary Federal Regulator or BSA Examiner of the financial institution recorded in Item 57.  If more than one regulator option could apply, select the regulator that has primary responsibility for enforcing compliance with the BSA.  Select "Internal Revenue Service (IRS)" if the filing institution is subject to U.S. law and none of the other codes apply or if the financial institution type recorded in Item 51 is "Casino/Card Club," "Insurance company," or "MSB."  Select "Not Applicable" if the financial institution is not subject to U.S. law.`,
  '53': `53. If "Casino/Card Club" was selected in Item 51 indicating the financial institution recorded in Item 57 is a gaming institution such as a casino or card club, record the type of gaming institution by checking the appropriate box. If none of the options for type of gaming institution are appropriate, check "Other" and provide a brief description in the associated text field.  If necessary, include a more detailed description in Part V.`,
  '54': `54. If "Securities/Futures" was selected in Item 51 indicating the financial institution recorded in Item 57 is part of the securities and futures industries, check all boxes that apply to indicate the type of securities and futures institution.  If none of the options for securities and futures type are appropriate, check "Other" and provide a brief description in the associated text field.  Examples of this are commodity pool operator or commodity trading adviser. If necessary, include a more detailed description in Part V.`,
  '55': `55. Check the appropriate box if the financial institution recorded in Item 57 has a Central Registration Depository (CRD) number, Investment Adviser Registration Depository (IARD) number, National Association of Commissioners (NAIC) Number, National Futures Association number, Nationwide Mortgage Licensing System (NMLS) number, Research, Statistics, Supervision, and Discount (RSSD) number, or Securities and Exchange Commission (SEC) Reporting File Number (RFN). Do not enter an SEC Central Index Key (CIK) number in place of the RFN. Enter the identification number as a single text string without formatting or special characters such as hyphens or periods. For example, an identification number in the format NNN-NN-NNNN would be entered as NNNNNNNNN.`,
  '56': `56. Financial institution role in transaction. Check the box "Selling location" if the customer purchased at the Part III financial institution the products or instruments recorded in Items 45 or 46. Check the box "Paying location" if the customer received payment from the Part III financial institution for the products or instruments recorded in Items 45 or 46. Check the box "Both" if the Part III financial institution was both a paying and selling location for the products or instruments recorded in Items 45 or 46.`,
  '57': `57. Enter the legal name of the financial institution where activity occurred as recorded on articles of incorporation or other documents establishing the institution.  Enter the full name of the filer if the filer is an individual, such as a sole proprietorship, acting as a financial institution. If an individual’s name is recorded, enter the name in (first name) (middle name) (last name) or equivalent format, e.g. John Jacob Doe or Richard R. Roe II, etc. Check "Unknown" if the legal name is unknown and leave this item blank.`,
  '58': '58. If the financial institution recorded in Item 57 has a separate doing business as (DBA) name, enter the name here. If an individual recorded in Item 57 has a separate also known as (AKA) name, enter that name here.  Do not include the acronyms DBA or AKA with the alternate name.',
  '59': `59. Enter the Taxpayer Identification Number (TIN), either U.S. or foreign, of the financial institution or individual recorded in Item 57.  Enter the TIN as a single text string without formatting or special characters such as hyphens or periods.  A TIN in the format NNN-NN-NNNN would be entered as NNNNNNNNN.  Check "Unknown" if the TIN is unknown. If the TIN in Item 59 is unknown and the Item 57 financial institution has a U.S. address, select TIN Type option "EIN" if the financial institution is an entity and "SSN-ITIN" if the financial institution is an individual. If the financial institution has a foreign address and the TIN is unknown, select "Foreign."`,
  '60': `60. Select the type of TIN entered by selecting either "EIN," "SSN/ITIN," or "Foreign." Select "Foreign" if the financial institution or individual recorded in Item 57 has a foreign TIN of any type and record in Part V the name of the country that issued the foreign TIN.  If the TIN in Item 59 is unknown and the Item 57 financial institution has a U.S. address, select TIN Type option "EIN" if the financial institution is an entity and "SSN-ITIN" if the financial institution is an individual. If the financial institution has a foreign address and the TIN is unknown, select "Foreign." Select "EIN" as the TIN type if the TIN is known and TIN type cannot be derived from other financial institution information.`,
  '61-65': `61-65. Enter the permanent street address, city, state/territory/province (U.S./Canada/Mexico only), ZIP Code or foreign postal code, state, and country for the financial institution identified in Item 57. Complete any address item that is known and check "Unknown" for any required address item that is not known. Provide the apartment number or suite number, if known, following the street address.  A non-location address such as a post office box or rural route number should be used only if no other street address information is available. ZIP Codes must be five or nine digits.  ZIP Codes and foreign postal codes must be entered without formatting or special characters such as spaces or hyphens.  For example, the ZIP Code 12354-6120 would be entered as 123546120. The foreign postal code HKW  702 would be entered HKW702.  For other foreign addresses enter the street address, city, postal code, and two letter country abbreviation or address equivalent.  If a foreign address contains other address information that does not conform to the SAR address format, record equivalent address information in the SAR address items and the full address in Part V.  No abbreviations are permitted in city names, which must be completely spelled out. A U.S. city name must match the city name used by the U.S. Postal Service for the associated state and ZIP Code.`,
  '66': `66. Enter the internal control number or file number, if applicable, assigned to the SAR by the financial institution recorded in Item 57.  This number should be unique to the SAR if possible, allowing interested parties such as law enforcement or the financial institution to reference the SAR without committing a potentially illegal disclosure of SAR data.`,
  '67': `67. If the financial institution recorded in Item 57 has suffered a loss because of the suspicious activity, record the amount of loss here. If some losses are known and other losses unknown, enter the total of known losses as of the date of filing. If "Continuing Activity Report" is selected as the filing type, enter the loss amount for the current SAR here and record in Part V the total aggregated losses for all SARs filed on the suspicious activity. All monetary amounts must be recorded in U.S. Dollars rounded up to the next whole dollar.  The amount $5,265.25 would be recorded as $5,266. The amount entered cannot be "0" or greater than the amount in Item 29 (Amount involved in this report).`,
  '68': `68. Branch's role in transaction.  Check the box "Selling location" if the branch sold the products or instruments recorded in Items 45 or 46 to a customer. Check the box "Paying location" if a customer received payment from the branch for the products or instruments recorded in Items 45 or 46. Check the box "Both" if the Part III financial institution branch was both a paying and selling location for the products or instruments recorded in Items 45 or 46.`,
  '69-74': `69-74. Enter the permanent street address, city, state/territory/province (U.S./Canada/Mexico only), ZIP Code or foreign postal code, state, and country for the branch or office involved in the suspicious activity. If the suspicious activity occurred at a branch or office location, the "Address of branch or office where activity occurred" and "Country" must be completed. All other branch items may be left blank if unknown. If no branch was involved in the suspicious activity, then the checkbox to indicate that no branch activity is involved in the suspicious activity must be selected.  Provide the apartment number or suite number, if known, following the street address.  A non-location address such as a post office box or rural route number should be used only if no other street address information is available. ZIP Codes must be five or nine digits.  ZIP Codes and foreign postal codes must be entered without formatting or special characters such as spaces or hyphens.  For example, the ZIP Code 12354-6120 would be entered as 123546120. The foreign postal code HKW  702 would be entered HKW702.  For other foreign addresses enter the street address, city, postal code, and two letter country abbreviation or address equivalent.  If a foreign address contains other address information that does not conform to the SAR address format, record equivalent address information in the SAR address items and the full address in Part V.  No abbreviations are permitted in city names, which must be completely spelled out. A U.S. city name must match the city name used by the U.S. Postal Service for the associated state and ZIP Code. If multiple branches are involved in the suspicious activity, complete a set of branch address items for each branch.`,
  '70': `70. Enter the RSSD (Research Statistics Supervision Discount) number of the branch, if known.`
};

export const partThreeToolTips = {
  '3': `3. Check this box if the subject described in Part I is an entity and not an individual.`,
  '3a': `3a. if all critical* subject information is unavailable. Check this box if all information for Items 4, 5, 11-16, 18, and 19 is unavailable. Checking this box will cause these items to become inactive. Do not check this box if information for even one of these items is available. Instead, enter the available information and check the "Unknown" boxes in each item for which the information is unavailable`,
  '4': `4. Enter the individual's last name or the entity's legal name. The legal name is the name on the articles of incorporation or other document that established the entity. Do not abbreviate names unless an abbreviation is part of the legal name. Check "Unknown" if the individual's last name or the entity's legal name is unknown.`,
  '5': `5. If the subject is an individual, enter the subject's first name.  Check "Unknown" if the first name of the subject is unknown.`,
  '6': `6. If the subject is an individual, enter the subject's middle name or middle initial.  Leave this item blank if the subject's middle name or middle initial is unknown.`,
  '7': `7. If the subject is an individual, enter the subject's suffix such as JR, SR, III, etc. Leave this item blank if the suffix is unknown or the individual’s legal name does not include a suffix.`,
  '8': `8. If the subject is an individual, select the appropriate gender option if known.  Select "Unknown" if the subject's gender is unknown.  This item does not create an obligation for a financial institution to collect gender information when such collection would be in conflict with the financial institution’s obligations under any other federal law.`,
  '19': `19. If the subject is an individual enter the subject's data of birth in MM/DD/YYYY format where MM = Month, DD = Day, and YYYY = Century and Year (e.g. 01/25/1970).  If the birth day and/or month is (are) unknown, enter "00" for the unknown day and/or month.  For example, a date of birth with an unknown day in February 1978 would be entered as 02/00/1978. Do not enter zeros for the year if the year is unknown.  Check "Unknown" if the year of birth or the complete date of birth is unknown.`,
  '9': `9. Enter the individual's also known as (AKA) name or the entity's doing business as (DBA) name if different from the name entered in Items 4-7.  Do not include the acronyms DBA or AKA with the alternate name.  Multiple AKA/DBA names may be completed if multiple subject alternate names are known.`,
  '10': `10. Record the occupation, profession, or type of business of the individual or entity recorded in Part I.  Use specific descriptions such as doctor, carpenter, attorney, used car dealership, plumber, truck driver, hardware store, etc.  Do not use non-descriptive items such as businessman, merchant, retailer, retired, or self-employed.  If words like self-employed, unemployed, or retired are used, add the current or former profession if known (e.g. self-employed building contractor, retired teacher, or unemployed carpenter).  If the occupation or business activity can be described in more detail, include the additional information in Part V.`,
  '10a': `10a. Select from the attached list the North American Industry Classification System Code that most-closely matches the occupation or type of business entered in Item 10.`,
  '16': `16. Enter the Taxpayer Identification Number (TIN) or foreign equivalent of the subject.  Enter all identifying numbers as a single text string without formatting or special characters such as hyphens or periods.  An identifying number in the format NNN-NN-NNNN would be entered as NNNNNNNNN. Check "Unknown" if the TIN is unknown. Item 17 TIN type is required if TIN is known.`,
  '17': `17. Select "EIN" if the subject has a U.S. Employer Identification Number (EIN). If "EIN" is selected,  then Item 3 "If entity" must also be checked because an EIN is assigned only to an entity.  Select "SSN/ITIN" if the subject has a U.S. Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).  Select "Foreign" if the subject has a foreign TIN of any type and record in Part V the name of the country that issued the foreign TIN.  Enter the subject’s TIN type (if known) even if "Unknown" is checked for the actual TIN.  If a 9-digit TIN is entered but the TIN type is unknown, check "EIN" if the subject is an entity or "SSN-ITIN" if the subject is an individual.  TINs that are not 9 digits are presumed to be foreign; therefore "Foreign" should be selected.`,
  '21': `21. Enter the subject's U.S. or foreign telephone number and extension in the space provided. Record all telephone numbers, both foreign and domestic, as a single number string without formatting or special characters such as parentheses or hyphens.  For example, a number in the format (NNN) NNN-NNNN would be recorded as NNNNNNNNNN.  Provide the telephone extension number if known.  Telephone numbers that are part of the North American Numbering Plan used by the U.S., Canada, many Caribbean countries, and current/former U.S. Pacific island protectorates must consist of an area code and seven-digit telephone number.  Other foreign telephone numbers should include the country number code.  If only a partial telephone number is known record that number in the phone number item and explain in Part V that the entry is a partial number. Leave blank any part of the telephone number that is unknown. If the telephone extension and/or telephone type for the subject are known, then telephone number must be recorded. Multiple sets of telephone contact information may be completed if multiple telephone numbers for the subject are known.`,
  '20': `20. Indicate the type of telephone number provided (home, work, mobile, or fax) by selecting the appropriate entry.`,
  '22': `22. Enter the subject's E-mail address if known. Include all formatting, punctuation, and special characters in the E-mail address. Leave this item blank if the E-mail address is unknown. An E-mail address must contain the "@" sign and a period in the text following the "@" sign.  Examples: johndoe@business.com or richardroephd@college.edu. Multiple E-mail addresses may be completed if multiple E-mail addresses for the subject are known.`,
  '22a': `22a. Enter the subject's website URL (Uniform Resource Locator) if known. Include all punctuation and special characters present in the URL.  Leave this item blank if the URL is unknown.  Multiple website addresses may be completed if multiple website addresses for the subject are known.`,
  '23': `23. Select "Yes" if the subject has made a statement to the filer admitting to involvement in or otherwise substantiating the suspicious activity. Record in Part V the nature of the corroborative statement and how it was recorded, i.e. written confession, verbal statement, etc. Select "No" if no corroborative statement was made to the filer.`,
  '28': `28. Record the subject's role in the suspicious activity by selecting the appropriate option.  Select "Purchaser/Sender" if the subject purchased or sent the financial instrument(s) or product(s) involved in the suspicious activity.  Select "Payee/Receiver" if the subject was the payee or receiver of the instrument(s) or product(s). Select "Both" if the subject was both a purchaser/sender and payee/receiver.  Instruments and products are not limited to the instruments and products listed in Items 45 and 46. Leave Item 28 blank if none of the roles apply to the suspicious activity.`,
  '11-15': `11-15. Enter the permanent street address, city, state/territory/province (U.S./Canada/Mexico only), ZIP Code or foreign postal code, and country for the subject. Complete any address item that is known and check "Unknown" for any required address item that is not known. Provide the apartment number or suite number, if known, following the street address.  A non-location address such as a post office box or rural route number should be used only if no other street address information is available. ZIP Codes must be five or nine digits.  ZIP Codes and foreign postal codes must be entered without formatting or special characters such as spaces or hyphens.  For example, the ZIP Code 12354-6120 would be entered as 123546120. The foreign postal code HKW  702 would be entered HKW702.  For other foreign addresses enter the street address, city, postal code, and two letter country abbreviation or address equivalent.  If a foreign address contains other address information that does not conform to the SAR address format, record equivalent address information in the SAR address items and the full address in Part V.  No abbreviations are permitted in city names, which must be completely spelled out. A U.S. city name must match the city name used by the U.S. Postal Service for the associated state and ZIP Code. If multiple branches are involved in the suspicious activity, complete a set of branch address items for each branch. Multiple sets of address fields may be completed if multiple present and past subject addresses are known.`,
  '18': `18. Enter the information used to identify the subject. Select "Other" if the identification provided does not match the available identification types and describe the identification in the associated text field. "Other" identification could include such things as an entity’s business license or incorporation documents, corporate ID cards, local government ID cards, etc. Do not include formatting such as hyphens or periods when entering the identification number.  Select the appropriate state and country from the drop-down list if the identification issuer was a U.S., Canadian, or Mexican state, territory, or province.  If the identification was issued by a country other than U.S., Canada, or Mexico, select the appropriate country from the drop-down list and leave the state blank.  Record the name and nature of the issuer in Part V.  Enter all identification data that is available.  Check "Unknown" if all identification information for the subject is not known.  Multiple known forms of identification associated with the subject should be identified. All identifying numbers must be entered as a single text string without formatting or special characters such as hyphens or periods.  An identifying number in the format NNN-NN-NNNN would be entered as NNNNNNNNN.  Such numbers include alien registration, CRD, CUSIP, driver’s license, state identification, EIN, IARD, ITIN, passport, RSSD, SEC ID, and SSN.`,
  '24': `24. Relationship of the subject to an institution listed in Part III or IV.  If the subject has a relationship with a financial institution or individual listed in Part III or Part IV, enter the financial institution's TIN and check all boxes that describe the relationship. If "Other" is checked, enter a brief description of the relationship in the associated text field.  When describing the "other" identification, avoid descriptions that are too general.  For example, the entry "broker" should be made more specific by indicating the relationship was "real estate broker," "securities broker," or "mortgage broker."  If necessary, describe the relationship more fully in Part V. If the subject has no known relationship with any of the Part III and Part IV financial institutions, a single Item 21 with box 21j "No known relationship" checked and the 21a "Institution TIN" field blank will be prepared. If the subject has a relationship with some institutions and does not have a relationship with others, an Item 21 will be prepared only on those institutions where a relationship was present. Additional Item 21s will not be prepared for the other institutions.If the SAR is a joint report, the checkbox for 24h "Director," 24i "Employee,"  24i "Officer," or 24k "Owner or Controlling Shareholder" must not be checked.  If the subject had a relationship with a financial institution recorded in Part III but the financial institution’s TIN is unknown, record the relationship but leave "Institution TIN" blank.  If the subject has relationships with multiple Part III and/or Part IV financial institutions, repeat this set of items for each financial institution.If more than one of 24h "Director," 24i "Employee,"  24i "Officer," or 24k "Owner or Controlling Shareholder" is selected and/or the status or action date is different for each, an Item 24 for each must be completed.`,
  '25': `25. If the Item 24 relationship of the subject to an institution recorded in Part III or IV contains "Director," "Employee," "Officer," and/or "Owner or Controlling Stakeholder," indicate the status of the relationship by selecting the appropriate option from the drop-down list.`,
  '27': `27. Enter information about any accounts involved in the suspicious activity that are related to the subject.  An account is related to a subject if the subject owns the account, has control over the account, or conducted activity in or through an account the subject does not own or control.  Check "No known account involved" if no account related to the subject is involved in the suspicious activity.  Check "Non-U.S. Financial Institution" if the account is located at a foreign financial institution. Check "Closed" if the account is closed.  Record all account numbers involved in the suspicious activity that are related to the subject even if the TIN or name of the financial institution is unknown.  When there are multiple Part III institutions recorded in the SAR and the TIN of a financial institution associated with account numbers is unknown, record the institution name and the associated account number(s) in Part V (example: "The account number 123654987 is held at First Federal Bank.").  If the filer is aware that the subject has an account involved in the suspicious activity with a financial institution listed in Part III but the account number is unknown, record the financial institution TIN but leave the associated account number field blank.  Explain the missing account number in Part V.  The subject’s relationship to any account must be clearly defined in Part V unless the subject is the owner of the account (example: "The subject made unauthorized purchases using a stolen debit card for account 3456789 owned by his aunt.")`
};

export const partFourToolTips = {
  '29': `29. Enter the total dollar amount involved in the suspicious activity for the time period of this report.  If the amount is unknown, leave this field blank and select the checkbox for "Amount Unknown."  If the checkbox for "Amount Unknown" is selected, explain why the dollar amount is unknown in Part V. If some dollar amounts are known while others are unknown, enter the total of all known amounts and explain the unknown amounts in Part V. If there is no amount involved in the suspicious activity, select the checkbox for "No amount involved." If the SAR is a continuing activity report, enter only the amount involved for the time period covered by this SAR. Do not include amounts from prior SARs in Item 29. All monetary amounts must be recorded in U.S. Dollars rounded up to the next whole dollar.  The amount $5,265.25 would be recorded as $5,266.  If the amount involves a foreign currency, record the currency name, amount, and country of origin in Part V. When converting a foreign currency to U.S. Dollars use an exchange rate for the date or dates the foreign currency was involved in the suspicious activity.`,
  '30': `30. Use the format MM/DD/YYYY where MM = month, DD = day, YYYY = Century and Year (e.g. 2018). Enter the suspicious activity date or date range for this report.  If the suspicious activity occurred on a single day, enter that date in the "From" field and leave the "To" field blank.  If the suspicious activity occurred on multiple days, enter the earliest date of suspicious activity in the "From" field and the most-recent date of suspicious activity in the "To" field.  If the exact date(s) of the suspicious activity is (are) unknown, enter a date range that the filer believes will encompass the date(s) of the suspicious activity.  Explain the nature of this date range in Part V.  If "Continuing activity report" is selected as the filing type, enter only the date range covered by this SAR.  Record in Part V the aggregated date range of all SARs filed on the continuing suspicious activity.`,
  '31': `31. Enter the cumulative amount involved in the suspicious activity if "Continuing activity report" is selected as the filing type. The cumulative amount represents the total amount involved in all SARs filed on the suspicious activity, including the current BSA SAR.  If the cumulative dollar amount is unknown because all dollar amounts are unknown or no dollar amount is involved, enter zero (0) and explain the entry in Part V. If some dollar amounts are known while others are unknown, enter the total of all known amounts here and explain the nature of the unknown amounts in Part V. All monetary amounts must be recorded in U.S. Dollars rounded up to the next whole dollar.  The amount $5,265.25 would be recorded as $5,266.  If the amount involves a foreign currency, record the currency name, amount, and country of origin in Part V. When converting a foreign currency to U.S. Dollars use an exchange rate for the date or dates the foreign currency was involved in the suspicious activity.`,
  '32-42': `32-42. Use the suspicious activity categories specified to record the type(s) of suspicious activity being reported.  Check all boxes that apply to the suspicious activity. If a category applies but none of the options within the category apply, check the category’s "Other" box and briefly describe the type of suspicious activity in the associated text field.  If necessary, explain the type of suspicious activity in more detail in Part V.`,
  '45': `45. Check all boxes that apply to record the product type(s) involved in the suspicious activity.  "Forex transactions" includes all foreign exchange transactions both on and off-exchange, including futures and options on foreign exchange.  "Futures/Options on futures" includes futures and options on any "commodity," as that term is defined in Section 1a(4) of the Commodity Exchange Act, including broad-based security indexes.  "Swap, hybrid or other derivative" includes all derivatives not included above including those traded over-the-counter and off-exchange.  If the product type is not covered by options listed, check "Other (List below)" and enter a brief description in the associated text field.  If necessary provide a more detailed product type (such as commodity futures contracts, options on commodity futures contracts, commodity options, and OTC agricultural trade options) in Item 48 "Product/instrument description"or in Part V. If there are multiple product types not covered by the options listed, record one as "Other (List below)" and provide the additional instrument types/payment mechanisms in Part V.`,
  '46': `46. Check all boxes that apply to record the instrument type(s) involved in the suspicious activity. If the specific instrument type is listed, select "Other" and enter a brief description in the associated text field. If necessary provide a more-detailed description of the instrument type in Part V. If there are multiple instrument types not listed in this item, select "Other" and record one in the associated text field and all others in Part V.`,
  '43': `43. Enter the Internet Protocol (IP) address, if known, of a subject’s electronic internet based contact with the financial institution. In addition to the IP address, enter the date (MM/DD/YYYY format) of the activity and/or the Coordinated Universal Time (UTC) (HH:MM:SS format) of the first instance of the reported IP address (if known).  Enter as many IP addresses as are known up to a maximum of 99. If the IP address is IPv4, use a variation of the format nnn.nnn.nnn.nnn (n = number) with periods between the segments.  First set of numbers must be values between 1-255 (inclusive); second, third and fourth set of numbers must be values between 0-255 (inclusive). If the IP address is IPv6, use a variation of the format cccc:cccc:cccc:cccc:cccc:cccc:cccc:cccc (c = character, IPv6 is alphanumeric in some segments) with colons between the segments. Multiple IP addresses should be completed if multiple IP addresses are involved in the suspicious activity.`,
  '44': `44. If one or more cyber-events are involved in the suspicious activity, select the appropriate indicator from the drop-down list and enter the supporting information in the associated text fields. If none of the indicators apply, select "Other" and provide a brief description of the cyber-event in the associated text field. The event value must be recorded if a cyber-event indictor is selected. If the "Command and control IP address" or "Suspicious IP address" indicator is selected, enter the date (MM/DD/YYYY format) of the activity and/or the Coordinated Universal Time (UTC) (HH:MM:SS format) of the first instance of the reported IP address (if known).  A maximum of 99 cyber-event indicators may be recorded. Cyber-event indicator information must be explained in the FinCEN SAR Part V narrative.`,
  '47': `47. If a commodity is involved in the suspicious activity, record the commodity type here.  Multiple commodity types should be reported if more than one commodity type is involved in the suspicious activity.`,
  '48': `48. Provide a description of the product recorded in Item 45 or instrument recorded in Item 46 for a more-complete understanding of the suspicious activity (if necessary).  Multiple descriptions should be completed if multiple products/instruments requiring a description are involved in the suspicious activity.`,
  '49': `49. Enter the three to five-letter code for the market where a commodity recorded in Items 45 or 47 was traded. Multiple entries should be recorded if multiple markets are involved in the suspicious activity. Use only the ISO 10383 Exchange/Market Identifier Codes (MIC) found at https://www.iso20022.org/10383/iso-10383-market-identifier-codes`,
  '50': `50. Enter the CUSIP® (Committee on Uniform Securities Identification Procedures) number of any securities products such as stocks and bonds involved in the suspicious activity. Multiple entries should be completed if there are multiple CUSIP numbers for multiple securities products involved in the suspicious activity.`
};

export const partFiveToolTips = {
  '51': `51. Enter a detailed explanation that accurately and completely describes the nature and circumstances of the suspicious activity. The narrative section of the report is critical to understanding the nature and circumstances of the suspicious activity.  The care with which the narrative is completed may determine whether the described activity and its possible criminal nature are clearly understood by investigators.  Filers must provide a clear, complete, and concise description of the activity, including what was unusual or irregular that caused suspicion. This description should encompass the data provided in Parts I through III, but should include any other information necessary to explain the nature and circumstances of the suspicious activity.  Filers should provide any information the filers believe necessary to better enable investigators to understand the reported suspicious activity.  Narratives must be completed in English.`
};
