import { Group, User } from '@gcv/shared';
import { Typography } from '@mui/material';
import { useComponent } from 'hooks';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Header,
  InputSelectSearch,
  InputText,
  InputTextArea,
  Text,
  MultiSelectSearch,
  ListOption
} from 'ui';
import palette from 'ui/theme/palette';

interface ManageUserRow {
  id: string;
  firstName: string;
  lastName: string;
  role?: string;
}

interface Props extends Record<string, unknown> {
  roleModifier: 'dispensary_' | 'bank_';
  isGroupEditable?: boolean;
  selectedUsers: string[];
  userRows: User[];
  roleOptions: { label: string; value: string }[];
  isStaffLoaded: boolean;
  defaultValues?: Group;
  title: string;
  cancelButton?: {
    onClick: () => void;
    label: string;
    color: 'default' | 'primary' | 'primary-outlined' | 'warning';
  };
  completeButton: {
    onClick: (data: FieldValues) => void;
    label: string;
    color: 'default' | 'primary' | 'primary-outlined' | 'warning';
  };
  isOpen: boolean;
  handleClose: () => void;
}

export const EditGroupModal: React.FC<Props> = useComponent((props) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: (props.defaultValues ? props.defaultValues : {}) as FieldValues
  });
  const [manageSearchTerm, setManageSeachTerm] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>(props.selectedUsers);
  const [exitGuard, setExitGuard] = React.useState(false);
  const [usersTouched, setUsersTouched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<ListOption[]>([]);

  React.useEffect(() => {
    if (selectedUsers.length !== props.selectedUsers.length) {
      if (!usersTouched) {
        setUsersTouched(true);
      }
    }
  }, [selectedUsers]);

  React.useEffect(() => {
    setOptions(
      props.userRows
        .filter(
          (user) =>
            !Boolean(selectedUsers.includes(user.id)) &&
            user.invitation_status !== 'archived' &&
            (user.firstName.toLowerCase().includes(manageSearchTerm.toLowerCase()) ||
              user.lastName.toLowerCase().includes(manageSearchTerm.toLowerCase()) ||
              user.role?.toLowerCase().includes(manageSearchTerm.toLowerCase()))
        )
        .map((item) => {
          return {
            label: `${item.firstName + ' ' + item.lastName}`,
            value: item.id
          } as ListOption;
        })
    );
  }, []);

  const onComplete = async (data: FieldValues) => {
    setLoading(true);
    const dataWithUsers = {
      ...data,
      users: selectedUsers,
      roles: data.roles?.map((o: { value: string; label: string }) => (o?.value ? o?.value : o))
    } as FieldValues;
    await props.completeButton.onClick(dataWithUsers);
    setLoading(false);
  };

  const showExitGuard = (usersTouched || Object.values(form.formState.dirtyFields).length) && !exitGuard;

  return (
    <Dialog
      height={exitGuard ? '470px' : '770px'}
      width={exitGuard ? '490px' : '460px'}
      noActions={exitGuard ? true : false}
      isOpen={props.isOpen}
      handleClose={() => {
        if (showExitGuard) {
          setExitGuard(true);
        } else {
          props.handleClose();
          form.formState;
          setManageSeachTerm('');
        }
      }}
      action={
        <Button
          label={props.completeButton.label}
          color={props.completeButton.color}
          onClick={form.handleSubmit(onComplete)}
          fullWidth
          isLoading={loading}
          dataCy="save-group-button"
        />
      }
      title={exitGuard ? '' : props.title}
      dataCy="edit-group-modal"
    >
      {!exitGuard ? (
        <Container column padding="0rem">
          <ContainerItem width="100%" padding="0rem">
            <InputText
              name="name"
              label="Group Name"
              readOnly={props.isGroupEditable}
              placeholder="Enter Group Name"
              rules={{
                required: {
                  message: 'is required',
                  value: true
                }
              }}
              dataCy="name"
              {...form}
            />
          </ContainerItem>
          <ContainerItem width="100%" padding="1rem 0rem">
            <InputTextArea
              name="description"
              label="Description"
              readOnly={props.isGroupEditable}
              {...form}
              rules={{
                required: { message: 'is required', value: true },
                maxLength: { value: 400, message: '400 max characters' }
              }}
              style={{ borderColor: palette.border, padding: '0.25rem' }}
              dataCy="description"
            />
          </ContainerItem>
          <ContainerItem width="100%" padding="0rem">
            <InputSelectSearch
              multiSelect
              name="roles"
              label="Permission"
              placeholder="Select role(s) for this group"
              readOnly={props.isGroupEditable}
              defaultValues={props.defaultValues?.roles}
              options={props.roleOptions}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="roles"
              {...form}
            />
          </ContainerItem>
          <ContainerItem width="100%" padding="1.5rem 0rem 0.5rem">
            <Typography component="div" variant="body1">
              <span style={{ fontWeight: '700', fontSize: '16px', lineHeight: '19px', fontStyle: 'Lato' }}>
                Add Users to Group
              </span>
            </Typography>
            <span
              style={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                fontStyle: 'Lato',
                color: palette.text.secondary
              }}
            >
              To remove users, go back to the previous screen and select the user(s) to remove from the group.
            </span>
          </ContainerItem>

          <MultiSelectSearch
            options={options}
            searchLabel="Search users"
            title=""
            onSelect={(value: string, isSelected?: boolean) => {
              setSelectedUsers((prev) => {
                const newSelected = [...prev];
                const index = selectedUsers.findIndex((id) => id === value);
                if (isSelected !== undefined) {
                  if (index < 0 && isSelected) {
                    newSelected.push(value);
                  } else {
                    newSelected.splice(index, 1);
                  }
                } else {
                  if (index < 0) {
                    newSelected.push(value);
                  } else {
                    newSelected.splice(index, 1);
                  }
                }

                return newSelected;
              });
            }}
          />
        </Container>
      ) : (
        <Container column align="center" justify="center" padding="0rem">
          <ContainerItem justify="center" width="100%" padding="35px 0 0 0">
            <span className="material-icons" style={{ color: palette.error.main, fontSize: '60px' }}>
              error_outline
            </span>
          </ContainerItem>
          <ContainerItem justify="center" width="100%" padding="0 0 20px 0">
            <Header content="You have unsaved changes" type="h1" />
          </ContainerItem>
          <ContainerItem justify="center" width="100%" padding="0rem">
            <Text
              sx={{ color: palette.text.primary, fontSize: '17px', fontWeight: '550' }}
              content={'In order to keep your progress intact, save before exiting.'}
            />
          </ContainerItem>
          <ContainerItem padding="110px 0 15px 0">
            <Button
              label={'Keep Editing'}
              onClick={() => setExitGuard(false)}
              color="primary"
              width="370px"
              dataCy="back-button"
            ></Button>
          </ContainerItem>
          <ContainerItem justify="center" width="100%" padding="0rem">
            <span
              onClick={() => {
                setManageSeachTerm('');
                props.handleClose();
              }}
              style={{ cursor: 'pointer' }}
            >
              <Text
                sx={{ color: palette.text.secondary, fontWeight: '700' }}
                content={'Exit without saving'}
                type="body2"
              />
            </span>
          </ContainerItem>
        </Container>
      )}
    </Dialog>
  );
});
