import { idtypes, USStateOptions } from 'domain/consts';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  Button,
  Container,
  ContainerItem,
  Form,
  Header,
  InputHidden,
  InputSelect,
  InputText,
  Text
} from 'ui';
import { IdVerificationPresenter } from '../id-verification-presenter';
import { UploadIdentificationDocsModal } from './upload-identification-docs-modal';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  isMobile: boolean;
  uploadedIdCallback: (isCompleted: boolean) => void; // Used to track the hidden input for the form.
  onChange?: () => void;
}
export const IdentificationForm: React.FC<Props> = (props) => {
  const { form, isMobile, uploadedIdCallback } = props;
  const presenter = useInjection(IdVerificationPresenter);

  React.useEffect(() => {
    // Comparing takes care the form's the initial state matches the model's initial state.
    const modelValue = !!presenter.viewModel.idFrontDocument && !!presenter.viewModel.idBackDocument;
    const fieldValue = !!form.getValues('hasUploadedId');

    if (modelValue !== fieldValue || presenter.viewModel.uploadDocumentsModalOpen) {
      uploadedIdCallback(!!presenter.viewModel.idFrontDocument && !!presenter.viewModel.idBackDocument);
    }
  }, [
    presenter.viewModel.idFrontDocument,
    presenter.viewModel.idBackDocument,
    presenter.viewModel.uploadDocumentsModalOpen
  ]);

  return (
    <>
      <Container padding="0" dataCy="identification-card">
        <Container padding={isMobile ? '20px 0 8px 0' : '28px 0 8px 0'}>
          <Header mobileHeader={isMobile} content="Identification" type="h2" />
        </Container>
        <Form>
          <Container padding="0" width="100%">
            <ContainerItem
              padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
              width="100%"
              maxWidth={isMobile ? 'none' : '20%'}
              minWidth={isMobile ? 'none' : '200px'}
            >
              <InputSelect
                placeholder="Select"
                label="Type"
                name="idType"
                options={idtypes}
                rules={{ required: { message: 'is required', value: true } }}
                dataCy="id-type"
                additionalOnChange={(e) => {
                  const type = form.getValues('idType');
                  presenter.setIdType(type);
                  if (props.onChange) {
                    props.onChange();
                  }
                }}
                onBlur={props.onChange}
                {...form}
              />
            </ContainerItem>
            <ContainerItem
              padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
              width="100%"
              maxWidth={isMobile ? 'none' : '20%'}
              minWidth={isMobile ? 'none' : '200px'}
            >
              <InputText
                placeholder="ID Number"
                label="ID Number"
                name="idNumber"
                rules={{ required: { message: 'is required', value: true } }}
                dataCy="id-number"
                onChange={props.onChange}
                onBlur={props.onChange}
                {...form}
              />
            </ContainerItem>
            {presenter.viewModel.idType === 'dlicense' ||
            presenter.viewModel.idType === 'nondriverid' ||
            presenter.viewModel.idType === 'other' ? (
              <ContainerItem
                padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                width="100%"
                maxWidth={isMobile ? 'none' : '20%'}
                minWidth={isMobile ? 'none' : '200px'}
              >
                <InputSelect
                  placeholder="Select"
                  label="State"
                  name="idState"
                  options={USStateOptions}
                  dataCy="id-state"
                  rules={{
                    required: {
                      message: 'is required',
                      value: presenter.viewModel.idType === 'other' ? false : true
                    }
                  }}
                  additionalOnChange={props.onChange}
                  onBlur={props.onChange}
                  {...form}
                />
              </ContainerItem>
            ) : null}
          </Container>
          <ContainerItem padding="8px 0" width="100%">
            <Text
              sx={{
                mb: '6px',
                fontFamily: 'Lato',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '15px'
              }}
              content="Upload Your ID *"
            />
            <Button
              color="primary"
              label={
                presenter.viewModel.idFrontDocument && presenter.viewModel.idBackDocument
                  ? 'Edit Documents'
                  : 'Upload ID'
              }
              onClick={() => {
                presenter.setUploadDocumentsModalOpen(true);
              }}
              dataCy="upload-id-button"
            />
            <InputHidden
              name="hasUploadedId"
              label="Uploaded ID"
              rules={{ required: { message: 'is required', value: true } }}
              {...form}
            />
          </ContainerItem>
        </Form>
      </Container>
      <UploadIdentificationDocsModal />
    </>
  );
};
