import { useInjection } from 'ioc';
import * as React from 'react';
import { Table, TableContainer } from 'ui';
import { AccountOwnersPresenter } from '../account-owners-presenter';

export const AccountOwnersTable: React.FC = () => {
  const presenter = useInjection(AccountOwnersPresenter);
  return (
    <TableContainer>
      <Table
        columns={[
          { id: 'firstName', label: 'First Name', widthPercent: 25 },
          { id: 'lastName', label: 'Last Name', widthPercent: 25 },
          { id: 'email', label: 'Email', widthPercent: 50 }
        ]}
        defaultSort="firstName"
        rows={presenter.viewModel.invitedUsers.map((u) => {
          return { id: u.id, data: u };
        })}
        minWidth={540}
        dataCy="invited-users-table"
      />
    </TableContainer>
  );
};
