import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { ReactComponent as PageBackIcon } from 'assets/images/page-back-icon.svg';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ContainerItem, Header, Hyperlink, Text } from 'ui';

interface Props {
  bottomActions?: React.ReactNode;
  alignBottomActions?: 'flex-start' | 'flex-end' | 'center';
  dataCy: string;
  description?: string;
  onBack?: { path: string; state?: { from: string } };
  onBackCallback?: () => unknown | boolean;
  onLoadCallback?: () => void;
  subtitle?: string;
  title: string | React.ReactNode;
  titleIcon?: React.ReactNode;
  titlePadding?: string | number;
  topActions?: React.ReactNode;
  actions?: React.ReactNode;
  zeroState?: React.ReactNode;
  descriptionHeader?: boolean;
  stickyHeader?: boolean;
  padding?:
    | string
    | number
    | { xs?: string | number; sm?: string | number; md?: string | number; lg?: string | number };
  backIconMargin?: string;
  descriptionStyles?: SxProps<Theme>;
  noLeftMarginForTitle?: boolean;
  alignHeader?: 'flex-start' | 'flex-end' | 'center';
  headerType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  inlineTitle?: boolean;
  height?: string;
  width?: string;
  children?: React.ReactNode;
}

export const Page: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [showFullDescription, setShowDescription] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  React.useEffect(() => {
    if (props.onLoadCallback) props.onLoadCallback();
  }, []);

  const onBack = () => {
    if (props.onBackCallback) props.onBackCallback();
    if (props.onBack && props.onBack?.path !== '') {
      navigate(props.onBack.path);
    } else {
      navigate(-1);
    }
  };

  // 74 matches top nav bar height
  const bottomHeight = '74px';
  const mainHeight = props.bottomActions ? `calc(100% - 74px)` : '100%';

  return (
    <>
      <div style={{ height: props.height ?? mainHeight, overflow: props.stickyHeader ? 'hidden' : 'auto' }}>
        <Container
          column
          dataCy={props.dataCy}
          width={props.width ? props.width : '100%'}
          padding={props.padding ? props.padding : '11px 12px 11px 26px'}
          overflow="visible"
        >
          <Container
            align={props.alignHeader ? props.alignHeader : 'flex-start'}
            dataCy="page-header"
            padding={0}
            width="100%"
            column={isMobile}
          >
            {props.title && (
              <ContainerItem
                flex
                align="flex-start"
                padding={props.titlePadding ? props.titlePadding : 0}
                margin={props.noLeftMarginForTitle ? '0px' : '0 0.5rem'}
                width={isMobile ? '100%' : '64%'}
              >
                {props.onBack && (
                  <ContainerItem padding="0">
                    <PageBackIcon
                      onClick={onBack}
                      style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        height: '30px',
                        width: '30px',
                        marginTop: props.backIconMargin ? props.backIconMargin : '0.5rem'
                      }}
                      data-cy="back-button"
                    />
                  </ContainerItem>
                )}

                <Container
                  padding={typeof props.title === 'string' ? '4px 0 0 0' : '0'}
                  column
                  align="flex-start"
                >
                  <Container padding="0 0 6px 0">
                    <Container align="center" padding={0}>
                      <Box
                        sx={{ padding: props.inlineTitle ? '8px 0 0 0 ' : '2px 0 0 0', maxWidth: '850px' }}
                      >
                        {typeof props.title === 'string' ? (
                          <Header
                            inline
                            content={props.title}
                            dataCy="page-title"
                            type={props.headerType ? props.headerType : 'h1'}
                          />
                        ) : (
                          props.title
                        )}

                        {props.titleIcon && (
                          <Box sx={{ display: 'inline-block', fontSize: '26px', padding: '4px 8px 0 8px' }}>
                            {props.titleIcon}
                          </Box>
                        )}
                      </Box>
                    </Container>

                    {props.description && !props.descriptionHeader && (
                      <Container width="100%" padding={0}>
                        <ContainerItem padding="0" column>
                          <Box>
                            <Text
                              content={props.description}
                              dataCy="page-description"
                              type="body1"
                              sx={
                                props.descriptionStyles
                                  ? props.descriptionStyles
                                  : {
                                      color: (theme) => theme.palette.text.secondary
                                    }
                              }
                            />
                          </Box>
                        </ContainerItem>
                      </Container>
                    )}
                  </Container>
                </Container>
              </ContainerItem>
            )}

            {props.topActions && (
              <ContainerItem
                flex
                align="center"
                justify="right"
                padding="0"
                textAlign="left"
                width={isMobile ? '100%' : '34%'}
              >
                {props.topActions}
              </ContainerItem>
            )}
          </Container>

          {props.description && props.descriptionHeader && (
            <Container width="100%" padding={0}>
              <ContainerItem padding="0 0 8px 0" column>
                <Box>
                  <Text
                    content="Description"
                    type="body3"
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                      fontWeight: 700,
                      paddingBottom: '10px'
                    }}
                  />
                  <Text
                    content={
                      props.description.length > 106 && !showFullDescription
                        ? props.description.substring(0, 105) + '...'
                        : props.description
                    }
                    dataCy="page-description"
                    type="body2"
                    sx={{
                      color: '#000',
                      paddingBottom: '13.5px',
                      display: 'inline',
                      wordBreak: 'break-word'
                    }}
                  />

                  {props.description.length > 106 && (
                    <Hyperlink
                      label={showFullDescription ? 'See less' : 'See more'}
                      style={{
                        display: 'inline',
                        marginLeft: '4px',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}
                      onClick={() => {
                        setShowDescription(!showFullDescription);
                      }}
                    />
                  )}
                </Box>
              </ContainerItem>
            </Container>
          )}

          {!props.zeroState && props.actions && (
            <ContainerItem dataCy="page-actions" padding={0}>
              <Box>{props.actions}</Box>
            </ContainerItem>
          )}

          {props.subtitle && (
            <Container>
              <Container padding={0}>
                <ContainerItem padding={0}>
                  <Box sx={{ ml: props.onBack ? 'calc(0.625rem + 30px)' : 0 }}>
                    <Header type="h2" content={props.subtitle} dataCy="page-subtitle" />
                  </Box>
                </ContainerItem>
              </Container>
            </Container>
          )}

          <ContainerItem width="100%" padding="1rem 0 0 0">
            {props.zeroState ? props.zeroState : props.children}
          </ContainerItem>
        </Container>
      </div>

      {props.bottomActions && (
        <Box
          sx={{
            alignItems: 'center',
            borderTop: '1px solid #dbe0eb',
            bottom: 0,
            display: 'flex',
            height: bottomHeight,
            position: 'relative',
            width: '100%',
            backgroundColor: 'background.paper',
            boxShadow: '0px -2px 10px rgba(5, 30, 39, 0.2)'
          }}
          data-cy="page-bottom-actions"
        >
          <Container align="center" justify={props.alignBottomActions || 'flex-end'} padding={0} width="100%">
            <ContainerItem>{props.bottomActions}</ContainerItem>
          </Container>
        </Box>
      )}
    </>
  );
};
