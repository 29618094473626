import { List } from '@mui/material';
import * as React from 'react';
import { Icon, ListItemLink } from 'ui/atoms';
import { Drawer } from 'ui/molecules';

interface Props {
  openNavDrawer: boolean;
  permanent: boolean;
  setOpenNavMenu: (isOpen: boolean) => void;
}

export const GcvNavDrawer: React.FC<Props> = (props) => {
  const [activeLink, setActiveLink] = React.useState('');

  React.useEffect(() => {
    if (window.location.href.indexOf('/secure/gcv/dashboard') > -1) {
      setActiveLink('dashboard');
    } else if (window.location.href.indexOf('/secure/gcv/deposits') > -1) {
      setActiveLink('deposits');
    } else if (window.location.href.indexOf('/secure/gcv/sales') > -1) {
      setActiveLink('sales');
    } else if (window.location.href.indexOf('/secure/gcv/fincen') > -1) {
      setActiveLink('fincen');
    } else if (window.location.href.indexOf('/secure/gcv/ingestion') > -1) {
      setActiveLink('ingestion');
    } else if (window.location.href.indexOf('/secure/gcv/organizations') > -1) {
      setActiveLink('organizations');
    } else if (window.location.href.indexOf('/secure/gcv/users') > -1) {
      setActiveLink('users');
    } else if (window.location.href.indexOf('/secure/gcv/data-generation') > -1) {
      setActiveLink('data-generation');
    } else if (window.location.href.indexOf('/secure/gcv/cre') > -1) {
      setActiveLink('cre');
    } else if (window.location.href.indexOf('/secure/gcv/access') > -1) {
      setActiveLink('access');
    }
  });

  return (
    <Drawer
      width="xsmall"
      anchor="left"
      hideBackdrop={props.permanent}
      onClose={() => props.setOpenNavMenu(false)}
      open={props.openNavDrawer}
      permanent={props.permanent}
    >
      <List component="nav">
        <ListItemLink
          active={activeLink === 'dashboard'}
          dataCy="dashboard-nav-link"
          icon={<Icon type="dashboard" />}
          onClick={() => setActiveLink('dashboard')}
          text="Dashboard"
          to="/secure/gcv/dashboard"
        />

        <ListItemLink
          active={activeLink === 'users'}
          dataCy="users-nav-link"
          icon={<Icon type="people" />}
          onClick={() => setActiveLink('users')}
          text="Users"
          to="/secure/gcv/users"
        />

        <ListItemLink
          active={activeLink === 'organizations'}
          dataCy="organizations-nav-link"
          icon={<Icon type="store" />}
          onClick={() => setActiveLink('organizations')}
          text="Organizations"
          to="/secure/gcv/organizations"
        />

        <ListItemLink
          active={activeLink === 'deposits'}
          dataCy="deposits-nav-link"
          icon={<Icon type="account_balance" />}
          onClick={() => setActiveLink('deposits')}
          text="Deposits"
          to="/secure/gcv/deposits"
        />

        <ListItemLink
          active={activeLink === 'sales'}
          dataCy="sales-nav-link"
          icon={<Icon type="attach_money" />}
          onClick={() => setActiveLink('sales')}
          text="Sales"
          to="/secure/gcv/sales"
        />

        <ListItemLink
          active={activeLink === 'ingestion'}
          dataCy="ingestion-nav-link"
          icon={<Icon type="compare_arrows" />}
          onClick={() => setActiveLink('ingestion')}
          text="Ingestion"
          to="/secure/gcv/ingestion"
        />

        <ListItemLink
          active={activeLink === 'fincen'}
          dataCy="fincen-nav-link"
          icon={<Icon type="gavel" />}
          onClick={() => setActiveLink('fincen')}
          text="FinCEN"
          to="/secure/gcv/fincen"
        />

        <ListItemLink
          active={activeLink === 'cre'}
          dataCy={'cre-nav-link'}
          icon={<Icon type="pan_tool" />}
          onClick={() => setActiveLink('cre')}
          text={'CRE'}
          to={'/secure/gcv/cre'}
        />

        <ListItemLink
          active={activeLink === 'data-generation'}
          dataCy="data-generation-nav-link"
          icon={<Icon type="settings" />}
          onClick={() => setActiveLink('data-generation')}
          text="Utilities"
          to="/secure/gcv/data-generation"
        />

        <ListItemLink
          active={activeLink === 'access'}
          dataCy="access-nav-link"
          icon={<Icon type="insights" />}
          onClick={() => setActiveLink('access')}
          text="Access"
          to="/secure/gcv/access"
        />
      </List>
    </Drawer>
  );
};
