import { Box, useTheme, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { Text } from 'ui/atoms';
import { ReactComponent as CheckIcon } from 'assets/images/check-circle-filled-icon.svg';
import palette from 'ui/theme/palette';

interface Props {
  type: 'warning' | 'default' | 'info';
  title: string | JSX.Element;
  subtitle?: string; // Only available if title is a string.
  status?: string | React.ReactNode;
  action?: React.ReactNode; // CTA button
  hideCopyOnMobile?: boolean; // Hide title and subtitle on mobile
}

export const NoticeBar: React.FC<Props> = ({ type, title, subtitle, status, action, hideCopyOnMobile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const hideCopy = hideCopyOnMobile && isMobile;

  const backgroundColor = useMemo(() => {
    switch (type) {
      case 'warning':
        return palette.warning.light;
      case 'info':
        return palette.info.notice;
      default:
        return palette.background.secondary;
    }
  }, [type]);

  return (
    <Box
      data-cy="noticebar-container"
      sx={{
        padding: { xs: '8px 16px', md: '8px 35px' },
        height: '57px',
        background: backgroundColor,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      {!hideCopy && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '5px',
            width: '100%',
            maxWidth: '605px'
          }}
        >
          {typeof title === 'string' ? (
            <Text content={title} dataCy="dialog-title" sx={{ fontWeight: 'bold', width: 'max-content' }} />
          ) : (
            title
          )}
          {subtitle && typeof title === 'string' && !isMobile && (
            <Text content={subtitle} dataCy="dialog-subtitle" />
          )}
        </Box>
      )}
      {(status || action) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: { xs: '100%', md: 'auto' }
          }}
        >
          {typeof status === 'string' ? (
            <>
              <CheckIcon width="20px" height="20px" />
              <Text content={status} sx={{ color: palette.primary.main, marginLeft: '4px' }} />
            </>
          ) : (
            <Box display="flex" alignItems="center">
              {status}
            </Box>
          )}
          {action && <Box sx={{ marginLeft: '14px' }}>{action}</Box>}
        </Box>
      )}
    </Box>
  );
};
