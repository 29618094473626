import { USStateOptions } from 'domain/consts';
import * as React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Container, ContainerItem, Form, Header, InputSelect, InputText, InputZip } from 'ui';

interface Props extends Record<string, unknown> {
  form: UseFormReturn<FieldValues>;
  isMobile: boolean;
  onChange?: () => void;
}

export const UserHomeAddressForm: React.FC<Props> = ({ form, isMobile, onChange }) => {
  return (
    <Container padding="0" dataCy="home-address-card">
      <Container padding={isMobile ? '20px 0 8px 0' : '28px 0 8px 0'}>
        <Header mobileHeader={isMobile} content="Home Address" type="h2" />
      </Container>
      <Form>
        <Container padding="0" width="100%">
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="Address"
              name="address"
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="street-address"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputText
              label="City"
              name="city"
              rules={{
                required: { message: 'is required', value: true },
                pattern: { message: 'must be valid', value: /^[a-zA-Z ]+$/ }
              }}
              dataCy="city"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputSelect
              label="State"
              name="state"
              options={USStateOptions}
              rules={{ required: { message: 'is required', value: true } }}
              dataCy="state"
              additionalOnChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
          <ContainerItem
            padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
            width="100%"
            maxWidth={isMobile ? 'none' : '20%'}
            minWidth={isMobile ? 'none' : '200px'}
          >
            <InputZip
              name="zipcode"
              label="Zip Code"
              rules={{
                required: { message: 'is required', value: true }
              }}
              dataCy="zip-code"
              onChange={onChange}
              onBlur={onChange}
              {...form}
            />
          </ContainerItem>
        </Container>
      </Form>
    </Container>
  );
};
