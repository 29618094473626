export enum MyProvidersStatus {
  // App received
  NotStarted = 'Not Started',
  // App started
  Started = 'Started',
  // App submitted
  Submitted = 'Submitted',
  // App under review
  UnderReview = 'Under Review',
  // App approved and account active
  Active = 'Active',
  // Account archived or misc
  Inactive = 'Inactive'
}
