import { BankOverview, Deposit } from '@gcv/shared';
import { BanksApi } from 'api/BanksApi';
import { DateTime } from 'luxon';
import { DepositTableExtended } from 'ui/apps/fi/deposits/deposits.repo';

export class BanksApiMock extends BanksApi {
  async getBankOverview(bankId: string, startDate: string, endDate: string): Promise<BankOverview> {
    return {
      deposits: {
        '358b116f-d1d1-4c1e-ae65-d8e8fd1f0914': {
          reconciled: 0,
          pending: 70000,
          under_review: 0,
          total: 70000
        },
        '21cfe821-34c7-4e7b-afd9-86ed62cc730c': {
          reconciled: 0,
          pending: 30000,
          under_review: 0,
          total: 30000
        }
      },
      sales: {
        'd6d2e359-5688-4ef2-94c3-b713d49f14f8': {
          verified_total: 0,
          unverified_total: 0,
          uncheckable_total: 0,
          total: 0,
          dispensary_name: 'Test Custom Fields'
        },
        '86c98296-00f3-4d2c-8bf2-7240a1540502': {
          verified_total: 580000,
          unverified_total: 3075,
          uncheckable_total: 154772,
          total: 738184,
          dispensary_name: 'Test Onboarding'
        },
        '358b116f-d1d1-4c1e-ae65-d8e8fd1f0914': {
          verified_total: 0,
          unverified_total: 0,
          uncheckable_total: 0,
          total: 0,
          dispensary_name: 'Test Dispensary'
        }
      }
    };
  }

  async getDailySummaries(bankId: string, startDate: string, endDate: string): Promise<any> {
    return [
      {
        dispensary_name: 'Test Dispensary',
        dispensary_id: '21cfe821-34c7-4e7b-afd9-86ed62cc730c',
        date: '2022-01-01',
        verified_percent: 100,
        total_sales: 51863,
        total_cash: 51863,
        total_debit: 0,
        total_ach: 0,
        total_wire: 0
      },
      {
        dispensary_name: 'Test Dispensary',
        dispensary_id: '21cfe821-34c7-4e7b-afd9-86ed62cc730c',
        date: '2022-01-01',
        verified_percent: 100,
        total_sales: 51863,
        total_cash: 51863,
        total_debit: 0,
        total_ach: 0,
        total_wire: 0
      },
      {
        dispensary_name: 'Test Dispensary',
        dispensary_id: '21cfe821-34c7-4e7b-afd9-86ed62cc730c',
        date: '2022-01-01',
        verified_percent: 100,
        total_sales: 51863,
        total_cash: 51863,
        total_debit: 0,
        total_ach: 0,
        total_wire: 0
      }
    ];
  }

  async editDepositStatus(bankId: string, deposit: Deposit, status: string, date: string): Promise<Deposit> {
    return Promise.resolve({ ...deposit, status: status, date_updated: date } as Deposit);
  }

  async getDepositById(bankId: string, depositId: string): Promise<Deposit> {
    return Promise.resolve({ deposit_id: depositId } as Deposit);
  }

  async getDepositsTable(
    bankId: string,
    startDate: string,
    endDate: string,
    filterType?: string | undefined
  ): Promise<DepositTableExtended[]> {
    const date = DateTime.utc().toISO();

    return [
      {
        arrived_date: date,
        bank_id: '1234',
        created_by: '1234',
        date_created: date,
        date_updated: date,
        delMethod: {
          name: 'Test Delivery Method',
          active: true,
          id: '1234',
          type: 'bank',
          favorite: true
        },
        deposit_date: date,
        deposit_id: '1234',
        dispensary_id: '1234',
        dispensary_name: 'Test Dispensary',
        expected_arrival_date: date,
        final_deposit: 100,
        status: 'pending'
      }
    ];
  }
}
