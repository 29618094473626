import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Container, ContainerItem } from 'ui/atoms';
import { Card } from 'ui/organisms';

interface Props {
  icon?: string | React.ReactNode;
  title?: string;
  width?: string;
}

export const ZeroState: React.FC<Props> = (props) => {
  const { icon, title } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container align="center" justify="center" width="100%" padding={'26px 0 0 0'}>
      <ContainerItem width={props.width ?? '100%'} padding={'0'}>
        <Card noPadding>
          <Container column align="center" justify="center" width="100%" padding={'23px'}>
            <ContainerItem>{typeof icon === 'string' ? <img src={icon} /> : icon}</ContainerItem>

            <ContainerItem textAlign="center" padding="10px 8px 8px 8px">
              <div
                style={{
                  font: 'lato',
                  fontSize: '16px',
                  lineHeight: '24px',
                  display: 'flex',
                  fontWeight: 700,
                  justifyContent: 'center'
                }}
              >
                {title}
              </div>
            </ContainerItem>
          </Container>
        </Card>
      </ContainerItem>
    </Container>
  );
};
