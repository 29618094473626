import { GreenCheckAwsEnvironmentName } from '@gcv/shared';
import { environment } from 'environments/environment';

const devKey = 'pk_83e6780accc0474dbd49ac88c506d9ab';
const prodKey = 'pk_f0e980c0e1f74c4182e383f3b6f5557e';

export const publishableKey = environment.env === GreenCheckAwsEnvironmentName.PROD ? prodKey : devKey;
export const cssOverride = `
    .flatfile_iframe-wrapper { z-index: 1201 !important; }
    .flatfile-close-button { margin: 8px !important; }
    .flatfile_displayAsModal { overflow-y: scroll !important; }
`;
