import {
  ApproveDispensaryReviewRequest,
  BankTemplateMetadataResponse,
  CrbTemplateOnboardingResponse,
  CRBTemplates,
  CreateDocumentsRequest,
  CustomRequirement,
  CustomRequirementResult,
  CustomSection,
  DueDiligenceDocumentStatus,
  DueDiligenceRequirementStatus,
  LicenseDocumentDataRequest,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementAddRequest,
  OnboardingDocumentRequirementResult,
  RequirementId,
  RequirementReference,
  TemplateMetadata,
  TemplateResponse,
  TemplateResultResponse,
  TemplateUsageResponse
} from '@gcv/shared';
import { injectable } from 'inversify';
import { FieldValues } from 'react-hook-form';
import { api, banksApi } from './api-util/api';
@injectable()
export class TemplateApi {
  async newTemplate(bankId: string, data: FieldValues): Promise<TemplateMetadata> {
    return await banksApi().post(`/banks/${bankId}/onboarding-template`, {
      name: data.name,
      description: data.description,
      plant_touching: data.plant_touching,
      attach_beneficial_ownership_questionnaire: !!data.attach_beneficial_ownership_questionnaire
    });
  }

  async getBankTemplates(id: string): Promise<BankTemplateMetadataResponse> {
    return await banksApi().get(`/banks/${id}/templates`);
  }

  async getBankTemplate(bankId: string, templateId: string, version?: string): Promise<TemplateResponse> {
    return await banksApi().get(`/banks/${bankId}/onboarding-template/${templateId}`, { version });
  }

  async updateBankTemplate(bankId: string, templateId: string, data: FieldValues): Promise<TemplateResponse> {
    return await banksApi().put(`/banks/${bankId}/onboarding-template/${templateId}`, {
      name: data.name,
      description: data.description,
      attach_beneficial_ownership_questionnaire: !!data.attach_beneficial_ownership_questionnaire
    });
  }

  async publishBankTemplate(bankId: string, templateId: string): Promise<TemplateResponse> {
    return await banksApi().put(`/banks/${bankId}/onboarding-template/${templateId}/publish`, {});
  }

  async getCrbsForTemplate(bankId: string, templateId: string): Promise<TemplateUsageResponse> {
    return await banksApi().get(`/banks/${bankId}/onboarding-template/${templateId}/crbs`);
  }

  async setTemplateForCrb(bankId: string, templateId: string, crbId: string): Promise<CRBTemplates> {
    return await banksApi().post(`/banks/${bankId}/onboarding-template/${templateId}/crb/${crbId}`, {});
  }

  async addCustomFieldSection(
    bankId: string,
    templateId: string,
    section: CustomSection
  ): Promise<CustomRequirement> {
    return await banksApi().post(`/banks/${bankId}/onboarding-template/${templateId}/custom-fields`, section);
  }

  async editCustomFieldSection(
    bankId: string,
    templateId: string,
    section: CustomSection
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${templateId}/requirement/${section.id}/custom-fields`,
      section
    );
  }

  async archiveCustomFieldSections(
    bankId: string,
    templateId: string,
    sections: CustomSection[]
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${templateId}/archive-custom-requirements`,
      {
        requirementIds: sections.map((s) => s.id)
      }
    );
  }

  async reorderSections(bankId: string, templateId: string, sectionIds: string[]): Promise<TemplateResponse> {
    return await banksApi().put(`/banks/${bankId}/onboarding-template/${templateId}/custom-fields/reorder`, {
      requirement_ids: sectionIds
    });
  }

  async createTemplateRequirement(
    bankId: string,
    templateId: string,
    data: OnboardingDocumentRequirementAddRequest
  ): Promise<OnboardingDocumentRequirement & RequirementReference> {
    return await banksApi().post(
      `/banks/${bankId}/onboarding-template/${templateId}/document-requirements`,
      data
    );
  }

  async updateTemplateRequirement(
    bankId: string,
    templateId: string,
    requirementId: string,
    data: OnboardingDocumentRequirementAddRequest
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${templateId}/requirement/${requirementId}/document-requirements`,
      data
    );
  }

  async archiveDocumentRequirements(
    bankId: string,
    templateId: string,
    requirementIds: string[]
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${templateId}/archive-document-requirements`,
      { requirementIds }
    );
  }

  async unarchiveDocumentRequirements(
    bankId: string,
    templateId: string,
    requirementIds: string[]
  ): Promise<TemplateResponse> {
    return await banksApi().put(
      `/banks/${bankId}/onboarding-template/${templateId}/unarchive-document-requirements`,
      { requirementIds }
    );
  }

  async getTemplatesForCrb(crbId: string): Promise<CrbTemplateOnboardingResponse[]> {
    return await api().get(`/crb/${crbId}/onboarding-templates`);
  }

  async getCrbTemplateResult(
    crbId: string,
    templateId: string,
    templateResultId: string
  ): Promise<TemplateResultResponse> {
    return await api().get(`/crb/${crbId}/onboarding-templates/${templateId}/results/${templateResultId}`);
  }

  async updateCrbTemplateRequirement(
    crbId: string,
    fiId: string,
    templateId: string,
    requirementId: string,
    customFields: Record<string, any>
  ): Promise<CustomRequirementResult> {
    return await api().put(
      `/crb/${crbId}/fi/${fiId}/onboarding-templates/${templateId}/requirement/${requirementId}/custom-fields`,
      customFields
    );
  }

  async updateBankCrbTemplateRequirement(
    crbId: string,
    fiId: string,
    templateId: string,
    requirementId: string,
    customFields: Record<string, any>
  ): Promise<CustomRequirementResult> {
    return await banksApi().put(
      `/banks/${fiId}/crb/${crbId}/onboarding-templates/${templateId}/requirement/${requirementId}/custom-fields`,
      customFields
    );
  }

  async bankFinishedUpdatingCustomFields(
    fiId: string,
    crbId: string,
    templateId: string,
    customRequirementResults: CustomRequirementResult[]
  ): Promise<CustomRequirementResult> {
    return await banksApi().put(
      `/banks/${fiId}/crb/${crbId}/onboarding-templates/${templateId}/custom-fields/completed`,
      { custom_requirement_results: customRequirementResults }
    );
  }

  async crbFinishedUpdatingCustomFields(
    fiId: string,
    crbId: string,
    templateId: string,
    customRequirementResults: CustomRequirementResult[]
  ): Promise<CustomRequirementResult> {
    return await api().put(
      `/crb/${crbId}/fi/${fiId}/onboarding-templates/${templateId}/custom-fields/completed`,
      { custom_requirement_results: customRequirementResults }
    );
  }

  async completeCrbTemplate(
    crbId: string,
    fiId: string,
    templateId: string
  ): Promise<TemplateResultResponse> {
    return await api().put(
      `/crb/${crbId}/fi/${fiId}/onboarding-templates/${templateId}/custom-fields/complete`,
      {}
    );
  }

  // updates the status of an onboarding template from *_pending to *_in_progress
  async startCrbOnboardingTemplate(crbId: string, templateId: string): Promise<TemplateResultResponse> {
    return await api().post(`/crb/${crbId}/onboarding-templates/${templateId}/start`, {});
  }

  // updates the status of an onboarding template from *_in_progress to *_awaiting_review
  async completeCrbOnboardingTemplate(crbId: string, templateId: string): Promise<TemplateResultResponse> {
    return await api().post(`/crb/${crbId}/onboarding-templates/${templateId}/complete`, {});
  }

  // marks a requirement as "do not have"
  async setOnboardingDocumentRequirementDoNotHave(
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    doNotHave: boolean,
    doNotHaveComments: string
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().post(
      `/crb/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/do-not-have`,
      { do_not_have: doNotHave, do_not_have_comments: doNotHaveComments }
    );
  }

  // adds documents to a requirement result (for CRB use)
  async addOnboardingDocumentRequirementDocumentsCrb(
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentsToAdd: CreateDocumentsRequest
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().post(
      `/crb/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents`,
      documentsToAdd
    );
  }

  async addOnboardingDocumentRequirementDocumentsCrbV2(
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentsToAdd: CreateDocumentsRequest
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().post(
      `/crb/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents-v2`,
      documentsToAdd
    );
  }

  // adds documents to a requirement result (for FI use)
  async addOnboardingDocumentRequirementDocumentsFi(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentsToAdd: CreateDocumentsRequest
  ): Promise<OnboardingDocumentRequirementResult> {
    return await banksApi().post(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents`,
      documentsToAdd
    );
  }

  async addOnboardingDocumentRequirementDocumentsFiV2(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentsToAdd: CreateDocumentsRequest
  ): Promise<OnboardingDocumentRequirementResult> {
    return await banksApi().post(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents-v2`,
      documentsToAdd
    );
  }

  // removes a single document from a requirement result
  async removeDocumentFromOnboardingDocumentRequirement(
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentId: string
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().delete(
      `/crb/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents/${documentId}`
    );
  }

  // updates a single document from a requirement result
  async updateLicenseDocument(
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentId: string,
    licenseData?: LicenseDocumentDataRequest
  ): Promise<OnboardingDocumentRequirementResult> {
    return await api().put(
      `/crb/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents/${documentId}`,
      licenseData
    );
  }

  async updateTemplateReviewSections(
    bankId: string,
    dispensaryId: string,
    templateId: string,
    data: {
      operation_details?: boolean;
      account_owners?: boolean;
      due_diligence_documentation?: boolean;
      business_details?: boolean;
      additional_information?: boolean;
    }
  ): Promise<TemplateResultResponse> {
    return await banksApi().put(
      `/banks/${bankId}/crbs/${dispensaryId}/onboarding-templates/${templateId}/sections`,
      data
    );
  }

  async reviewTemplateResult(
    bankId: string,
    dispensaryId: string,
    templateId: string,
    approveRequest?: ApproveDispensaryReviewRequest
  ): Promise<TemplateResultResponse> {
    return await banksApi().post(
      `/banks/${bankId}/crbs/${dispensaryId}/onboarding-templates/${templateId}/review`,
      approveRequest
    );
  }

  // request file upload on a document
  async requestFileUploadOnDocumentRequirement(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    comment: string
  ): Promise<void> {
    return await banksApi().put(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/request-upload`,
      { comment }
    );
  }

  // set a requirement status DueDiligenceRequirementStatus
  async updateOnboardingDocumentRequirementStatus(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    status: DueDiligenceRequirementStatus
  ): Promise<OnboardingDocumentRequirementResult> {
    return await banksApi().put(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/status`,
      { status }
    );
  }

  // set document status DueDiligenceDocumentStatus
  async updateOnboardingDocumentRequirementDocumentStatus(
    bankId: string,
    crbId: string,
    templateId: string,
    requirementId: RequirementId,
    documentId: string,
    status: DueDiligenceDocumentStatus,
    note?: string
  ): Promise<OnboardingDocumentRequirementResult> {
    return await banksApi().put(
      `/banks/${bankId}/crbs/${crbId}/onboarding-templates/${templateId}/requirements/${requirementId}/documents/${documentId}/status`,
      { status, note }
    );
  }
}
