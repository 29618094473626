import { Box } from '@mui/material';
import * as React from 'react';

interface Props {
  align?: 'flex-start' | 'center' | 'flex-end';
  column?: boolean;
  dataCy?: string;
  flex?: boolean;
  grow?: boolean;
  height?: string;
  id?: string;
  justify?: 'left' | 'center' | 'right' | 'between';
  textAlign?: 'left' | 'center' | 'right';
  margin?: number | string;
  maxWidth?: string;
  minWidth?: string;
  padding?: number | string;
  width?: string | { xs?: string; sm?: string; md?: string; lg?: string };
  flexWrap?: 'wrap' | 'nowrap' | 'unset';
  overflow?: 'visible' | 'hidden' | 'auto';
  children?: React.ReactNode;
}

export const ContainerItem: React.FC<Props> = (props) => {
  const align = props.align ? props.align : 'flex-start';
  const flex = props.flex ? 'flex' : 'block';
  const flexWrap = props.flexWrap ? props.flexWrap : 'unset';
  const grow = props.grow || props.minWidth ? 1 : 0;
  const height = props.height ? props.height : 'auto';
  const justify = props.justify ? props.justify : 'left';
  const margin = props.margin !== undefined ? props.margin : 0;
  const maxWidth = props.maxWidth ? props.maxWidth : 'auto';
  const minWidth = props.minWidth ? props.minWidth : 'auto';
  const padding = props.padding !== undefined ? props.padding : 1;
  const textAlign = props.textAlign ? props.textAlign : justify;
  const width = props.width ? props.width : props.minWidth ? '100%' : 'fit-content';
  const overflow = props.overflow ? props.overflow : 'visible';

  const flexBasis = props.column ? height : minWidth;

  return (
    <Box
      data-cy={props.dataCy}
      id={props.id}
      sx={{
        alignItems: align,
        flexWrap: flexWrap,
        display: flex,
        flexBasis: flexBasis,
        flexGrow: grow,
        height: height,
        justifyContent:
          justify === 'left'
            ? 'flex-start'
            : justify === 'right'
            ? 'flex-end'
            : justify === 'between'
            ? 'space-between'
            : 'center',
        m: margin,
        maxWidth: maxWidth,
        minWidth: minWidth,
        p: padding,
        textAlign: textAlign,
        width: width,
        overflow: overflow
      }}
    >
      {props.children}
    </Box>
  );
};
