import { IANATimezones, QuestionnaireStatus, TaskStatus } from '@gcv/shared';
import React from 'react';
import { DateTimeHelpers } from 'util/dateTime.util';
import { Container } from '../Container/container.atom';
import { Box } from '@mui/material';
import { Text } from '../Text/text.atom';

interface Props {
  dueDate: string;
  status: QuestionnaireStatus | TaskStatus;
  timeZone: IANATimezones;
}

export const DueDate: React.FC<Props> = (props) => {
  if (!props.dueDate || props.dueDate === '--') return <>--</>;

  const diff = Math.ceil(
    DateTimeHelpers.parseFromISOString(props.dueDate, props.timeZone).diffNow('days').days
  );
  const isDueSoonOrOverdue =
    props.status === QuestionnaireStatus.Completed || props.status === TaskStatus.COMPLETE
      ? false
      : diff <= 2;

  return (
    <Container padding="0">
      <Box
        className="material-icons"
        sx={{
          fontSize: '14px',
          paddingTop: '2px',
          mr: '0.5rem',
          color: (theme) => (isDueSoonOrOverdue ? theme.palette.error.main : theme.palette.text.primary)
        }}
      >
        {isDueSoonOrOverdue ? 'schedule' : 'calendar_today'}
      </Box>
      <Text
        type="body3"
        content={DateTimeHelpers.formatISOToTableDateString(props.dueDate, props.timeZone)}
        sx={{
          color: (theme) => (isDueSoonOrOverdue ? theme.palette.error.main : theme.palette.text.primary)
        }}
      />
    </Container>
  );
};
