import { TableRow as MaterialTableRow } from '@mui/material';
import { RadioButton, TableCell } from 'ui/atoms';
import { Column, Row } from 'ui/organisms';
import { TableCheckboxCell } from '../TableCheckboxCell/table-checkbox-cell.molecule';
import { TableCell as MaterialTableCell } from '@mui/material';

interface Props<T> {
  isRowSelected: boolean;
  selectable: boolean;
  clickable: boolean;
  row: Row<T>;
  columns: Column<T>[];
  handleCheckboxSelect: (event: React.MouseEvent<unknown>, id: string) => void;
  handleCellClick: (event: React.MouseEvent<unknown>, id: string, data: T) => void;
  handleRadioSelect: (value: string) => void;
  isLastItem: boolean;
  singleSelectable: boolean;
  selected: boolean;
}

export const TableRow = <T,>(props: Props<T>) => {
  return (
    <MaterialTableRow
      hover={props.clickable && !props.row.disableSelect && !props.row.disabled}
      role="row"
      aria-checked={props.isRowSelected}
      tabIndex={-1}
      key={props.row.id}
      selected={props.isRowSelected && !props.row.disableSelect && !props.row.disabled}
      data-cy="table-row"
      sx={{
        borderBottom: props.row.heavyBottomBorder ? '2px solid #C6CCDA' : 'unset',
        backgroundColor: props.row.heavyBottomBorder || props.row.highlightBackground ? '#F9FAFD' : 'unset',
        borderTop: props.row.heavyTopBorder ? '2px solid #c6ccda' : 'unset',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: 'primary.screen',
          transition: 'background-color 0.15s ease-in-out',
          cursor: 'pointer'
        },
        '& .MuiTypography-root, & .MuiTableCell-body': {
          color: props.row.disabled ? 'text.disabled' : 'auto',
          // note: auto is not a valid value for fill but intentionally is used to
          // invalidate the fill property so the color in the child path is used
          '& svg path': {
            fill: props.row.disabled ? '#03a55a54' : 'auto'
          }
        }
      }}
    >
      {props.selectable && (
        <TableCheckboxCell
          isItemSelected={props.isRowSelected}
          row={props.row}
          handleSelect={props.handleCheckboxSelect}
          disabled={props.row.disableSelect}
          tooltip={props.row.selectTooltip}
          isLastItem={props.isLastItem}
        />
      )}

      {props.singleSelectable && (
        <MaterialTableCell
          padding="none"
          sx={{
            textAlign: 'center',
            borderBottom: (theme) => (props.isLastItem ? 'none' : `0.016rem solid ${theme.palette.divider}`),
            p: 0,
            width: '35px'
          }}
        >
          <RadioButton
            checked={props.selected && !props.row.disableSelect}
            disabled={props.row.disableSelect}
            handleSelect={(value) => {
              if (!props.row.disableSelect) {
                props.handleRadioSelect(value);
              }
            }}
            label=""
            name="tableSingleSelect"
            value={props.row.id}
          />
        </MaterialTableCell>
      )}

      {Object.values(props.columns).map((column, index) => {
        return (
          <TableCell
            key={`${column.id}-${index}`}
            row={props.row}
            column={column}
            handleClick={(event: React.MouseEvent<unknown>, id: string, data: T) => {
              if (!props.row.disabled) {
                props.handleCellClick(event, id, data);

                if (props.handleRadioSelect) {
                  props.handleRadioSelect(props.row.id);
                }
              }
            }}
            index={index}
            isLastItem={props.isLastItem}
          />
        );
      })}
    </MaterialTableRow>
  );
};
