import { useAuth0 } from '@auth0/auth0-react';
import { useRollbar } from '@rollbar/react';
import ApiAuth0Session, { GCApplicationAccess } from 'api/api-util/api-auth0-session';
import { environment } from 'environments/environment';
import { useComponent } from 'hooks/useComponent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { getUserStore } from 'stores/UserStore';
import { logRocketConfig } from 'third-party-integrations/log-rocket';
import { Spinner } from 'ui';
import { initializeLoggedInUser } from 'ui/routing/router-util';
import { getStoredUsername } from 'util/auth0-storage.util';

/**
 * Return the redirect URI only if it validates against the whitelist.
 *
 * Note: The optional arg 2 allows for testing.
 *
 * See: https://regex101.com/
 *
 * @param uri the redirect URI.
 * @returns the redirect URI as a string if valid; otherwise, undefined.
 */
export const validateRedirectUri = (uri: string, isProduction = environment.production) => {
  const candidate = new URL(uri);

  if (isProduction) {
    // We are whitelisting only the payqwick.com domain (and sub-domains) for production.
    return !!candidate.host.match(/^(.*?\.)?(payqwick|greencheckdirect)\.com$/)
      ? candidate.toString()
      : undefined;
  } else {
    // We include the production URI and add localhost on ports 3010 & 4200 to the whitelist for development.
    return !!candidate.host.match(
      /^((.*?\.)?(payqwick|greencheckdirect)\.com)|((.*?\.)?localhost:((3010)|(4200)))$/
    )
      ? candidate.toString()
      : undefined;
  }
};

/* Component */

const Auth0Login: React.FC = useComponent(() => {
  const { isAuthenticated, isLoading, loginWithRedirect, error: auth0LoginError } = useAuth0();

  const userStore = getUserStore();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  const login = async () => {
    const options = {} as { [key: string]: any };
    const username = getStoredUsername(true);

    if (username) {
      options['login_hint'] = username;
    }

    return loginWithRedirect(options);
  };

  if (isLoading) {
    // Shows while authentication is loading.
    return <Spinner />;
  } else if (auth0LoginError) {
    // If we don't handle an Auth0 error here, we could wind up in an infinite loop.
    rollbar.error('Error logging in', auth0LoginError, { additional: logRocketConfig.session_url });
    getSnackbarStore().showErrorSnackbarMessage(
      'There was an error authenticating. Please contact the help desk!'
    );
    navigate('/error');
  } else if (!isAuthenticated) {
    login();
  } else if (
    isAuthenticated &&
    userStore.isLoaded &&
    ApiAuth0Session.selectedApplication !== GCApplicationAccess.PQ &&
    ApiAuth0Session.selectedApplication !== GCApplicationAccess.GCD
  ) {
    initializeLoggedInUser(navigate, userStore, userStore.setIsLoading);
  }

  return <Spinner />;
});

export default Auth0Login;
