import { Deposit } from '@gcv/shared';
import { injectable } from 'inversify';
import { api, banksApi } from './api-util/api';

@injectable()
export class DepositsApi {
  async getDeposit(depositId: string): Promise<Deposit> {
    return await api().get(`/gcv/deposits/${depositId}`);
  }

  async saveDeposit(deposit: Deposit): Promise<Deposit> {
    return await api().put(`/gcv/deposits/${deposit.deposit_id}`, deposit);
  }

  async deleteDeposit(depositId: string) {
    return await api().delete(`/gcv/deposits/${depositId}`);
  }

  async deleteDepositFi(bankId: string, depositId: string) {
    return await banksApi().delete(`/banks/${bankId}/deposits/${depositId}/delete`);
  }
}
