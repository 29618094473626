import {
  CreateDocumentsRequest,
  OnboardingDocumentRequirement,
  OnboardingDocumentRequirementResult
} from '@gcv/shared';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as CirclePlus } from 'assets/images/ic-circle-plus.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Button, Container, ContainerItem, InputCheckBox } from 'ui';
import { OnboardingPresenter } from 'ui/apps/crb/onboarding-two/onboarding.presenter';
import { UploadDocumentModalV2 } from 'ui/organisms/UploadDocumentsModal/UploadDocumentModalV2';
import { theme } from 'ui/theme';
import { AddCommentModal } from './add-comment-modal';

export const UploadLicenseButton: React.FC<{
  requirement: OnboardingDocumentRequirement;
  result?: OnboardingDocumentRequirementResult;
}> = useComponent((props) => {
  const presenter = useInjection(OnboardingPresenter);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const form = useForm({ defaultValues: { doNotHave: false } as FieldValues });

  const [fileUploadOpen, setFileUploadOpen] = React.useState(false);
  const [addCommentModalOpen, setAddCommentModalOpen] = React.useState(false);
  const filesUploaded = props.result?.documents && Object.keys(props.result?.documents).length > 0;

  return (
    <Container align="center" justify="center" padding={0} nowrap={!isTablet}>
      <ContainerItem minWidth="125px" justify={filesUploaded || isTablet ? 'center' : 'right'}>
        <Button
          label={filesUploaded ? 'Add ' : 'Upload License'}
          color={filesUploaded ? 'primary-text' : 'primary'}
          startIcon={filesUploaded && <CirclePlus />}
          onClick={() => setFileUploadOpen(true)}
          dataCy="upload-license-button"
        />
      </ContainerItem>
      {props.requirement.requirement_id === 'business_licenses' &&
        !presenter.viewModel.dispenaryIsPlantTouching &&
        (!props.result || (props.result && Object.keys(props.result.documents).length === 0)) && (
          <ContainerItem padding={0}>
            <InputCheckBox
              {...form}
              checked={false}
              label="I don’t have this"
              name="doNotHave"
              onChange={(e, checked) => {
                if (checked) {
                  setAddCommentModalOpen(true);
                }
              }}
              justifyContent="center"
              dataCy="dont-have-checkbox"
            />
          </ContainerItem>
        )}
      <AddCommentModal
        requirement={props.requirement}
        result={props.result}
        title="Add Explanation"
        addCommentModalOpen={addCommentModalOpen}
        onClose={() => {
          setAddCommentModalOpen(false);
          form.setValue('doNotHave', false);
        }}
      />
      <UploadDocumentModalV2
        dispensary={presenter.crbDispensaryStore.currentDispensary}
        companyType={'dispensary'}
        addDocumentsToRequirement={async (documentsRequest: CreateDocumentsRequest) => {
          presenter.addLicenseDocument(props.requirement, documentsRequest);
        }}
        openDocumentNewWindow={presenter.openDocument}
        requirement={props.requirement}
        documents={[]}
        userMap={presenter.viewModel.dispensaryStaff}
        modalOpen={fileUploadOpen}
        toggleModal={setFileUploadOpen}
        existingFiles={[]}
        sharedRootDocs={presenter.viewModel.sharedRootDocs}
        templateResults={presenter.viewModel.templateResults}
        allDocumentsMap={presenter.viewModel.allDocumentsMap}
      />
    </Container>
  );
});
