import { CustomField, CustomSection } from '@gcv/shared';
import { ReactComponent as EditIcon } from 'assets/images/ic-edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/ic-trash.svg';
import { ReactComponent as MoveControl } from 'assets/images/move-control.svg';
import { ReactComponent as RulesIcon } from 'assets/images/ic-rules.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/ic-duplicate.svg';
import { Box } from '@mui/material';
import * as React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Card, Container, ContainerItem, Drag, Drop, Hyperlink, Text, Title, Tooltip } from 'ui';
import { FieldDisplay } from '.';

interface Props {
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
  openFieldModal: (isOpen: boolean, fieldId?: string, sectionId?: string) => void;
  openFieldDeleteModal: (isOpen: boolean, fieldId: string) => void;
  openSectionModal: (isOpen: boolean, sectionId?: string) => void;
  openSectionDeleteModal: (isOpen: boolean, sectionId: string) => void;
  openSmartRuleModal: (field: CustomField) => void;
  openSmartRuleModalForSection: (section: CustomSection) => void;
  section: CustomSection;
  viewOnly?: boolean;
  viewOnlyTooltip?: string;
  duplicateSection?: (type: 'field' | 'section', data: CustomField | CustomSection) => void;
  duplicateItemIds: string[];
}

export const SectionDisplay: React.FC<Props> = (props) => {
  const animation = props.duplicateItemIds.includes(props.section.id) ? '3s linear SMART_RULE_ANIM' : null;

  return (
    <Card
      header={{
        align: 'center',
        title: (
          <Container padding={0} nowrap>
            {!props.viewOnly && (
              <ContainerItem padding={0}>
                <div {...props.dragHandleProps}>
                  <MoveControl
                    style={{ cursor: 'grab', marginRight: '1rem' }}
                    data-cy="section-grab-control"
                  />
                </div>
              </ContainerItem>
            )}
            <ContainerItem padding={0}>
              {props.section.label.length > 50 ? (
                <Title
                  content={props.section.label.substring(0, 50) + '...'}
                  Tooltip={props.section.label}
                  justify="flex-start"
                  type={'h2'}
                  dataCy="section-title"
                />
              ) : (
                <Title
                  content={props.section.label}
                  justify="flex-start"
                  type={'h2'}
                  dataCy="section-title"
                />
              )}
              <Text
                sx={{ whiteSpace: 'pre-line' }}
                content={props.section.description ?? ''}
                type="body2"
                dataCy="section-description"
              />
            </ContainerItem>
          </Container>
        ),
        actions: !props.viewOnly ? (
          <Container padding={0} nowrap>
            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Smart Rules">
                <Box
                  sx={{
                    '&:hover path': { fill: props.section.smart_rule ? '#03A65B' : '#3A3C45' },
                    path: { fill: props.section.smart_rule ? '#03A65B' : '#C6CCDA' }
                  }}
                >
                  <RulesIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => {
                      props.openSmartRuleModalForSection(props.section);
                    }}
                    data-cy="edit-field-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Duplicate">
                <Box
                  sx={{
                    '&:hover path': { fill: '#3A3C45' }
                  }}
                >
                  <DuplicateIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => {
                      if (props.duplicateSection) {
                        props.duplicateSection('section', props.section);
                      }
                    }}
                    data-cy="duplicate-section-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding="0 0.5rem">
              <Tooltip placement="bottom" title="Edit">
                <Box
                  sx={{
                    '&:hover path': { fill: '#3A3C45' }
                  }}
                >
                  <EditIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => props.openSectionModal(true, props.section.id)}
                    data-cy="edit-section-icon"
                  />
                </Box>
              </Tooltip>
            </ContainerItem>

            <ContainerItem padding="0">
              <Box
                sx={{
                  '&:hover path': { fill: '#3A3C45' }
                }}
              >
                <Tooltip placement="bottom" title="Delete">
                  <DeleteIcon
                    style={{ cursor: 'pointer', height: '24px' }}
                    onClick={() => props.openSectionDeleteModal(true, props.section.id)}
                    data-cy="delete-section-icon"
                  />
                </Tooltip>
              </Box>
            </ContainerItem>
          </Container>
        ) : null,
        nowrap: true
      }}
      dataCy={`section-display-${props.section.id}`}
      contentOverflow="visible"
      animation={animation}
    >
      {props.viewOnly ? (
        <>
          <Container column padding={0} width="100%" dataCy="section-fields">
            {props.section.fields?.map((field, index) => (
              <ContainerItem padding="6px 0" width="100%">
                <FieldDisplay
                  field={field}
                  openFieldModal={props.openFieldModal}
                  openFieldDeleteModal={props.openFieldDeleteModal}
                  dragHandleProps={undefined}
                  openSmartRuleModal={props.openSmartRuleModal}
                  viewOnly={props.viewOnly}
                  viewOnlyTooltip={props.viewOnlyTooltip}
                  duplicateField={props.duplicateSection}
                  duplicateItemIds={props.duplicateItemIds}
                />
              </ContainerItem>
            ))}
          </Container>
        </>
      ) : (
        <Container column padding={0} width="100%" dataCy="section-fields">
          <Drop id={props.section.id} type="FIELD">
            {props.section.fields?.map((field, index) => (
              <Drag key={field.id} id={field.id} index={index}>
                {(dragHandle) => (
                  <ContainerItem padding={0} width="100%">
                    <FieldDisplay
                      field={field}
                      openFieldModal={props.openFieldModal}
                      openFieldDeleteModal={props.openFieldDeleteModal}
                      dragHandleProps={dragHandle}
                      openSmartRuleModal={props.openSmartRuleModal}
                      viewOnly={props.viewOnly}
                      duplicateField={props.duplicateSection}
                      duplicateItemIds={props.duplicateItemIds}
                    />
                  </ContainerItem>
                )}
              </Drag>
            ))}
            <ContainerItem justify="center" width="100%">
              <Hyperlink
                label="+ Drag or Add New Field"
                color="green"
                onClick={() => props.openFieldModal(true, undefined, props.section.id)}
                dataCy="drag-or-add-new-field-button"
              />
            </ContainerItem>
          </Drop>
        </Container>
      )}
    </Card>
  );
};
