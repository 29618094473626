import { useMediaQuery, useTheme } from '@mui/material';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, ContainerItem, OnBoardingPage, OverlaySpinner } from 'ui';
import { AccountOwnersPresenter } from './account-owners-presenter';
import { AccountOwnerInformationCard } from './components/account-owner-information-card';
import { AccountOwnersTable } from './components/account-owners-table';
import { AddAccountOwnerModal } from './components/add-account-owner-modal';

interface Props extends Record<string, unknown> {
  onNext: () => void;
  onBack: () => void;
  stepNumber: number;
  totalSteps: number;
}

export const AccountOwners: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(AccountOwnersPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { bank_id } = useParams() as { bank_id: string };
  const subtitle = `Let's get started with ${presenter.viewModel.serviceProviderName}. We'll guide you through the application process.`;

  React.useEffect(() => {
    presenter.load(bank_id);
  }, []);

  return (
    <>
      {presenter.viewModel.isLoadingAccountOwner ? <OverlaySpinner /> : null}
      <OnBoardingPage
        alignBottomActions="center"
        bottomActions={
          <Button
            label="Continue"
            color="primary"
            onClick={() => {
              presenter.saveAccountOwners(props.onNext);
            }}
            isLoading={presenter.viewModel.isLoadingAccountOwner}
            dataCy="save-button"
          />
        }
        dataCy="account-owners-page"
        subtitle={subtitle}
        title={` Hello there, ${presenter.userStore.user.firstName}`}
        totalSteps={props.totalSteps}
        step={props.stepNumber}
        bgCard
      >
        <Container column={isMobile} padding={0}>
          <ContainerItem padding="0">
            <AccountOwnerInformationCard />
          </ContainerItem>

          {presenter.viewModel.invitedUsers.length ? (
            <ContainerItem flex column padding="12px 0 0 0" overflow="auto" width="100%">
              <AccountOwnersTable />
            </ContainerItem>
          ) : null}
        </Container>

        <AddAccountOwnerModal />
      </OnBoardingPage>
    </>
  );
});
