import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { Button, Container, ContainerItem } from 'ui/atoms';
import { OnboardingPresenter } from '../../../onboarding.presenter';

interface Props extends Record<string, unknown> {
  onBack: () => void;
  onNext: () => void;
}

export const DocumentationFooter: React.FC<Props> = useComponent(({ bank, onBack, onNext }) => {
  const presenter = useInjection(OnboardingPresenter);
  return (
    <Container justify="flex-end">
      {/* {bank && (
        <ContainerItem grow justify="center">
          Have questions about any of these documents? Contact {presenter.viewModel.provider.orgName} by
          clicking{' '}
          <Hyperlink
            label="here"
            url={`mailto:${presenter.viewModel.provider.primary_point_of_contact?.emailAddress}`}
          />
          .
        </ContainerItem>
      )} */}
      <ContainerItem>
        <Button color="default-outlined" label="Back" onClick={onBack} dataCy="back-button" />
      </ContainerItem>
      <ContainerItem>
        <Button
          label="Continue"
          color="primary"
          onClick={() => {
            presenter.submitDocumentation(onNext);
          }}
          isLoading={presenter.viewModel.isSavingDocumentation}
          dataCy="save-button"
        />
      </ContainerItem>
    </Container>
  );
});
