import {
  DocumentUpload,
  GCD_RIVERBANK_BANK_ID,
  GcdUserRole,
  IANATimezones,
  PAYQWICK_BANK_ID,
  PayqwickUserRole,
  User
} from '@gcv/shared';
import { USStateOptions, idtypes } from 'domain/consts';
import { SsnMask } from 'domain/consts/input-masks';
import { GcdRoleOptions, PayqwickRoleOptions } from 'domain/consts/roles';
import { environment } from 'environments/environment';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { getFiBankStore } from 'stores/FiBankStore';
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Error,
  FileContainer,
  Hyperlink,
  InlineSpinner,
  InputDate,
  InputEmail,
  InputMasked,
  InputPhone,
  InputSelect,
  InputText,
  InputZip,
  Label,
  Text
} from 'ui';

interface Props {
  title: string;
  form: UseFormReturn;
  isOpen: boolean;
  newUser?: boolean;
  userRoleOptions: { label: string; value: string }[];
  editableEmail?: boolean;
  onClose: () => void;
  cancelButton: {
    onClick: () => void;
    label: string;
    isLoading?: boolean;
    color: 'default-outlined' | 'warning';
  };
  completeButton: {
    onClick: () => void;
    label: string;
    isLoading?: boolean;
    color: 'primary' | 'primary-outlined' | 'warning';
  };
  error?: string;
  /**
   * Determines if we show the PQ/GCD user role options. FIs will pass in their own ID here, but the option wil lonly show if PQ or GCD
   * For CRBs, once they migrate from PQ to GCD, we will pass in GCD so we no longer show the PQ options
   */
  payqwickOrgId?: string;
  showRole?: boolean;
  showIdVerificationFields?: boolean;
  readonlyRole?: boolean;
  user?: User;
  timezone?: IANATimezones;
  idFrontDocument?: DocumentUpload | undefined;
  idBackDocument?: DocumentUpload | undefined;
  documentUpload?: DocumentUpload;
  removeDocumentFromUser?: (
    idSide: 'front' | 'back',
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  saveWithDocs?: (front: File[], back: File[]) => void;
}

export const EditUserModal: React.FC<Props> = (props) => {
  const [idType, setIdType] = React.useState<string>('');
  const [tempFrontId, setTempFrontId] = React.useState<File[]>([]);
  const [tempBackId, setTempBackId] = React.useState<File[]>([]);
  const [frontLoading, setFrontLoading] = React.useState<boolean>(false);
  const [backLoading, setBackLoading] = React.useState<boolean>(false);
  const [errorFrontMessage, setErrorFrontMessage] = React.useState<string>('');
  const [errorBackMessage, setErrorBackMessage] = React.useState<string>('');

  React.useEffect(() => {
    if (props.form.getValues('idType')) {
      setIdType(props.form.getValues('idType'));
    }
  }, [props.form.getValues('idType')]);

  return (
    <Dialog
      onCancel={props.cancelButton.onClick}
      action={
        <Button
          label={props.completeButton.label}
          color={props.completeButton.color}
          onClick={() => {
            if (props.saveWithDocs) {
              if (props.user?.identityVerified) {
                if (!props.idFrontDocument && tempFrontId.length === 0) {
                  setErrorFrontMessage('Please upload both ID documents');
                } else if (!props.idBackDocument && tempBackId.length === 0) {
                  setErrorBackMessage('Please upload both ID documents');
                } else {
                  setErrorFrontMessage('');
                  setErrorBackMessage('');
                  props.saveWithDocs(tempFrontId, tempBackId);
                }
              } else {
                props.saveWithDocs(tempFrontId, tempBackId);
              }
            } else {
              props.completeButton.onClick();
            }
          }}
          isLoading={props.completeButton.isLoading}
          dataCy="save-button"
        />
      }
      isOpen={props.isOpen}
      handleClose={props.onClose}
      title={props.title}
      alignTitle="center"
      dataCy="edit-user-modal"
      width="458px"
      errorMessage={props.error}
    >
      <Container width="100%" padding="0">
        <ContainerItem width="50%" padding="0 0.5rem 0 0">
          <InputText
            name="firstName"
            label="First Name"
            rules={{ required: { message: 'is required', value: true } }}
            dataCy="first-name"
            placeholder="Enter First Name"
            {...props.form}
          ></InputText>
        </ContainerItem>
        <ContainerItem width="50%" padding="0 0 0 0.5rem">
          <InputText
            name="lastName"
            label="Last Name"
            rules={{ required: { message: 'is required', value: true } }}
            dataCy="last-name"
            placeholder="Enter Last Name"
            {...props.form}
          ></InputText>
        </ContainerItem>

        <ContainerItem width="100%" padding="10px 0">
          <InputEmail
            readOnly={!props.editableEmail}
            name="email"
            label="Email Address"
            tooltip={
              props.editableEmail
                ? undefined
                : 'Email address can only be edited if the user has not yet set their password. Please contact support@greencheckverified.com for additional help.'
            }
            rules={{
              required: { message: 'is required', value: true }
            }}
            dataCy="email"
            {...props.form}
          ></InputEmail>
        </ContainerItem>
        {props.showRole ? (
          <>
            <ContainerItem width="100%" padding="10px 0">
              <InputSelect
                {...props.form}
                label="User Role"
                name="role"
                options={props.userRoleOptions}
                rules={{
                  required: { message: 'is required', value: true }
                }}
                readOnly={props.readonlyRole}
                dataCy="role"
                tooltip={
                  props.readonlyRole
                    ? 'User access cannot be edited once it has been set, as this impacts their access to different areas of the application. Please contact support@greencheckverified.com for additional help.'
                    : undefined
                }
              />
            </ContainerItem>
          </>
        ) : null}

        {props.user && props.user.identityVerified && props.timezone ? (
          <>
            <Container padding="0" width="100%">
              <ContainerItem padding="10px 12px 10px 0" width="100%" maxWidth="50%">
                <InputPhone
                  name="phone"
                  label="Phone"
                  rules={{
                    required: { message: 'is required', value: true }
                  }}
                  dataCy="phone"
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 0 10px 0" width="100%" maxWidth="50%">
                <InputDate
                  name="dateOfBirth"
                  label="Date of Birth"
                  timezone={props.timezone}
                  rules={{
                    required: { message: 'is required', value: true }
                  }}
                  dataCy="date-of-birth"
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 12px 10px 0" width="100%" maxWidth="50%">
                <InputText
                  label="Address"
                  name="address"
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="street-address"
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 0 10px 0" width="100%" maxWidth="50%">
                <InputText
                  label="City"
                  name="city"
                  rules={{
                    required: { message: 'is required', value: true },
                    pattern: { message: 'must be valid', value: /^[a-zA-Z ]+$/ }
                  }}
                  dataCy="city"
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 12px 10px 0" width="100%" maxWidth="50%">
                <InputSelect
                  label="State"
                  name="state"
                  options={USStateOptions}
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="state"
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 0 10px 0" width="100%" maxWidth="50%">
                <InputZip
                  name="zipcode"
                  label="Zip Code"
                  rules={{
                    required: { message: 'is required', value: true }
                  }}
                  dataCy="zip-code"
                  {...props.form}
                />
              </ContainerItem>
              {getFiBankStore().bank.id === GCD_RIVERBANK_BANK_ID && (
                <ContainerItem padding="10px 0 10px 0" width="100%" maxWidth="50%">
                  <InputMasked
                    mask={SsnMask}
                    label="SSN"
                    name="ssn"
                    rules={{
                      minLength: { message: 'must be in ###-##-#### format', value: 11 },
                      maxLength: { message: 'must be in ###-##-#### format', value: 11 }
                    }}
                    {...props.form}
                  />
                </ContainerItem>
              )}
            </Container>
            <Container padding="0" width="100%">
              <ContainerItem padding="10px 12px 10px 0" width="100%" maxWidth="50%">
                <InputSelect
                  placeholder="Select"
                  label="ID Type"
                  name="idType"
                  options={idtypes}
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="id-type"
                  additionalOnChange={(e) => {
                    const type = props.form.getValues('idType');
                    setIdType(type);
                  }}
                  {...props.form}
                />
              </ContainerItem>
              <ContainerItem padding="10px 0 10px 0" width="100%" maxWidth="50%">
                <InputText
                  placeholder="ID Number"
                  label="ID Number"
                  name="idNumber"
                  rules={{ required: { message: 'is required', value: true } }}
                  dataCy="id-number"
                  {...props.form}
                />
              </ContainerItem>
              {idType === 'dlicense' || idType === 'nondriverid' ? (
                <ContainerItem padding="10px 0 10px 0" width="100%">
                  <InputSelect
                    placeholder="Select"
                    label="Driver’s License State"
                    name="idState"
                    options={USStateOptions}
                    dataCy="id-state"
                    rules={{ required: { message: 'is required', value: true } }}
                    {...props.form}
                  />
                </ContainerItem>
              ) : null}
            </Container>

            <ContainerItem width="100%" padding="0">
              {frontLoading ? (
                <>
                  <Label content="Uploading..." />
                  <Container justify="center" align="center" width="100%">
                    <ContainerItem>
                      <InlineSpinner size="30px" />
                    </ContainerItem>
                  </Container>
                </>
              ) : (
                <FileContainer
                  fullWidth
                  multiple={false}
                  acceptedTypes="all"
                  existingFilesLabel="Upload ID Front"
                  existingS3Files={props.idFrontDocument ? [props.idFrontDocument] : []}
                  fileBucket={environment.storageConfig.orgDocument}
                  onNewFilesChange={(files) => {
                    setTempFrontId(files);
                    setErrorFrontMessage('');
                  }}
                  onRemoveExistingFile={(file) => {
                    if (props.removeDocumentFromUser) {
                      props.removeDocumentFromUser('front', setFrontLoading);
                    }
                  }}
                  uploadFilesLabel="Upload ID Front"
                  dataCy="upload-license-file-container"
                />
              )}
              {errorFrontMessage.length ? (
                <Error content={errorFrontMessage} dataCy="validation-message" />
              ) : null}
            </ContainerItem>

            <ContainerItem width="100%" padding="0">
              {backLoading ? (
                <>
                  <Label content="Uploading..." />
                  <Container justify="center" align="center" width="100%">
                    <ContainerItem>
                      <InlineSpinner size="30px" />
                    </ContainerItem>
                  </Container>
                </>
              ) : (
                <FileContainer
                  fullWidth
                  multiple={false}
                  acceptedTypes="all"
                  existingFilesLabel="Upload ID Back"
                  existingS3Files={props.idBackDocument ? [props.idBackDocument] : []}
                  fileBucket={environment.storageConfig.orgDocument}
                  onNewFilesChange={(files) => {
                    setTempBackId(files);
                    setErrorBackMessage('');
                  }}
                  onRemoveExistingFile={(file) => {
                    if (props.removeDocumentFromUser) {
                      props.removeDocumentFromUser('back', setBackLoading);
                    }
                  }}
                  uploadFilesLabel="Upload ID Back"
                  dataCy="upload-license-file-container"
                />
              )}
              {errorBackMessage.length ? (
                <Error content={errorBackMessage} dataCy="validation-message" />
              ) : null}
            </ContainerItem>
          </>
        ) : null}

        {props.newUser ? (
          <>
            <ContainerItem width="100%" margin="0.75rem 0" padding="0">
              <InputSelect
                {...props.form}
                label="Initial Group"
                name="role"
                options={props.userRoleOptions}
                rules={{
                  required: { message: 'is required', value: true }
                }}
                readOnly={
                  props.form.watch('gcd_user_role') === GcdUserRole.Admin ||
                  props.form.watch('gcd_user_role') === GcdUserRole.Manager ||
                  props.form.watch('pq_user_role') === PayqwickUserRole.Admin
                }
                dataCy="role"
                /** @payqwickRename The text here will need to be renamed to GreenCheckDirect when biz says so */
                tooltip={
                  props.form.watch('gcd_user_role') === GcdUserRole.Admin ||
                  props.form.watch('gcd_user_role') === GcdUserRole.Manager
                    ? 'When GCD role is set to Admin/Manager, users must be put in the Account Owner role'
                    : props.form.watch('pq_user_role') === PayqwickUserRole.Admin
                    ? 'When PayQwick role is set to Admin, users must be put in the Account Owner role'
                    : undefined
                }
              />
            </ContainerItem>
            <ContainerItem width="100%" margin="0.75rem 0" padding="0">
              <Text content="This user will receive an email with everything they need to set up their account on Green Check." />
            </ContainerItem>
            {props.payqwickOrgId === PAYQWICK_BANK_ID ? (
              <>
                <ContainerItem width="100%" margin="0.75rem 0" padding="0">
                  <Text sx={{ fontWeight: 'bold' }} content="Permission to access the PayQwick system" />
                </ContainerItem>

                <ContainerItem width="100%" padding="0">
                  <Text inline content="Learn more about PayQwick user roles and permissions in this " />
                  <Hyperlink
                    label="support article."
                    newTab
                    url="https://support.greencheckverified.com/knowledge/payqwick-user-access-comparison-chart"
                  />
                </ContainerItem>

                <ContainerItem width="100%" margin="0.75rem 0" padding="0">
                  <InputSelect
                    {...props.form}
                    label="Select Role"
                    name="pq_user_role"
                    options={PayqwickRoleOptions}
                    rules={{
                      required: { message: 'is required', value: true }
                    }}
                    defaultValue={'none'}
                    dataCy="pq_user_role"
                    additionalOnChange={(e) => {
                      if (e.target.value === PayqwickUserRole.Admin) {
                        props.form.setValue('role', 'dispensary_account_owner');
                      }
                    }}
                  />
                </ContainerItem>
              </>
            ) : null}
            {props.payqwickOrgId === GCD_RIVERBANK_BANK_ID ? (
              <>
                <ContainerItem width="100%" margin="0.75rem 0" padding="0">
                  <Text
                    sx={{ fontWeight: 'bold' }}
                    content="Permission to access the Green Check Direct system"
                  />
                </ContainerItem>

                <ContainerItem width="100%" padding="0">
                  <Text
                    inline
                    content="Learn more about Green Check Direct user roles and permissions in this "
                  />
                  <Hyperlink
                    label="support article."
                    newTab
                    url="https://support.greencheckverified.com/knowledge/payqwick-user-access-comparison-chart"
                  />
                </ContainerItem>

                <ContainerItem width="100%" margin="0.75rem 0" padding="0">
                  <InputSelect
                    {...props.form}
                    label="Select Role"
                    name="gcd_user_role"
                    options={GcdRoleOptions}
                    rules={{
                      required: { message: 'is required', value: true }
                    }}
                    defaultValue={'none'}
                    dataCy="gcd_user_role"
                    additionalOnChange={(e) => {
                      if (e.target.value === GcdUserRole.Admin || e.target.value === GcdUserRole.Manager) {
                        props.form.setValue('role', 'dispensary_account_owner');
                      }
                    }}
                  />
                </ContainerItem>
              </>
            ) : null}
          </>
        ) : null}
      </Container>
    </Dialog>
  );
};
