import { getAppViewStateStore } from './AppViewStateStore';
import { getBannerStore } from './BannerStore';
import { getCommentStore } from './CommentStore';
import { getComplianceStore } from './ComplianceStore';
import { getCrbBankStore } from './CrbBankStore';
import { getCrbDispensaryStore } from './CrbDispensaryStore';
import { getFiBankStore } from './FiBankStore';
import { getFiTemplateStore } from './FiTemplateStore';
import { getNotificationStore } from './NotificationStore';
import { getSnackbarStore } from './SnackBarStore';
import { getUserStore } from './UserStore';

export const clearStores = () => {
  const bankStore = getFiBankStore();
  const dispensaryStore = getCrbDispensaryStore();
  const crbBankStore = getCrbBankStore();
  const userStore = getUserStore();
  const snackbarStore = getSnackbarStore();
  const bannerStore = getBannerStore();
  const appViewStateStore = getAppViewStateStore();
  const notificationStore = getNotificationStore();
  const complianceStore = getComplianceStore();
  const fiTemplateStore = getFiTemplateStore();
  const commentStore = getCommentStore();

  bankStore.clearStore();
  dispensaryStore.clearStore();
  crbBankStore.clearStore();
  snackbarStore.clearStore();
  bannerStore.clearStore();
  userStore.clearStore();
  appViewStateStore.clearStore();
  notificationStore.clearStore();
  complianceStore.clearStore();
  fiTemplateStore.clearStore();
  commentStore.clearStore();
};
