import { debounce, useMediaQuery, useTheme } from '@mui/material';
import { posOptions } from 'domain/types/integrations/posOptions';
import { useComponent } from 'hooks/useComponent';
import { useInjection } from 'ioc';
import * as React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  ContainerItem,
  Form,
  Header,
  HoursOfOperation,
  InputCurrency,
  InputNumber,
  InputSelect,
  InputText,
  OnBoardingPage
} from 'ui';
import { OperationalDetailsPresenter } from './operational-details-presenter';

interface Props extends Record<string, unknown> {
  onBack: () => void;
  onNext: () => void;
  isLastStep: boolean;
  stepNumber: number;
  totalSteps: number;
}

export const OperationalDetails: React.FC<Props> = useComponent((props) => {
  const presenter = useInjection(OperationalDetailsPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { bank_id } = useParams() as { bank_id: string };

  const subtitle = presenter.viewModel.serviceProviderName;

  React.useEffect(() => {
    presenter.load(bank_id);
  }, []);

  const updatedBusinessHours: {
    [day: string]: {
      openHour?: string;
      closeHour?: string;
      isOpen?: boolean;
    };
  } = {
    monday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    tuesday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    wednesday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    thursday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    friday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    saturday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    },
    sunday: {
      openHour: '09:00',
      closeHour: '17:00',
      isOpen: false
    }
  };

  // this migrates business hours from the old method of allowing people to have hours of operations but still be closed
  if (presenter.crbDispensaryStore.currentDispensary?.businessHours) {
    Object.entries(presenter.crbDispensaryStore.currentDispensary?.businessHours).reduce(
      (acc, [key, val]) => {
        acc[key] = {
          openHour: val.isOpen ? val.openHour : '',
          closeHour: val.isOpen ? val.closeHour : '',
          isOpen: val.isOpen
        };
        return acc;
      },
      updatedBusinessHours
    );

    if (!updatedBusinessHours['sunday'] || !updatedBusinessHours['sunday'].isOpen) {
      updatedBusinessHours['sunday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['monday'] || !updatedBusinessHours['monday'].isOpen) {
      updatedBusinessHours['monday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['tuesday'] || !updatedBusinessHours['tuesday'].isOpen) {
      updatedBusinessHours['tuesday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['wednesday'] || !updatedBusinessHours['wednesday'].isOpen) {
      updatedBusinessHours['wednesday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['thursday'] || !updatedBusinessHours['thursday'].isOpen) {
      updatedBusinessHours['thursday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['friday'] || !updatedBusinessHours['friday'].isOpen) {
      updatedBusinessHours['friday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
    if (!updatedBusinessHours['saturday'] || !updatedBusinessHours['saturday'].isOpen) {
      updatedBusinessHours['saturday'] = {
        openHour: '09:00',
        closeHour: '17:00',
        isOpen: false
      };
    }
  }

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      pos_name: presenter.crbDispensaryStore.currentDispensary.pos_name,
      pos_name_other: presenter.crbDispensaryStore.currentDispensary.pos_name_other,
      ptEmployees: presenter.crbDispensaryStore.currentDispensary.ptEmployees,
      ftEmployees: presenter.crbDispensaryStore.currentDispensary.ftEmployees,
      business_type: presenter.crbDispensaryStore.currentDispensary.business_type,
      monthlyCustomers: presenter.crbDispensaryStore.currentDispensary.monthlyCustomers,
      monthlySales: presenter.crbDispensaryStore.currentDispensary.monthlySales,
      businessHours: updatedBusinessHours
    } as FieldValues
  });

  const onNext = async (data: Record<string, unknown>) => {
    await presenter.submitOperationalDetails(data, props.onNext);
  };
  const onChangeSave = React.useCallback(
    debounce(() => {
      presenter.autoSaveOperationalDetails(form.getValues());
    }, 2000),
    []
  );

  return (
    <OnBoardingPage
      alignBottomActions="center"
      dataCy="operational-details-page"
      title="Operational Details"
      subtitle={`Providing this information will help ${subtitle} tailor your product and service offerings!`}
      step={props.stepNumber}
      totalSteps={props.totalSteps}
      bgCard
      bottomActions={
        <Container justify="flex-end">
          <ContainerItem>
            <Button color="default-outlined" label="Back" onClick={props.onBack} dataCy="back-button" />
          </ContainerItem>
          <ContainerItem>
            <Button
              label={props.isLastStep ? 'Submit' : 'Continue'}
              color="primary"
              onClick={form.handleSubmit(onNext)}
              isLoading={presenter.viewModel.isSaving}
              dataCy="save-button"
            />
          </ContainerItem>
        </Container>
      }
    >
      <Container column padding={0} width="100%">
        <ContainerItem padding="0" width="100%">
          <Container padding="0" dataCy="employees-card">
            <Container padding="0">
              <Header mobileHeader={isMobile} content="Employees" type="h2" />
            </Container>
            <Form>
              <Container padding="8px 0 0 0" width="100%">
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputNumber
                    label="Full-Time Employees"
                    name="ftEmployees"
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="ft-employees"
                    onChange={onChangeSave}
                    onBlur={onChangeSave}
                    {...form}
                  />
                </ContainerItem>
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputNumber
                    label="Part-Time Employees"
                    name="ptEmployees"
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="pt-employees"
                    onChange={onChangeSave}
                    onBlur={onChangeSave}
                    {...form}
                  />
                </ContainerItem>
              </Container>
            </Form>
          </Container>

          <Container padding="30px 0" width="100%" column dataCy="hours-card">
            <Container padding="8px 0">
              <Header mobileHeader={isMobile} content="Hours of Operation" type="h2" />
            </Container>
            <ContainerItem padding="0" width="100%">
              <HoursOfOperation form={form} onChange={onChangeSave} />
            </ContainerItem>
          </Container>

          <Container padding="0" dataCy="financial-card">
            <Container padding="8px 0">
              <Header mobileHeader={isMobile} content="Financial" type="h2" />
            </Container>
            <Form>
              <Container padding="0" width="100%">
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputCurrency
                    label="Estimated Monthly Sales"
                    name="monthlySales"
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="monthly-sales"
                    onChange={onChangeSave}
                    onBlur={onChangeSave}
                    {...form}
                  />
                </ContainerItem>
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputNumber
                    label="Estimated Monthly Customers"
                    name="monthlyCustomers"
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="monthly-customers"
                    onChange={onChangeSave}
                    onBlur={onChangeSave}
                    {...form}
                  />
                </ContainerItem>
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputSelect
                    label="POS/Invoice Tracking System"
                    name="pos_name"
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="pos-name"
                    options={posOptions}
                    onBlur={onChangeSave}
                    additionalOnChange={(event) => {
                      if (event.target.value !== 'Other') {
                        form.setValue('pos_name_other', '');
                      }
                      onChangeSave();
                    }}
                    {...form}
                  />
                </ContainerItem>
                {form.watch('pos_name') === 'Other' ? (
                  <ContainerItem
                    padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                    width="100%"
                    maxWidth={isMobile ? 'none' : '25%'}
                    minWidth={isMobile ? 'none' : '250.43px'}
                  >
                    <InputText
                      label="Other POS/Invoice Tracking System"
                      name="pos_name_other"
                      rules={{ required: { message: 'is required', value: true } }}
                      dataCy="pos-name-other"
                      onChange={onChangeSave}
                      onBlur={onChangeSave}
                      {...form}
                    />
                  </ContainerItem>
                ) : null}
                <ContainerItem
                  padding={isMobile ? '10px 0' : '10px 12px 10px 0'}
                  width="100%"
                  maxWidth={isMobile ? 'none' : '25%'}
                  minWidth={isMobile ? 'none' : '250.43px'}
                >
                  <InputSelect
                    label="Business Type"
                    name="business_type"
                    options={[
                      { label: 'Retail', value: 'retail' },
                      { label: 'Wholesale', value: 'wholesale' }
                    ]}
                    rules={{ required: { message: 'is required', value: true } }}
                    dataCy="business-type"
                    additionalOnChange={onChangeSave}
                    onBlur={onChangeSave}
                    {...form}
                  />
                </ContainerItem>
              </Container>
            </Form>
          </Container>
        </ContainerItem>
      </Container>
    </OnBoardingPage>
  );
});
