import { Box, Collapse, List, ListItemIcon, ListItemText } from '@mui/material';
import { ReactComponent as AccountsIcon } from 'assets/images/navigation-icons/accounts.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/navigation-icons/dashboard.svg';
import { ReactComponent as DepositsIcon } from 'assets/images/navigation-icons/deposits.svg';
import { ReactComponent as FincenIcon } from 'assets/images/navigation-icons/fincen.svg';
import { ReactComponent as DataIcon } from 'assets/images/navigation-icons/ic-data-round.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/navigation-icons/ic-settings.svg';
import { ReactComponent as MonitoringIcon } from 'assets/images/navigation-icons/monitoring.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/navigation-icons/reports.svg';
import { ReactComponent as SupportIcon } from 'assets/images/navigation-icons/support.svg';
import { ReactComponent as TasksIcon } from 'assets/images/navigation-icons/tasks.svg';
import { ReactComponent as UsersIcon } from 'assets/images/navigation-icons/users.svg';
import { ReactComponent as DownIcon } from 'assets/images/ic-chevron-down.svg';
import { ReactComponent as UpIcon } from 'assets/images/chevron-up.svg';
import { useInjection } from 'ioc';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getAppViewStateStore } from 'stores/AppViewStateStore';
import { Divider, ListItemLink } from 'ui/atoms';
import { Drawer } from 'ui/molecules';
import { FiNavDrawerPresenter } from './fi-nav-drawer.presenter';
import palette from 'ui/theme/palette';

interface Props {
  openNavDrawer: boolean;
  permanent: boolean;
  setOpenNavMenu: (isOpen: boolean) => void;
}

export const FiNavDrawer: React.FC<Props> = (props) => {
  const presenter = useInjection(FiNavDrawerPresenter);
  const appViewStore = getAppViewStateStore();
  const { tasksAssignments } = useFlags();
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    presenter.load();
    presenter.setActiveLinkFromPath(window.location.href);
    // while we don't necessarily need a loading state for this component,
    // it's helping to trigger some of the states for whether the user can view some sections
    setIsLoading(presenter.viewModel.isLoading);
    setOpen(presenter.viewModel.dataOpen);
  }, []);

  return !isLoading ? (
    <Drawer
      width="xsmall"
      anchor="left"
      hideBackdrop={props.permanent}
      onClose={() => props.setOpenNavMenu(false)}
      open={props.openNavDrawer}
      permanent={props.permanent}
      dataCy={props.permanent ? 'nav-drawer' : 'mobile-nav-drawer'}
    >
      <Box
        sx={{
          alignItems: 'center',
          borderRight: { xs: 'none', lg: '1px solid #bdbdbd' },
          display: 'flex',
          height: '73px',
          minHeight: '73px',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Link to="/secure/fi/dashboard" data-cy="logo-link">
          <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
        </Link>
      </Box>
      <Divider />
      <List component="nav">
        <ListItemLink
          active={presenter.viewModel.activeLink === 'dashboard'}
          dataCy="dashboard-nav-link"
          icon={<DashboardIcon />}
          onClick={() => {
            appViewStore.clearPaginationStore();
            presenter.setActiveLink('dashboard');
          }}
          text="Dashboard"
          to="/secure/fi/dashboard"
        />
        {presenter.viewModel.userCanViewDeposits && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'deposits'}
            dataCy="deposits-nav-link"
            icon={<DepositsIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('deposits');
            }}
            text="Deposits"
            to="/secure/fi/deposits"
          />
        )}
        <ListItemLink
          active={presenter.viewModel.activeLink === 'accounts'}
          dataCy="accounts-nav-link"
          icon={<AccountsIcon />}
          onClick={() => {
            appViewStore.clearPaginationStore();
            presenter.setActiveLink('accounts');
          }}
          text="Accounts"
          to="/secure/fi/accounts"
        />
        {presenter.viewModel.userCanViewFincen && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'fincen'}
            dataCy="fincen-nav-link"
            icon={<FincenIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('fincen');
            }}
            text="FinCEN"
            to="/secure/fi/fincen"
          />
        )}
        {!presenter.viewModel.isBankExaminer && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'reports'}
            dataCy="reports-nav-link"
            icon={<ReportsIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('reports');
            }}
            text="Reports"
            to="/secure/fi/reports"
          />
        )}
        {presenter.viewModel.userCanViewMonitoring && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'monitoring'}
            dataCy="monitoring-nav-link"
            icon={<MonitoringIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('monitoring');
            }}
            text="Monitoring"
            to="/secure/fi/monitoring"
          />
        )}

        <>
          <Box
            onClick={() => {
              presenter.toggleDataOpen();
              setOpen(presenter.viewModel.dataOpen);
            }}
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              height: '48px',
              paddingBottom: '0',
              '& .MuiTypography-root': {
                fontWeight: 700,
                fontSize: '14px'
              },
              '&:before': {
                content: "''",
                height: '40px',
                width: '3px',
                marginRight: '39px'
              },
              '&:hover': {
                backgroundColor:
                  presenter.viewModel.activeLink === 'daily-summaries'
                    ? 'rgba(3, 166, 91, 0.07)'
                    : 'transparent',
                '& .MuiListItemIcon-root': {
                  color: 'primary.main'
                },
                '& .MuiTypography-root': {
                  color: 'primary.main'
                }
              }
            }}
          >
            <ListItemIcon sx={{ ml: 0, mr: '18px', color: palette.text.primary }}>
              <DataIcon />
            </ListItemIcon>
            <ListItemText primary={'Data'} />
            <ListItemIcon>{open ? <UpIcon /> : <DownIcon />}</ListItemIcon>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
              {(presenter.viewModel.userCanViewData || presenter.viewModel.isBankExaminer) && (
                <ListItemLink
                  active={presenter.viewModel.activeLink === 'data'}
                  dataCy="data-nav-link"
                  icon={<></>}
                  onClick={() => {
                    appViewStore.clearPaginationStore();
                    presenter.setActiveLink('data');
                  }}
                  text="Data Overview"
                  to="/secure/fi/data"
                  height="40px"
                />
              )}
              <ListItemLink
                active={presenter.viewModel.activeLink === 'daily-summaries'}
                dataCy="daily-summaries-nav-link"
                icon={<></>}
                onClick={() => {
                  appViewStore.clearPaginationStore();
                  presenter.setActiveLink('daily-summaries');
                }}
                text="Daily Summaries"
                to="/secure/fi/data/daily-summaries"
                height="40px"
              />
            </List>
          </Collapse>
        </>
        {tasksAssignments && !presenter.viewModel.isBankExaminer && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'tasks'}
            dataCy="tasks-nav-link"
            icon={<TasksIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('tasks');
            }}
            text="Tasks"
            to="/secure/fi/tasks"
          />
        )}
        <ListItemLink
          active={presenter.viewModel.activeLink === 'settings'}
          dataCy="settings-nav-link"
          icon={<SettingsIcon />}
          onClick={() => {
            appViewStore.clearPaginationStore();
            presenter.setActiveLink('settings');
          }}
          text="Settings"
          to="/secure/fi/settings"
        />
        {presenter.viewModel.userCanViewUsers && (
          <ListItemLink
            active={presenter.viewModel.activeLink === 'users'}
            dataCy="users-nav-link"
            icon={<UsersIcon />}
            onClick={() => {
              appViewStore.clearPaginationStore();
              presenter.setActiveLink('susersettings');
            }}
            text="Users"
            to="/secure/fi/users"
          />
        )}
        <ListItemLink
          active={false}
          dataCy="support-nav-link"
          icon={<SupportIcon />}
          text="Support"
          to="https://support.greencheckverified.com"
        />
      </List>
    </Drawer>
  ) : (
    <></>
  );
};
