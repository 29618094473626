import { InviteNewUserRequest } from '@gcv/shared';
import { DispensariesApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';

export interface AccountOwnersProgrammersModel {}

@injectable()
export class AccountOwnersRepo {
  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public dispensaryStore: CrbDispensaryStore;

  @inject(DispensariesApi)
  public dispensariesApi: DispensariesApi;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: AccountOwnersProgrammersModel = {};

  updateProgrammersModel = action((programmersModel: Partial<AccountOwnersProgrammersModel>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  inviteUser = async (data: FieldValues) => {
    const request: InviteNewUserRequest = {
      userInfo: {
        role: 'dispensary_account_owner',
        email: data.email.trim(),
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        pq_user_role: data.pq_user_role === 'none' ? undefined : data.pq_user_role,
        gcd_user_role: data.gcd_user_role === 'none' ? undefined : data.gcd_user_role
      }
    };

    try {
      const response = await this.dispensariesApi.inviteDispensaryUser(
        this.dispensaryStore.currentDispensary.id,
        request
      );
      this.dispensaryStore.updateDispensaryStaff(response.user);
      this.dispensaryStore.updateDispensary(response.org);
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'User has been invited successfully.');
      return response;
    } catch (error) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Success, 'There was an issue inviting the user.');
    }
  };
}
