import * as base64arraybuffer from 'base64-arraybuffer';
import FileType from 'file-type';
import { getSnackbarStore } from 'stores/SnackBarStore';

export async function base64DecodeS3Data(e: ProgressEvent<FileReader>, type?: string, filename?: string) {
  filename = filename ? filename : 'filename';
  const base64 = (e.target ? e.target['result'] : null) as string;
  if (!base64) {
    throw new Error("base64DecodeS3Data: no base64 data in event's result");
  }
  const base64ArrayBuffer = base64arraybuffer.decode(base64);
  if (!base64ArrayBuffer) {
    throw new Error('base64DecodeS3Data: no base64ArrayBuffer when decoding base64 data');
  }
  const parsedFileType = type ? { mime: type } : await FileType.fromBuffer(base64ArrayBuffer);
  if (!parsedFileType) {
    throw new Error('base64DecodeS3Data: unable to parse file type from base64ArrayBuffer');
  }
  const file = new File([base64ArrayBuffer], filename, { type: parsedFileType?.mime });
  return { file };
}

export function openUrlFromFile(file: File) {
  const url = window.URL.createObjectURL(file);
  if (url) {
    //download file with filename
    if (
      file.type === '' ||
      (file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/png')
    ) {
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', file.name);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => document.body.removeChild(a), 0);
    } else {
      //open in new tab
      window.open(url, '_blank');
    }
  }
}

export async function openFileInNewWindow(blob: Blob, type?: string, filename?: string) {
  callFileReaderWithCallback(blob, async (e: ProgressEvent<FileReader>) => {
    const snackbarStore = getSnackbarStore();

    try {
      const { file } = await base64DecodeS3Data(e, type, filename);
      openUrlFromFile(file);
    } catch (error) {
      snackbarStore.showErrorSnackbarMessage('There was an issue opening this file');
    }
  });
}

export async function openUnendcodedFileInNewWindow(blob: Blob, type?: string, filename?: string) {
  callFileReaderWithCallback(blob, async (e: ProgressEvent<FileReader>) => {
    const snackbarStore = getSnackbarStore();

    try {
      const base64 = (e.target ? e.target['result'] : null) as string;
      filename = filename ? filename : 'filename';
      type = type ? type : 'csv';
      const file = new File([base64], filename, { type });
      openUrlFromFile(file);
    } catch (error) {
      snackbarStore.showErrorSnackbarMessage('There was an issue opening this file');
    }
  });
}

export async function encodeFileForUpload(file: Blob): Promise<string> {
  const result_base64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      if (e.target?.result) {
        const arrayBuffer = e.target['result'];
        if (typeof arrayBuffer !== 'string') {
          resolve(base64arraybuffer.encode(arrayBuffer));
        }
      }
    };
    fileReader.readAsArrayBuffer(file);
  }).catch((e) => console.log(e));

  return result_base64 as string;
}

// use of any in order to match fileReader.onload api
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function callFileReaderWithCallback(blob: Blob, callback: (e: ProgressEvent<FileReader>) => any) {
  const fileReader = new FileReader();
  fileReader.onload = callback;
  fileReader.readAsBinaryString(blob);
}

export const downloadXML = (fileName: string, fileData: string) => {
  const linkSource = `data:application/xml;base64,${fileData}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const downloadCSV = (fileName: string, fileData: string) => {
  const encodedData = encodeURIComponent(fileData);
  const linkSource = `data:text/csv;charset=utf-8,${encodedData}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const downloadFile = async (response: Response, fileName: string | undefined) => {
  const base64 = await response.text();
  const type = response.type;

  const downloadLink = document.createElement('a');
  downloadLink.href = `data:${type};base64,${base64}`;
  downloadLink.download = fileName || 'file';
  downloadLink.click();
  downloadLink.remove();
};

export const downloadFileFromSignedUrl = async (signedUrl: string) => {
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', signedUrl);
  linkElement.click();
  linkElement.remove();
};

export const getMimeType = (fileName: string) => {
  let fileType = '';
  if (fileName.includes('.csv')) {
    fileType = 'text/csv';
  } else if (fileName.includes('.txt')) {
    fileType = 'text/plain';
  } else if (fileName.includes('.pdf')) {
    fileType = 'application/pdf';
  }

  return fileType;
};
