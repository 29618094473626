import { GcdUserRole, PayqwickUserRole, Role } from '@gcv/shared';

export const DispensaryRoleOptions: { value: Role; label: string }[] = [
  { value: 'dispensary_admin', label: 'Admin' },
  { value: 'dispensary_user', label: 'User' },
  { value: 'dispensary_account_owner', label: 'Account Owner' },
  { value: 'dispensary_deposit_transport', label: 'Deposit Transport' },
  { value: 'dispensary_deposit_recorder', label: 'Deposit Recorder' },
  { value: 'dispensary_questionnaire_completer', label: 'Questionnaire Completer' }
];

export const BankRoleOptions: { value: Role; label: string }[] = [
  { value: 'bank_admin', label: 'Admin' },
  { value: 'bank_user', label: 'Standard User' },
  { value: 'bank_primary_contact', label: 'Account Owner' },
  { value: 'bank_deposit_approver', label: 'Deposit Approver' },
  { value: 'bank_document_preparer', label: 'Document Preparer' },
  { value: 'bank_document_approver', label: 'Document Approver' },
  { value: 'bank_fincen_preparer', label: 'FinCEN Preparer' },
  { value: 'bank_fincen_approver', label: 'FinCEN Approver' },
  { value: 'bank_account_review_preparer', label: 'Account Review Preparer' },
  { value: 'bank_account_review_approver', label: 'Account Review Approver' },
  { value: 'bank_examiner', label: 'Auditor / Examiner' },
  { value: 'bank_core_data_admin', label: 'Core Data Admin' },
  { value: 'bank_questionnaire_assigner', label: 'Questionnaire Assigner / Viewer' },
  { value: 'bank_questionnaire_template_editor', label: 'Questionnaire Template Editor' },
  { value: 'bank_due_diligence_template_editor', label: 'Due Diligence Template Editor' },
  { value: 'bank_questionnaire_admin', label: 'Due Diligence / Questionnaire Admin' },
  { value: 'bank_sponsor', label: 'Banking Sponsor' },
  { value: 'bank_account_facilitator', label: 'Account Facilitator' },
  { value: 'bank_edit_crb_info', label: 'Edit CRB Info' }
];

export const PayqwickRoleOptions: { value: PayqwickUserRole | 'none'; label: string }[] = [
  /** 'none' is not a valid value and needs to be excluded from API requests */
  { value: 'none', label: 'No Access' },
  { value: PayqwickUserRole.Employee, label: 'Employee' },
  { value: PayqwickUserRole.Manager, label: 'Manager' },
  { value: PayqwickUserRole.Admin, label: 'Admin' }
];

export const GcdRoleOptions: { value: GcdUserRole | 'none'; label: string }[] = [
  /** 'none' is not a valid value and needs to be excluded from API requests */
  { value: 'none', label: 'No Access' },
  { value: GcdUserRole.Employee, label: 'Employee' },
  { value: GcdUserRole.Manager, label: 'Manager' },
  { value: GcdUserRole.Admin, label: 'Admin' }
];
