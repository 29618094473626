import { useMediaQuery, useTheme } from '@mui/material';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { OverlaySpinner } from 'ui/molecules';
import { OnBoardingPage } from 'ui/templates';
import { OnboardingPresenter } from '../../onboarding.presenter';
import { DocumentationFilters } from './components/documentation-filters';
import { DocumentationFooter } from './components/documentation-footer';
import { RequirementsContainer } from './components/requirements-container';

interface Props extends Record<string, unknown> {
  onBack: () => void;
  onNext: () => void;
  stepNumber: number;
  totalSteps: number;
}

export const Documentation: React.FC<Props> = useComponent((props: Props) => {
  const presenter = useInjection(OnboardingPresenter);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const subtitle = presenter.viewModel.provider.orgName || 'Your service provider';

  return (
    <>
      {presenter.viewModel.licenseData && (
        <OnBoardingPage
          alignBottomActions="center"
          dataCy="documentation-page"
          title="Documentation"
          subtitle={`${subtitle} requires certain documentation as part of the account opening process. Upload your documents below or use the comments functionality if you have any questions.`}
          bottomActions={<DocumentationFooter onBack={props.onBack} onNext={props.onNext} />}
          step={props.stepNumber}
          totalSteps={props.totalSteps}
          bgCard={isMobile}
          topActions={<DocumentationFilters isMobile={isMobile} />}
        >
          {presenter.viewModel.isLoadingLicenseUpload && <OverlaySpinner />}
          <RequirementsContainer isMobile={isMobile} />
        </OnBoardingPage>
      )}
    </>
  );
});
