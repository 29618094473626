import {
  CrbServiceProvider,
  Dispensary,
  DocumentUpload,
  DueDiligenceDocument,
  DueDiligenceStatus,
  IANATimezones,
  MarketplaceOfferCategory,
  MarketplaceOfferStatus,
  MockDataProvider,
  OnboardingDocumentRequirement,
  USStates
} from '@gcv/shared';
import { DispensariesApi } from '../DispensariesApi';
export class DispensariesApiMock extends DispensariesApi {
  mockDispensary = MockDataProvider.getMockDispensary();

  async getDispensaryServiceProviders(
    dispensaryId: string
  ): Promise<{ [bankId: string]: CrbServiceProvider }> {
    return {
      '1': {
        id: '1',
        city: 'New Haven',
        address: '',
        businessHours: {},
        description: '',
        orgName: 'GCV',
        phoneNumber: '1234567890',
        postalCode: '12345',
        primary_point_of_contact: {
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '1234567890',
          title: ''
        },
        state: USStates.Connecticut,
        iana_timezone: IANATimezones.America_NewYork,
        templates: {
          version: 'latest',
          crb_id: 'a4342c49-b8e9-4cd8-b3ee-166340fc8830',
          number_of_documents: 0,
          secondary_label: '1.crb-templates',
          date_updated: '2022-12-29T16:02:29.689Z',
          label: '1',
          date_created: '2022-12-29T16:02:29.689Z',
          value: {
            onboarding: {
              template_id: 'green_check_template_id',
              template_version: '1',
              assigned_date: '2022-12-29T16:02:29.642Z',
              latest_version: '0',
              template_result_id: '2b0aa73a-86cc-4ecf-b49f-7e91fd154dcd',
              status: DueDiligenceStatus.GCV_PENDING,
              last_updated: '2022-12-29T16:02:29.593Z'
            },
            connected_date: '2022-12-29T16:02:29.642Z'
          },
          category: 'a4342c49-b8e9-4cd8-b3ee-166340fc8830.crb-templates',
          label_category: '1-a4342c49-b8e9-4cd8-b3ee-166340fc8830.crb-templates',
          id: '22585a01-113e-4caa-b075-b582e4824615',
          gcv_version_num: 1
        },
        transportVendors: [],
        plant_touching: true
      },
      '4346566a-cb4f-47a7-a142-04863d4d425a': {
        id: '4346566a-cb4f-47a7-a142-04863d4d425a',
        orgName: 'Ryans Bank',
        description: '',
        address: 'Your Bank Address St',
        city: 'BankCity',
        state: USStates.Connecticut,
        postalCode: '12345',
        businessHours: {
          sunday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: false
          },
          saturday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: false
          },
          tuesday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: true
          },
          wednesday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: true
          },
          thursday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: true
          },
          friday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: true
          },
          monday: {
            openHour: '09:00',
            closeHour: '17:00',
            isOpen: true
          }
        },
        phoneNumber: '(222) 333-4444',
        primary_point_of_contact: {
          firstName: 'PocFirst',
          lastName: 'PocLast',
          emailAddress: 'automation.pointofcontact@greencheckverified.com',
          phoneNumber: '6667778888',
          title: 'PrimaryPointOfContact'
        },
        iana_timezone: IANATimezones.America_NewYork,
        website: 'https://greencheckverified.com',
        templates: {
          version: 'latest',
          crb_id: 'a4342c49-b8e9-4cd8-b3ee-166340fc8830',
          number_of_documents: 0,
          secondary_label: '4346566a-cb4f-47a7-a142-04863d4d425a.crb-templates',
          date_updated: '2022-12-29T16:03:16.411Z',
          label: '4346566a-cb4f-47a7-a142-04863d4d425a',
          date_created: '2022-12-29T16:03:16.411Z',
          value: {
            onboarding: {
              template_id: 'add8271e-c863-4afb-b37d-0baccddf4ab8',
              marketplace_offer_snapshot: {
                status: MarketplaceOfferStatus.Active,
                large_image_url:
                  'https://cdn.proactiveinvestors.com/eyJidWNrZXQiOiJwYS1jZG4iLCJrZXkiOiJ1cGxvYWRcL05ld3NcL0ltYWdlXC8yMDIxXzA1XC8xNjIwNzc4MTY5X0Nhbm5hYmlzLVBsYW50LUdyb3dpbmctTGFiLVdhcmVob3VzZS5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjU0MCwiaGVpZ2h0IjozNzIsImZpdCI6ImNvdmVyIn19fQ==',
                highlights: [
                  {
                    'Marketing Point A': 'We are good'
                  },
                  {
                    'Marketing Point C': 'We are really, really good'
                  },
                  {
                    'Marketing Point B': 'We are really good'
                  }
                ],
                criteria: {
                  plant_touching: true,
                  business_type: 'retail',
                  states: [USStates.Illinois, USStates.Missouri, USStates.Arizona, USStates.Connecticut]
                },
                image_url:
                  'https://static.wixstatic.com/media/87d272_de1e8a0ffa9d4638b51b8b3b410c64e9~mv2.png/v1/fill/w_142,h_118,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/87d272_de1e8a0ffa9d4638b51b8b3b410c64e9~mv2.png',
                non_plant_touching_template_id: 'b244b8ca-89ce-4526-a174-7990296af27a',
                description:
                  'Power REIT is expanding its focus to include agricultural real estate with a focus on Controlled Environment, is expanding its focus to include agricultural real estate with a focus on Controlled',
                provider_id: '4346566a-cb4f-47a7-a142-04863d4d425a',
                id: 'd34a33a9-cec6-4acc-96b3-08ddc6fa4d2c',
                offer_category: MarketplaceOfferCategory.Lending,
                title: 'Power REIT',
                plant_touching_template_id: 'add8271e-c863-4afb-b37d-0baccddf4ab8'
              },
              template_version: '1',
              assigned_date: '2022-12-29T16:03:16.128Z',
              latest_version: '0',
              template_result_id: '59114af2-4582-405c-893a-5b62825560ac',
              status: DueDiligenceStatus.GCV_PENDING,
              last_updated: '2022-12-29T16:03:15.890Z'
            },
            connected_date: '2022-12-29T16:03:16.128Z'
          },
          category: 'a4342c49-b8e9-4cd8-b3ee-166340fc8830.crb-templates',
          label_category:
            '4346566a-cb4f-47a7-a142-04863d4d425a-a4342c49-b8e9-4cd8-b3ee-166340fc8830.crb-templates',
          id: '2e9177b9-84c6-4b30-8227-b1e7ae328481',
          gcv_version_num: 1
        },
        transportVendors: [],
        plant_touching: true
      }
    };
  }
  async getSharedRootDocuments(crbId: string): Promise<
    {
      requirement: OnboardingDocumentRequirement;
      ddDocuments: DueDiligenceDocument[];
      documents: DocumentUpload[];
    }[]
  > {
    return [];
  }

  async getAllRootDocuments(crbId: string): Promise<
    {
      requirement: OnboardingDocumentRequirement;
      ddDocuments: DueDiligenceDocument[];
      documents: DocumentUpload[];
    }[]
  > {
    return [];
  }

  async updateDispensaryBusinessDetails(
    dispensaryId: string,
    data: {
      name?: string;
      dba?: string;
      entityType?: string;
      establishedDate?: string;
      ein?: string;
      streetAddress?: string;
      city?: string;
      state?: string;
      country?: string;
      postalCode?: string;
      website?: string;
      phone?: string;
      iana_timezone?: string;
      mailingStreetAddress?: string;
      mailingCity?: string;
      mailingState?: string;
      mailingPostalCode?: string;
    }
  ): Promise<Dispensary> {
    return { ...this.mockDispensary, id: dispensaryId, ...data } as Dispensary;
  }

  async updateDispensaryOperationalDetails(
    dispensaryId: string,
    data: {
      businessHours?: any;
      monthlySales?: number;
      monthlyCustomers?: string;
      pos_name?: string;
      ftEmployees?: string;
      ptEmployees?: string;
      business_type?: 'retail' | 'wholesale';
    }
  ): Promise<Dispensary> {
    return { ...this.mockDispensary, id: dispensaryId, ...data } as Dispensary;
  }
}
