import { CommentType, DocumentUpload } from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observe } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { CrbBankStore } from '../../../../../../stores/CrbBankStore';
import { CrbDispensaryStore } from '../../../../../../stores/CrbDispensaryStore';
import { UserStore } from '../../../../../../stores/UserStore';
import { OnboardingRepo } from '../../onboarding.repo';
import { IdVerificationProgrammersModel, IdVerificationRepo } from './id-verification-repo';
import { CommentStore } from '../../../../../../stores/CommentStore';

export interface VM {
  serviceProviderName: string;
  isSavingIdVerification: boolean;
  idFrontDocument: DocumentUpload | undefined;
  idBackDocument: DocumentUpload | undefined;
  isSavingIdDocs: boolean;
  isDeletingIdDocs: boolean;
  idType: string;
  isAutoSaving: boolean;
  uploadDocumentsModalOpen: boolean;
}

@injectable()
export class IdVerificationPresenter {
  @inject(IdVerificationRepo)
  public repo: IdVerificationRepo;

  @inject(OnboardingRepo)
  public onboardingRepo: OnboardingRepo;

  @inject(SnackbarStore)
  public snackbarStore: SnackbarStore;

  @inject(CrbDispensaryStore)
  public crbDispensaryStore: CrbDispensaryStore;

  @inject(CrbBankStore)
  public crbBankStore: CrbBankStore;

  @inject(UserStore)
  public userStore: UserStore;

  @inject(CommentStore)
  public commentStore: CommentStore;

  constructor() {
    makeAutoObservable(this);
  }

  public viewModel: VM = {
    serviceProviderName: '',
    isSavingIdVerification: false,
    idFrontDocument: undefined,
    idBackDocument: undefined,
    isSavingIdDocs: false,
    isDeletingIdDocs: false,
    isAutoSaving: false,
    idType: '',
    uploadDocumentsModalOpen: false
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  public load = action((bankId: string) => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmerModel = obj.newValue as IdVerificationProgrammersModel;
      this.updateViewModel({
        idFrontDocument: programmerModel.idFront,
        idBackDocument: programmerModel.idBack,
        idType: this.userStore.user.identification?.idType
      });
    });

    this.repo.load();
    this.commentStore.setCurrentPost({
      type: CommentType.ACCOUNT,
      title: this.crbDispensaryStore.currentDispensary.name,
      idComponents: {
        orgId: this.crbDispensaryStore.currentDispensary.id,
        crbId: this.crbDispensaryStore.currentDispensary.id,
        fiId: bankId
      }
    });
    const serviceProviderName = this.crbBankStore.banks[bankId]?.orgName ?? 'Your Service provider';
    this.updateViewModel({
      serviceProviderName,
      idFrontDocument: this.repo.programmersModel.idFront,
      idBackDocument: this.repo.programmersModel.idBack,
      idType: this.userStore.user.identification?.idType
    });
  });

  updateUserIdentification = action(async (data: FieldValues, onNext: () => void) => {
    await this.repo.updateUserIdentification(data, onNext);
  });

  autoSaveUserIdentification = action(async (data: FieldValues) => {
    this.updateViewModel({ isAutoSaving: true });
    await this.repo.autoSaveUserIdentification(data);
    this.updateViewModel({ isAutoSaving: false });
  });

  uploadNewId = async (file: File): Promise<DocumentUpload> => {
    return this.repo.uploadNewId(file);
  };

  updateIdentityDocs = action(async (newIdFront?: File, newIdBack?: File) => {
    this.updateViewModel({ isSavingIdDocs: true });
    const docs = await this.repo.updateIdentityDocs(newIdFront, newIdBack);
    this.updateViewModel({
      isSavingIdDocs: false,
      idFrontDocument: docs?.frontDoc ? docs.frontDoc : this.viewModel.idFrontDocument,
      idBackDocument: docs?.backDoc ? docs.backDoc : this.viewModel.idBackDocument
    });
  });

  deleteFrontIdDoc = action(async () => {
    this.updateViewModel({ isDeletingIdDocs: true });
    const updatedIdentification = { ...this.userStore.user.identification };
    delete updatedIdentification.idFront;
    await this.repo.deleteIdDoc(updatedIdentification);
    this.updateViewModel({ isDeletingIdDocs: false, idFrontDocument: undefined });
  });

  deleteBackIdDoc = action(async () => {
    this.updateViewModel({ isDeletingIdDocs: true });
    const updatedIdentification = { ...this.userStore.user.identification };
    delete updatedIdentification.idBack;
    await this.repo.deleteIdDoc(updatedIdentification);
    this.updateViewModel({ isDeletingIdDocs: false, idBackDocument: undefined });
  });

  submitUserIdentification = action(async (data: FieldValues, onNext: () => void) => {
    if (!this.userStore.user.identification?.idFront || !this.userStore.user.identification?.idBack) {
      this.snackbarStore.showSnackbar(SnackbarSeverity.Error, 'Identification files upload required');
      return;
    }

    this.updateViewModel({ isSavingIdVerification: true });
    await this.updateUserIdentification(data, onNext);
    this.updateViewModel({ isSavingIdVerification: false });
  });

  setIdType = action((idType: string) => {
    this.updateViewModel({ idType });
  });

  setUploadDocumentsModalOpen = action((uploadDocumentsModalOpen: boolean) => {
    this.updateViewModel({ uploadDocumentsModalOpen });
  });
}
